import { MarketPageType } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { CellDefinition } from '@Elements/DataList/datalist-type';

import { useMarketPageList } from '../../hooks/use-market-page-list';

import { CreateMarketPage } from './create-market-page';

export const ListMarketPages = () => {
  const { marketPages } = useMarketPageList();

  const { t } = useTranslation();

  const cellDefinitionWithLabel = MarketPageCellDefinition.map((cell) => ({
    ...cell,
    label: t(cell.label),
  }));

  return (
    <>
      <div className="h-full flex flex-col flex-1 p-4">
        <ListCustom<MarketPageType>
          cellDefinition={cellDefinitionWithLabel}
          results={marketPages}
          getPathDetail={(marketPage) => `/market-pages/${marketPage.id}`}
          enableFilters
          children={<CreateMarketPage />}
        />
      </div>
    </>
  );
};

const MarketPageCellDefinition: CellDefinition<MarketPageType>[] = [
  { id: 'id', label: 'Id' },
  {
    id: 'categoryId',
    label: 'categoryId',
    type: 'string',
  },
  {
    id: 'slug',
    label: 'slug',
    type: 'string',
  },
  {
    id: 'title',
    label: 'title',
    type: 'string',
  },
  {
    id: 'description',
    label: 'description',
    type: 'string',
  },
  {
    id: 'coverUrl',
    label: 'coverUrl',
    type: 'string',
  },
  {
    id: 'countryIds',
    label: 'countries',
    type: 'string',
  },
  {
    id: 'createdAt',
    label: 'form.createdAt',
    type: 'date-with-time',
  },
  {
    id: 'updatedAt',
    label: 'form.updatedAt',
    type: 'date-with-time',
  },
  {
    id: 'deletedAt',
    label: 'form.deletedAt',
    type: 'date-with-time',
  },
];
