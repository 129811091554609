import { useState } from 'react';

import { OrderCancellationReason } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@/components/elements/buttons/LoadingButton';

type CancelOrderProps = {
  onClose: () => void;
  onConfirm: (cancellationReason: OrderCancellationReason | null) => void;
  isLoading: boolean;
};

export const CancelOrder = ({ onClose, onConfirm, isLoading }: CancelOrderProps) => {
  const [cancellationReason, setCancellationReason] = useState<OrderCancellationReason | null>(
    null,
  );

  const { t } = useTranslation();

  const handleOnClose = () => {
    setCancellationReason(null);
    onClose();
  };

  const handleChange = (event: SelectChangeEvent<OrderCancellationReason>) => {
    setCancellationReason(event.target.value as OrderCancellationReason);
  };

  return (
    <Dialog open>
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('databaseExplorer.order.cancel.cancelOrder')}
        <IconButton size="small" color="inherit" onClick={handleOnClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minWidth: 300 }}>
        <FormControl fullWidth margin="dense">
          <InputLabel>{t('databaseExplorer.order.cancel.cancellationReason')}</InputLabel>
          <Select
            value={cancellationReason || ''}
            label={t('databaseExplorer.order.cancel.cancellationReason')}
            onChange={handleChange}
            sx={{ textTransform: 'capitalize' }}
          >
            {Object.values(OrderCancellationReason).map((reason) => (
              <MenuItem key={reason} value={reason} sx={{ textTransform: 'capitalize' }}>
                {reason.replaceAll('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: ({ spacing }) => spacing(3),
          paddingLeft: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <LoadingButton
          variant="contained"
          color="error"
          loading={isLoading}
          disabled={isLoading || !cancellationReason}
          onClick={() => onConfirm(cancellationReason)}
        >
          {t('databaseExplorer.order.cancel.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
