import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinTable,
  ManyToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Country } from './Country';
import { Product } from './Product';
import { Show } from './Show';

export enum CommercialEventType {
  standard = 'standard',
  golden_days = 'golden_days',
  voggt_anniversary = 'voggt_anniversary',
  featured = 'featured',
}

@Entity()
export class CommercialEvent {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ unique: true })
  name!: string;

  @Column({ nullable: true, type: 'character varying', unique: true })
  internalName!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  description!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  iconUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  coverUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  bannerUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  largeCoverUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  largeBannerUrl!: string | null;

  @Column({ type: 'timestamp' })
  startAt!: Date;

  @Column({ type: 'timestamp' })
  endAt!: Date;

  @ManyToMany(() => Country)
  @JoinTable({
    name: 'commercial_event_country',
  })
  countries!: Country[];

  @ManyToMany(() => Show)
  @JoinTable({
    name: 'commercial_event_show',
  })
  shows!: Show[];

  @ManyToMany(() => Product)
  @JoinTable({
    name: 'commercial_event_product',
  })
  products!: Product[];

  @CreateDateColumn()
  createdAt!: Date;

  @UpdateDateColumn()
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt!: Date;

  @Column({
    type: 'enum',
    enum: CommercialEventType,
    default: CommercialEventType.standard,
  })
  commercialEventType!: CommercialEventType;

  @Column({ default: 0 })
  weight!: number;

  @Column({ nullable: true, type: 'timestamp' })
  realStartAt!: Date | null;

  @Column({ default: true })
  isVisibleForSellerStudio!: boolean;
}
