import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import { useOwnUser } from '../../context/own-user.context';
import { useGetCategories } from '../../queries';
import { useListCommercialEvent } from '../../voggt-database-explorer/commercial-event/hooks/use-list-commercial-event';

import { RecommendedItems } from './components/RecommendedItems';
import { FeedRecommendationForm, useFeedRecommendation } from './useFeedRecommendation';

const recommendationTypeOptions: {
  key: FeedRecommendationForm['recommendationType'];
  name: string;
}[] = [
  { key: 'show-user', name: 'Show by user' },
  { key: 'show-device', name: 'Show by device' },
  { key: 'product-user', name: 'Product by user' },
  { key: 'product-device', name: 'Product by device' },
];

export const FeedRecommendationView = () => {
  const { hasPermissions } = useOwnUser();
  const { data: categories } = useGetCategories();
  const categoryMap =
    categories?.reduce<Record<string, string>>((acc, category) => {
      acc[category.id] = category.name;
      return acc;
    }, {}) || {};

  const { data: commercialEvents } = useListCommercialEvent();
  const commercialEventsAvailable =
    commercialEvents?.filter((event) => !event.deletedAt && event.endAt > new Date()) || [];

  const { control, refetch, register, data, disableSubmit, watch } = useFeedRecommendation();

  if (!hasPermissions(PERMISSIONS.FEED_RECOMMANDATION.READ_FEED_RECOMMANDATION)) {
    return <div>Not authorized</div>;
  }

  const isProductRequest =
    watch('recommendationType') === 'product-user' ||
    watch('recommendationType') === 'product-device';

  return (
    <section className="h-full flex flex-col gap-8 w-full p-10">
      <div className="flex flex-col gap-2 w-1/2 shadow-md bg-white p-4 rounded-lg">
        <h1>Feed Recommendation</h1>

        <Controller
          name="recommendationType"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="recommendationType-label"></InputLabel>
              <Select labelId="recommendationType-label" {...field}>
                {recommendationTypeOptions.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <TextField
          fullWidth
          {...register('identifier')}
          label={'Identifier (deviceid or userid)'}
        />

        {categories ? (
          <Controller
            name="categoryIds"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="category-selector">Categories</InputLabel>
                <Select<string[]>
                  labelId="category-selector"
                  multiple
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={categoryMap[value]} />
                      ))}
                    </Box>
                  )}
                  {...field}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ) : null}

        {commercialEventsAvailable.length ? (
          <Controller
            name="eventId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="eventId-selector">Commercial Events</InputLabel>
                <Select<string>
                  labelId="eventId-selector"
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  {...field}
                >
                  {commercialEventsAvailable.map((commercialEvent) => (
                    <MenuItem key={commercialEvent.id} value={commercialEvent.id}>
                      <span>
                        {commercialEvent.countries.map((country) => country.id).join(', ')}
                      </span>{' '}
                      - {commercialEvent.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ) : null}

        {isProductRequest && (
          <>
            <Controller
              name="sortByPrice"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="sortByPrice-label">Sort by price</InputLabel>
                  <Select labelId="sortByPrice-label" {...field} label="Sort by price">
                    <MenuItem value={'ASC'}>ASC</MenuItem>
                    <MenuItem value={'DESC'}>DESC</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <FormControlLabel
              control={<Switch {...register('isOnlyFromLiveShows')} />}
              label="Is only from live shows"
              labelPlacement="start"
            />
          </>
        )}

        <FormControlLabel
          control={<Switch {...register('refresh')} />}
          label="Refresh (invalide cache)"
          labelPlacement="start"
        />

        <Button onClick={() => refetch()} variant="contained" disabled={disableSubmit}>
          Check
        </Button>
      </div>

      <RecommendedItems data={data?.data} />
    </section>
  );
};
