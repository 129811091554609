/* eslint-disable @typescript-eslint/no-unused-vars */
import { SentinelFraudCase, SentinelFraudster, SentinelDevice } from '@bits-app/voggtpit-shared';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PanelDetails } from '../../components/layouts/PanelDetails';

import { useSentinelDetail } from './hooks/useSentinelDetail';
import { panelDetailSentinelFraudCase } from './sentinel.const';

export const SentinelDetail = () => {
  const { id: sentinelId } = useParams();
  const { data, isLoading } = useSentinelDetail({ sentinelId: Number(sentinelId) });
  const { t } = useTranslation();

  // TODO: add fraudsters and devices when API wil resolve those field instead of ids only
  const fraudsters = data?.data?.fraudsters || [];
  const devices = data?.data?.devices || [];

  return (
    <Box sx={{ padding: 2, display: 'flex', flex: 1 }}>
      <Grid container spacing={2} gap={2} mt={2}>
        <Grid xs={12} md={4}>
          {data?.data && (
            <PanelDetails<SentinelFraudCase>
              title={t('databaseExplorer.panel.main')}
              information={panelDetailSentinelFraudCase}
              data={data?.data}
              loading={isLoading}
            />
          )}
        </Grid>

        {/* <Grid xs={12}>
          <ListCustom<SentinelFraudster>
            cellDefinition={sentinelFraudsterCellDefinition}
            results={fraudsters}
            title={t('databaseExplorer.panel.fraudsters')}
            enableGlobalFilter={false}
            hideWhenNoData
          />
        </Grid>

        <Grid xs={12}>
          <ListCustom<SentinelDevice>
            cellDefinition={sentinelDevicesCellDefinition}
            results={devices}
            title={t('databaseExplorer.panel.devices')}
            enableGlobalFilter={false}
            hideWhenNoData
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};
