import {
  PERMISSIONS,
  currencySymbol,
  formatAmount,
  mapRefundReasonValueToKey,
  RefundDebitedAccount,
  RefundReason,
} from '@bits-app/voggtpit-shared';
import { Checkbox, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableCellBodyNoBorderBottom } from '@/components/elements/StyledTableCell';
import { OrderItem } from '@/entities/order-item.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { useOwnUser } from '../../../../context/own-user.context';
import {
  selectDebitedAccount,
  selectIsOrderRefundForDeliveredByHand,
  selectIsOrderRefundForFreeShipping,
  selectMaxRefundableAmountFor,
  selectRefundAmountFor,
  selectRefundCommissionToSeller,
  selectRefundFullOrder,
  selectRefundReason,
} from '../../../redux/refund.selectors';
import { refundActions } from '../../../redux/refund.slice';

import { useOrderItemRefundForm } from './use-order-item-refund-form';

type OrderItemRefundFormProps = {
  orderItem: OrderItem;
  disabled?: boolean;
  activated: boolean;
};

export const OrderItemRefundForm = ({
  orderItem,
  activated,
  disabled,
}: OrderItemRefundFormProps) => {
  const { hasPermissions } = useOwnUser();
  const userCanToggleRefundCommissionToSeller = hasPermissions(
    PERMISSIONS.REFUND_COMMISSION.WRITE_REFUND_COMMISSION,
  );
  const refundReason = useAppSelector(selectRefundReason);
  const debitedAccount = useAppSelector(selectDebitedAccount);
  const amount = useAppSelector((state) => selectRefundAmountFor(state, orderItem.id));
  const refundFullOrder = useAppSelector((state) => selectRefundFullOrder(state, orderItem.id));
  const maxAmount = useAppSelector((state) => selectMaxRefundableAmountFor(state, orderItem.id));
  const disabledFreeShippingRefundReason = useAppSelector(selectIsOrderRefundForDeliveredByHand);
  const disabledDeliveredByHandRefundReason = useAppSelector(selectIsOrderRefundForFreeShipping);
  const refundCommissionToSeller = useAppSelector((state) =>
    selectRefundCommissionToSeller(state, orderItem.id),
  );

  const {
    handleOnAmountChange,
    handleOnDebitedAccountSelected,
    handleOnReasonSelected,
    handleRefundFullOrder,
    handleToggleRefundCommissionToSeller,
  } = useOrderItemRefundForm(orderItem);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleFormatAmount = () => {
    if (isNaN(Number(amount))) {
      dispatch(
        refundActions.setRefundAmountFor({
          orderItemId: orderItem.id,
        }),
      );
      return;
    }

    dispatch(
      refundActions.setRefundAmountFor({
        orderItemId: orderItem.id,
        amount: formatAmount(Number(amount)),
      }),
    );
  };

  const isRefundReasonDisabled = (refundReason: RefundReason) => {
    if (refundReason === RefundReason.freeShipping) {
      return disabledFreeShippingRefundReason;
    }

    if (refundReason === RefundReason.deliveredByHand) {
      return disabledDeliveredByHandRefundReason;
    }

    return false;
  };

  return (
    <>
      <TableCellBodyNoBorderBottom
        padding="checkbox"
        maxWidth={120}
        sx={{
          minWidth: 120,
        }}
      >
        {activated && (
          <Checkbox
            onChange={handleRefundFullOrder}
            disabled={disabled}
            checked={refundFullOrder}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: 120,
            }}
          />
        )}
      </TableCellBodyNoBorderBottom>
      <TableCellBodyNoBorderBottom
        padding="checkbox"
        maxWidth={120}
        sx={{
          minWidth: 120,
        }}
      >
        {activated && (
          <Checkbox
            onChange={handleToggleRefundCommissionToSeller}
            disabled={!userCanToggleRefundCommissionToSeller || disabled}
            checked={refundCommissionToSeller}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: 120,
            }}
          />
        )}
      </TableCellBodyNoBorderBottom>
      <TableCellBodyNoBorderBottom
        padding="checkbox"
        maxWidth={230}
        sx={{
          minWidth: 200,
        }}
      >
        {activated && (
          <Select
            fullWidth
            size="small"
            value={refundReason ?? ''}
            onChange={handleOnReasonSelected}
            title={
              refundReason
                ? t(`orderItemLabels.refundReasons.${mapRefundReasonValueToKey[refundReason]}`)
                : undefined
            }
            disabled={disabled}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: 200,
            }}
          >
            {Object.entries(RefundReason).map(([key, reason]) => (
              <MenuItem key={key} value={reason} disabled={isRefundReasonDisabled(reason)}>
                {t(`orderItemLabels.refundReasons.${key}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom padding="checkbox">
        {activated && (
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{
              minWidth: 120,
              '&[aria-invalid="true"]': {
                color: 'red',
                borderColor: 'red',
              },
            }}
            value={amount ?? ''}
            onChange={handleOnAmountChange}
            onBlur={handleFormatAmount}
            disabled={orderItem.amount === 0 || refundFullOrder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{currencySymbol(orderItem.currency)}</InputAdornment>
              ),
              inputProps: {
                lang: 'en_US',
                pattern: '[0-9]*.?[0-9]*',
                min: 0,
                max: maxAmount,
                sx: {
                  textAlign: 'right',
                },
                title: t('refund.process.maxAmount', {
                  amount: `${formatAmount(maxAmount)} ${currencySymbol(orderItem.currency)}`,
                }),
              },
            }}
          />
        )}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom
        padding="checkbox"
        sx={{
          minWidth: 145,
          paddingLeft: ({ spacing }) => spacing(2),
        }}
      >
        {activated && (
          <Select
            fullWidth
            size="small"
            sx={{
              minWidth: 100,
            }}
            disabled={disabled}
            value={debitedAccount ?? ''}
            onChange={handleOnDebitedAccountSelected}
          >
            {Object.entries(RefundDebitedAccount).map(([key, account]) => (
              <MenuItem key={key} value={account}>
                {t(`orderItemLabels.debitedAccounts.${key}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCellBodyNoBorderBottom>
    </>
  );
};
