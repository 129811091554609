import type { Currency } from '../../currency.enum';
import type { PartnerProgramType } from '../partner-program.type';
export class CreatePartnerProgramDto implements Partial<PartnerProgramType> {
  percentage?: number;
  percentageMaxAmount?: number;
  name!: string;
  amount?: number;
  expireInType!: 'months' | 'days' | 'weeks' | 'years' | 'minutes' | 'hours';
  expireInNumber!: 0 | number;
  currency!: Currency;
  isPrivate!: boolean;
}
