import { useState, useEffect } from 'react';

import { Schema } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { QUERIES_KEYS } from '@/queries';
import { EntityWrapper } from '@/voggt-database-explorer/redux/database-explorer';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useCreateShowNotification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { id: showId } = useParams();
  const [error, setError] = useState<string | null>(null);

  const { data: schemas = [], isLoading } = useGetShowNotification();

  useEffect(() => {
    if (error) {
      snackbar.error(error);
    }
  }, [error, snackbar]);

  const { mutate } = useMutation({
    mutationFn: (notification: Partial<Notification>) =>
      authenticatedInstance.post('/notifications-center/create/', {
        ...notification,
        showId: Number(showId),
      }),
    onSuccess: () => {
      snackbar.success('Notification successfully created');
      navigate(`/show/${showId}/notifications`);
    },
    onError: (error: string | CanceledRequestError) => {
      if (error instanceof CanceledRequestError) {
        setError(null);
        return;
      }

      setError(error ?? 'error');
    },
  });

  const schemasWithTraduction = schemas.map((schema) => ({
    ...schema,
    categoryLabel: t('infos'),
  }));

  return {
    header: {
      title: 'Create new notification',
    },
    schemas: schemasWithTraduction,
    onCreate: mutate,
    isLoading,
  };
};

const useGetShowNotification = () => {
  return useQuery(QUERIES_KEYS.notificationMutation(), async () => {
    const { data } = await authenticatedInstance.get<Schema<EntityWrapper>[]>(
      '/notifications-center/mutation-schema',
    );
    return data;
  });
};
