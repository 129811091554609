import { useReducer, useRef } from 'react';

import { ShipmentRating } from '@bits-app/bits-server-data';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '@/queries';

import { ModerateRatingForm } from './utils';

export const ShipmentRatingRowAction = ({
  row,
  sellerId,
}: {
  row: ShipmentRating;
  sellerId: number;
}) => {
  const snackbar = useSnackbar();
  const { handleSubmit, control, watch } = useForm<ModerateRatingForm>();
  const [menuIsOpen, toggleMenu] = useReducer((p) => !p, false);
  const ref = useRef(null);

  const { mutate } = useMutation({
    mutationFn: (input: ModerateRatingForm) => {
      return authenticatedInstance.post(`database-explorer/shipment-rating/moderate`, {
        ...input,
        shipmentRatingIds: [row.id],
      });
    },
    onSuccess: () => {
      snackbar.success('Rating moderated');
      queryClient.invalidateQueries(QUERIES_KEYS.shipmentRatingList(sellerId));
    },
    onError: (error: Error) => {
      snackbar.error(error.message);
    },
  });

  return (
    <>
      <IconButton onClick={toggleMenu} ref={ref}>
        <PublishedWithChangesIcon />
      </IconButton>

      <Dialog open={menuIsOpen} onClose={toggleMenu}>
        <DialogTitle>Moderate on {row.user.username}'s rating</DialogTitle>
        <DialogContent className="flex flex-col gap-4">
          <Controller
            name="shouldBeModerated"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="shipment-rating-label">Moderate</InputLabel>
                <Select labelId="shipment-rating-label" {...field} label="Moderate">
                  <MenuItem key={'true'} value={'true'}>
                    Moderate: hide from seller and remove from their average rating
                  </MenuItem>
                  <MenuItem key={'false'} value={'false'}>
                    Remove moderation: show to seller and include in their average rating
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            onClick={handleSubmit((d) => mutate(d))}
            disabled={!watch('shouldBeModerated')}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
