import { RecommendationResponse } from '../useFeedRecommendation';

import { ProductFeedRecommendationPreview } from './ProductFeedRecommendationPreview';
import { ShowFeedRecommendationPreview } from './ShowFeedRecommendationPreview';

export const RecommendedItems = ({ data }: { data?: RecommendationResponse }) => {
  if (data?.shows) {
    return (
      <div className="gap-2 grid lg:grid-cols-4 grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6">
        {data.shows?.map((show, index) => (
          <ShowFeedRecommendationPreview show={show} key={show.id} index={index} />
        ))}
      </div>
    );
  }

  if (data?.products) {
    return (
      <div className="gap-2 grid lg:grid-cols-4 grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6">
        {data.products?.map((product, index) => (
          <ProductFeedRecommendationPreview product={product} key={product.id} index={index} />
        ))}
      </div>
    );
  }

  return null;
};
