import { UserDataConsent } from '@bits-app/bits-server-data';

import { Information } from '@/components/layouts/PanelDetails';

export const userDataConsentDetail: Information<UserDataConsent>[] = [
  {
    value: 'userHasReceivedDataConsentRequest',
    label: 'userHasReceivedDataConsentRequest',
    type: 'boolean',
  },
  {
    value: 'hasGivenConsentForAppPersonalizationAt',
    label: 'hasGivenConsentForAppPersonalizationAt',
    type: 'date-with-time',
  },
  {
    value: 'hasGivenConsentForEmailPromotionsAndVouchersAt',
    label: 'hasGivenConsentForEmailPromotionsAndVouchersAt',
    type: 'date-with-time',
  },
  {
    value: 'hasGivenConsentForEmailShowsAndEventsAt',
    label: 'hasGivenConsentForEmailShowsAndEventsAt',
    type: 'date-with-time',
  },
  {
    value: 'hasGivenConsentForEmailNewsAndNewFeaturesAt',
    label: 'hasGivenConsentForEmailNewsAndNewFeaturesAt',
    type: 'date-with-time',
  },
  {
    value: 'hasGivenConsentForEmailPartnerNewsAt',
    label: 'hasGivenConsentForEmailPartnerNewsAt',
    type: 'date-with-time',
  },
  {
    value: 'hasGivenConsentForSMSAt',
    label: 'hasGivenConsentForSMSAt',
    type: 'date-with-time',
  },
  {
    value: 'hasGivenConsentForDataSharingWithThirdPartiesAt',
    label: 'hasGivenConsentForDataSharingWithThirdPartiesAt',
    type: 'date-with-time',
  },
];

export const sellerDataConsentDetail: Information<UserDataConsent>[] = [
  {
    value: 'sellerHasReceivedDataConsentRequest',
    label: 'sellerHasReceivedDataConsentRequest',
    type: 'boolean',
  },
  {
    value: 'sellerHasGivenConsentForEmailsAt',
    label: 'sellerHasGivenConsentForEmailsAt',
    type: 'date-with-time',
  },
  {
    value: 'sellerHasGivenConsentForSMSAt',
    label: 'sellerHasGivenConsentForSMSAt',
    type: 'date-with-time',
  },
];
