export enum NotificationTriggerAudienceEnum {
  show_reminders = 'show_reminders',
  seller_followers = 'seller_followers',
  category_buyers = 'category_buyers',
  category_followers = 'category_followers',
  related_categories_followers = 'related_categories_followers',
  related_categories_followers_extended = 'related_categories_followers_extended',
}

export type Notification = {
  id: string;
  title: string;
  body: string;
  audiences: NotificationTriggerAudienceEnum[];
};
