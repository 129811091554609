import {
  Order,
  Shipment,
  Promotion,
  Product,
  ShippingAddress,
  Show,
  User,
  OrderStatus,
  PaymentStatus,
} from '@bits-app/bits-server-data';
import {
  centsToUnit,
  currencySymbol,
  formatAmount,
  formatAmountWithCurrency,
} from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import {
  formatDate,
  getLinkShippingTracking,
  getStripeAccountUrl,
  getStripePaymentUrl,
  getStripeTransferUrl,
} from '@/utils';

const PAYMENT_STATUS = Object.values(PaymentStatus) as PaymentStatus[];
const ORDER_STATUS = Object.values(OrderStatus) as OrderStatus[];

export type OrderWithProduct = Order & { id: string } & Pick<Product, 'isPreOrder' | 'salesType'>;

export const CellOrderDefinition: CellDefinition<OrderWithProduct>[] = [
  {
    id: 'id',
    label: 'OrderId',
    type: 'internal-link',
    getLink: (order) => ({ link: `/order/${order?.id}`, label: order?.id }),
    columnFilter: { type: 'text' },
  },
  {
    id: 'showId',
    label: 'Show ID',
    type: 'internal-link',
    getLink: (order) => ({ link: `/show/${order?.showId}`, label: order?.showId }),
    enableColumnFilter: false,
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
    columnFilter: { type: 'multi-select', options: ORDER_STATUS.map((value) => ({ value })) },
  },
  {
    id: 'paymentStatus',
    label: 'Payment Status',
    type: 'badge',
    columnFilter: { type: 'multi-select', options: PAYMENT_STATUS.map((value) => ({ value })) },
  },
  {
    id: 'seller',
    label: 'Seller',
    type: 'internal-link',
    getLink: (order) => ({ link: `/user/${order?.sellerId}`, label: order?.seller.username }),
    enableColumnFilter: false,
  },
  {
    id: 'customer',
    label: 'Buyer',
    type: 'internal-link',
    getLink: (order) => ({ link: `/user/${order?.customerId}`, label: order?.customer.username }),
    enableColumnFilter: false,
  },
  {
    id: 'product',
    label: 'Product',
    type: 'internal-link',
    getLink: (order) => ({ link: `/product/${order?.productId}`, label: order?.product.name }),
    enableColumnFilter: false,
  },
  {
    id: 'isPreOrder',
    label: 'Is pre-order',
    getValue: (order) => (order.product.isPreOrder ? 'true' : 'false'),
  },
  {
    id: 'salesType',
    label: 'Sales type',
    getValue: (order) => order.product.salesType,
  },
  {
    id: 'shippingAmount',
    label: 'Shipping amount',
    type: 'money',
    getValue: (order) =>
      order.shippingAmount != null
        ? `${formatAmount(centsToUnit(Number(order.shippingAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
    enableColumnFilter: false,
  },
  {
    id: 'deductedShippingCost',
    label: 'Deducted shipping cost',
    type: 'money',
    getValue: (order) =>
      order.deductedShippingCost != null
        ? `${formatAmount(centsToUnit(Number(order.deductedShippingCost)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'promotionAmount',
    label: 'Promotion amount',
    type: 'money',
    getValue: (order) =>
      order.promotionAmount != null
        ? `${formatAmount(centsToUnit(Number(order.promotionAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
    enableColumnFilter: false,
  },
  {
    id: 'promotionTopUpAmountTransferred',
    label: 'Promotion top up amount transferred',
    type: 'money',
    getValue: (order) =>
      order.promotionTopUpAmountTransferred != null
        ? `${formatAmount(
            centsToUnit(Number(order.promotionTopUpAmountTransferred)),
          )}${currencySymbol(order.currency)}`
        : '',
    enableColumnFilter: false,
  },
  {
    id: 'promotionTopUpStripeTransferId',
    label: 'Promotion top up stripe transfer ID',
    type: 'external-link',
    getLink: (order) => ({
      link: getStripeTransferUrl(order?.promotionTopUpStripeTransferId),
      label: order?.promotionTopUpStripeTransferId,
    }),
    enableColumnFilter: false,
  },
  {
    id: 'refundedAmount',
    label: 'Refunded amount',
    type: 'money',
    getValue: (order) =>
      order.refundedAmount > 0 && order.refundReason
        ? `${formatAmount(centsToUnit(Number(order.refundedAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
    enableColumnFilter: false,
  },
  {
    id: 'refundReason',
    label: 'Refund reason',
    enableColumnFilter: false,
  },
  {
    id: 'refundAgent',
    label: 'Refund agent',
    enableColumnFilter: false,
  },
  {
    id: 'refundDebitedAccount',
    label: 'Refund debited account',
    enableColumnFilter: false,
  },
  {
    id: 'refundedAt',
    label: 'Refund date',
    type: 'date-with-time',
    enableColumnFilter: false,
  },
  {
    id: 'shippingFeesCancellationRequestedAt',
    label: 'Shipping fees cancellation requested at',
    type: 'date-with-time',
    enableColumnFilter: false,
  },
  {
    id: 'cancellationReason',
    label: 'Cancellation reason',
    enableColumnFilter: false,
  },
  {
    id: 'cancellationRequesterType',
    label: 'Cancellation requester type',
    enableColumnFilter: false,
  },
  {
    id: 'cancellationRequestedAt',
    label: 'Cancellation requested at',
    type: 'date-with-time',
    enableColumnFilter: false,
  },
  {
    id: 'feeDeductibleFromBuyerPayment',
    label: 'Commission',
    type: 'money',
    getValue: (order) =>
      order.feeDeductibleFromBuyerPayment != null
        ? `${formatAmount(
            centsToUnit(Number(order.feeDeductibleFromBuyerPayment)),
          )}${currencySymbol(order.currency)}`
        : '',
    enableColumnFilter: false,
  },
  {
    id: 'createdAt',
    label: 'Purchased at',
    type: 'date-with-time',
    enableColumnFilter: false,
  },
  {
    id: 'shippingProvider',
    label: 'Shipping Provider',
    enableColumnFilter: false,
  },
  {
    id: 'trackingNumber',
    label: 'Tracking Number',
    type: 'external-link',
    getLink: (order) => ({
      link: getLinkShippingTracking(order),
      label: order?.trackingNumber ?? '',
    }),
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
    enableColumnFilter: false,
  },
];

export const panelDetailsOrder: Information<Order>[] = [
  { label: 'id', value: 'id' },
  {
    label: 'amount',
    value: 'amount',
    format: (order) =>
      order?.currency && !isNaN(order?.amount)
        ? formatAmountWithCurrency(centsToUnit(Number(order.amount)), order.currency)
        : undefined,
  },
  { value: 'status', label: 'Order status', type: 'badge' },
  { value: 'paymentStatus', label: 'Payment status', type: 'badge' },
  {
    value: 'stripePaymentIntentId',
    label: 'Payment intent ID',
    type: 'external-link',
    link: (order) =>
      order?.stripePaymentIntentId ? getStripePaymentUrl(order?.stripePaymentIntentId) : '',
  },
  { label: 'Currency', value: 'currency' },
  {
    value: 'feeDeductibleFromBuyerPayment',
    label: 'Commission',
    format: (order) =>
      order?.currency && !isNaN(order?.feeDeductibleFromBuyerPayment)
        ? formatAmountWithCurrency(
            centsToUnit(Number(order.feeDeductibleFromBuyerPayment)),
            order.currency,
          )
        : undefined,
  },
  {
    label: 'Payout status',
    value: 'payoutStatus',
  },
  {
    label: 'Created at',
    value: 'createdAt',
    format: (order) =>
      order?.createdAt ? formatDate(order?.createdAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
  {
    label: 'Updated at',
    value: 'updatedAt',
    format: (order) =>
      order?.updatedAt ? formatDate(order?.updatedAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
];

export type PanelDetailsOrderWithPromotionsType = Order & Pick<Promotion, 'id' | 'name'>;
export const panelDetailsPromotions: Information<PanelDetailsOrderWithPromotionsType>[] = [
  {
    value: 'id',
    label: 'Promotion ID',
    type: 'internal-link',
    link: (order) => `/promotions/${order.currency}`,
    format: (order) => order?.promotion?.id,
  },
  {
    value: 'name',
    label: 'Promotion name',
    format: (order) => order?.promotion?.name,
  },
  {
    value: 'promotionTopUpStripeTransferId',
    label: 'Promotion top up Stripe transferId',
    type: 'external-link',
    link: (order) => getStripeTransferUrl(order?.promotionTopUpStripeTransferId),
  },
  {
    value: 'promotionTopUpAmountTransferred',
    label: 'Promotion top up amount transferred',
    format: (order) =>
      order?.currency && order?.promotionTopUpAmountTransferred
        ? formatAmountWithCurrency(
            centsToUnit(Number(order?.promotionTopUpAmountTransferred)),
            order.currency,
          )
        : undefined,
  },
];

export const panelDetailsUser = (kind: 'seller' | 'buyer'): Information<User>[] => [
  { label: 'id', value: 'id', type: 'internal-link', link: (user) => `/user/${user.id}` },
  { label: 'Username', value: 'username' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'lastName', label: 'Lastname' },
  {
    value: 'stripeAccountId',
    label: 'Stripe Account ID',
    link: (user) => {
      if (kind === 'buyer') {
        return user.stripeCustomerId && getStripeAccountUrl(user.stripeCustomerId);
      }

      return user.stripeAccountId && getStripeAccountUrl(user.stripeAccountId);
    },
    type: 'external-link',
  },
];

export const panelDetailsProduct: Information<Product>[] = [
  { label: 'id', value: 'id', type: 'internal-link', link: (product) => `/product/${product.id}` },
  { value: 'name', label: 'Name' },
  { value: 'currency', label: 'Currency', type: 'badge' },
  { value: 'salesType', label: 'Sales type' },
  { value: 'isPreOrder', label: 'Is pre-order', type: 'boolean' },
];

export const panelDetailsShippingAddress: Information<ShippingAddress>[] = [
  {
    value: 'id',
    label: 'Id',
    type: 'internal-link',
    link: (shippingAddress) => `/shipping-address/${shippingAddress.id}`,
  },
  { value: 'phoneNumber', label: 'Phone number' },
  { value: 'email', label: 'Email' },
  { value: 'inputLine1', label: 'Input line 1' },
  { value: 'inputLine2', label: 'Input line 2' },
  { value: 'inputCity', label: 'City' },
  { value: 'inputPostalCode', label: 'Postal code' },
  { value: 'inputCountry', label: 'Country' },
];

export const panelDetailsShow: Information<Show>[] = [
  { value: 'id', label: 'Id', type: 'internal-link', link: (show) => `/show/${show.id}` },
  { value: 'name', label: 'Show name' },
  { value: 'description', label: 'Show description' },
  {
    value: 'startedAt',
    label: 'Started at',
    format: (show) => {
      return show?.startedAt ? formatDate(show.startedAt, 'dd/MM/yyyy HH:mm:ss') : undefined;
    },
  },
  {
    value: 'endedAt',
    label: 'End at',
    format: (show) => (show?.endedAt ? formatDate(show.endedAt, 'dd/MM/yyyy HH:mm:ss') : undefined),
  },
];

export const panelDetailDelivery: Information<Order>[] = [
  { value: 'shippingProvider', label: 'Shipping Provider' },
  {
    value: 'shippingAmount',
    label: 'Shipping Amount',
    format: (order) =>
      order?.shippingAmount
        ? formatAmountWithCurrency(centsToUnit(Number(order?.shippingAmount)), order.currency)
        : undefined,
  },
  {
    value: 'deductedShippingCost',
    label: 'Deducted Shipping cost',
    format: (order) =>
      order?.deductedShippingCost
        ? formatAmountWithCurrency(centsToUnit(Number(order?.deductedShippingCost)), order.currency)
        : undefined,
  },
  {
    label: 'Tracking number',
    value: 'trackingNumber',
    type: 'external-link',
    link: (order) => getLinkShippingTracking(order) ?? '',
  },
  {
    label: 'Url label',
    value: 'urlLabel',
    type: 'external-link',
    format: (order) => (order?.urlLabel ? 'See doc' : null),
    link: (order) => order?.urlLabel,
  },
];

export const panelDetailRefund: Information<Order>[] = [
  {
    label: 'Refunded at',
    value: 'refundedAt',
  },
  {
    label: 'Refund agent',
    value: 'refundAgent',
  },
  {
    label: 'Refund reason',
    value: 'refundReason',
  },
  {
    label: 'Refunded amount',
    value: 'refundedAmount',
    format: (order) =>
      order?.refundedAmount
        ? formatAmountWithCurrency(centsToUnit(Number(order?.refundedAmount)), order.currency)
        : undefined,
  },
  {
    label: 'Refunded debited account',
    value: 'refundDebitedAccount',
  },
  {
    label: 'Buyer service fee refunded at',
    value: 'buyerServiceFeeAmountRefundedAt',
    type: 'date-with-time',
  },
  {
    value: 'shippingFeesRefundedAt',
    label: 'Shipping fees refunded at',
    type: 'date-with-time',
  },
];

export const panelDetailsCancellation: Information<Order>[] = [
  {
    label: 'Cancellation requested at',
    value: 'cancellationRequestedAt',
  },
  {
    label: 'Cancellation requested type',
    value: 'cancellationRequesterType',
  },
  {
    label: 'Cancellation reason',
    value: 'cancellationReason',
  },
];

export const panelDetailsShipment: Information<Shipment>[] = [
  {
    label: 'Shipment ID',
    value: 'id',
    type: 'internal-link',
    link: (shipment) => `/shipment/${shipment.id}`,
  },
  {
    label: 'Shipment status',
    value: 'status',
    type: 'badge',
  },
  {
    label: 'Easy shipping amount',
    value: 'easyShippingAmount',
    format: (shipment) =>
      shipment?.easyShippingAmount
        ? formatAmountWithCurrency(
            centsToUnit(Number(shipment?.easyShippingAmount)),
            shipment.currency,
          )
        : undefined,
  },
  {
    label: 'External shipping order ID',
    value: 'externalShippingOrderId',
  },
  {
    value: 'labelPrintUrl',
    label: 'Label print url',
    type: 'external-link',
    link: (shipment) => shipment?.labelPrintUrl,
  },
  {
    label: 'Shipping address ID',
    value: 'shippingAddressId',
    type: 'internal-link',
    link: (shipment) => `/shipping-address/${shipment.shippingAddressId}`,
  },
  {
    label: 'ratingId',
    value: 'ratingId',
  },
  {
    label: 'Created at',
    value: 'createdAt',
    format: (order) =>
      order?.createdAt ? formatDate(order?.createdAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
  {
    label: 'Updated at',
    value: 'updatedAt',
    format: (order) =>
      order?.updatedAt ? formatDate(order?.updatedAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
];

export type PanelDetailsOrderWithCollectedFeeAndFeeAmount = Order & {
  collectedFee: number;
  feeAmount: number;
};
export const panelDetailsServiceFee: Information<PanelDetailsOrderWithCollectedFeeAndFeeAmount>[] =
  [
    {
      label: 'Fee deductible from buyer payment',
      value: 'feeDeductibleFromBuyerPayment',
      format: (order) =>
        order?.feeDeductibleFromBuyerPayment
          ? formatAmountWithCurrency(
              centsToUnit(order.feeDeductibleFromBuyerPayment),
              order.currency,
            )
          : undefined,
    },
    {
      label: 'Fee deductible from top up to seller',
      value: 'feeDeductibleFromTopUpToSeller',
      format: (order) =>
        order?.feeDeductibleFromTopUpToSeller
          ? formatAmountWithCurrency(
              centsToUnit(order.feeDeductibleFromTopUpToSeller),
              order.currency,
            )
          : undefined,
    },
    {
      label: 'Fee amount',
      subDescription: 'fee deductible from buyer payment + fee deductible from top up to seller',
      value: 'feeAmount',
      format: (order) =>
        order
          ? formatAmountWithCurrency(
              centsToUnit(
                (order?.feeDeductibleFromBuyerPayment ?? 0) +
                  (order?.feeDeductibleFromTopUpToSeller ?? 0),
              ),
              order.currency,
            )
          : undefined,
    },
    {
      label: 'Buyer service fee amount',
      value: 'buyerServiceFeeAmount',
      format: (order) =>
        order?.buyerServiceFeeAmount
          ? formatAmountWithCurrency(centsToUnit(order.buyerServiceFeeAmount), order.currency)
          : undefined,
    },

    {
      label: 'Collected fee',
      subDescription: 'buyer service fee amount + fee deductible from buyer payment',
      value: 'collectedFee',
      format: (order) =>
        order
          ? formatAmountWithCurrency(
              centsToUnit(
                (order?.buyerServiceFeeAmount ?? 0) + (order?.feeDeductibleFromBuyerPayment ?? 0),
              ),
              order.currency,
            )
          : undefined,
    },
  ];
