import { useCallback, useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Dialog } from '@mui/material';

import { DataText } from '@/components/elements/DataWrapper/DataText';
import { Panel } from '@/components/layouts/Panel';
import { BulkUpdateTransactionStatusModal } from '@/voggt-database-explorer/users/views/profile/UserPayoutList/BulkUpdateTransactionStatus/BulkUpdateTransactionStatus.modal';
import { useLedgerBulkUpdateTransactionStatus } from '@/voggt-database-explorer/users/views/profile/UserPayoutList/BulkUpdateTransactionStatus/useLedgerBulkUpdateTransactionStatus';
import { ListCustom } from '@Elements/DataExplorer/ListCustom';
import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { useOwnUser } from '../../../../../context/own-user.context';
import { User } from '../../../users';

import { PayoutModal } from './components/Payout.modal';
import { useGeneratePayout } from './hooks/ledger.mutation';
import {
  CellDefinitionTransactionList,
  useUserDetailQuery,
  useUserTransactionsListQuery,
} from './hooks/ledger.query';
import { UserTransactionsListQueryResponse } from './misc/user-payout-list.type';

type UserPayoutListProps = {
  userId: User['id'];
};

export const UserPayoutList = ({ userId }: UserPayoutListProps) => {
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState({ page: 0, pageSize: 200 });

  const updateCurrentPage = (pagination: { page: number; pageSize: number }) => {
    setCurrentPage(pagination);
  };

  const snackbar = useSnackbar();
  const { hasPermissions } = useOwnUser();

  const userCanInitiatePayout = hasPermissions(PERMISSIONS.LEDGER.WRITE_LEDGER_PAYOUT);

  const { data, refetch: refetchUserTransactionsListQuery } = useUserTransactionsListQuery(
    userId,
    currentPage.page,
    currentPage.pageSize,
  );

  const { data: userDetail, refetch: refetchUserDetailQuery } = useUserDetailQuery(userId);

  const { mutate } = useGeneratePayout(userId);

  const toggleModal = useCallback(() => setPayoutModalIsOpen((prev) => !prev), []);

  const handleRefresh = useCallback(() => {
    refetchUserTransactionsListQuery();
    refetchUserDetailQuery();
  }, [refetchUserDetailQuery, refetchUserTransactionsListQuery]);

  const handleConfirmPayout = useCallback(() => {
    mutate(undefined, {
      onSuccess: ({ success, error }) => {
        if (success) {
          toggleModal();
          handleRefresh();
        }
        if (error) {
          snackbar.error('Ledger - ' + error);
        }
      },
    });
  }, [mutate, toggleModal, handleRefresh, snackbar]);

  const bulkUpdateTransactionStatusHook = useLedgerBulkUpdateTransactionStatus(userId);

  const ledgerPendingBalance = (userDetail?.ledgerBalance?.pendingBalance || 0) / 100;
  const ledgerAvailableBalance = (userDetail?.ledgerBalance?.availableBalance || 0) / 100;
  const ledgerInTransitBalance = (userDetail?.ledgerBalance?.transferInProgress || 0) / 100;
  const ledgerTotalBalance = ledgerPendingBalance + ledgerAvailableBalance;

  const stripePendingBalance = (userDetail?.stripeAccount.pendingBalance || 0) / 100;
  const stripeAvailableBalance = (userDetail?.stripeAccount.availableBalance || 0) / 100;
  const stripeInTransitBalance = (userDetail?.stripeAccount.transferInProgress || 0) / 100;
  const stripeTotalBalance = stripePendingBalance + stripeAvailableBalance;

  const mismatch = ledgerTotalBalance !== stripeTotalBalance;

  return (
    <>
      <div className="flex flex-col gap-4">
        {mismatch ? (
          <div className="bg-red-100 px-4 py-2 rounded-md">
            <p className="text-red-400">
              Missmatch between Stripe and Ledger, with a difference of{' '}
              {Math.abs(stripeTotalBalance - ledgerTotalBalance).toFixed(2)}€
            </p>
          </div>
        ) : null}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <Panel title="Ledger balance">
            <DataText title="Soon Available" value={ledgerPendingBalance} currency={'eur'} />
            <DataText title="Available to payout" value={ledgerAvailableBalance} currency={'eur'} />
            <DataText
              title="Soon + Available"
              value={ledgerTotalBalance.toFixed(2)}
              currency={'eur'}
            />
            <DataText title="Payouts in transit" value={ledgerInTransitBalance} currency={'eur'} />
          </Panel>

          <Panel title="Stripe balance">
            <DataText title="Soon Available" value={stripePendingBalance} currency={'eur'} />
            <DataText title="Available to payout" value={stripeAvailableBalance} currency={'eur'} />
            <DataText
              title="Soon + Available"
              value={stripeTotalBalance.toFixed(2)}
              currency={'eur'}
            />
            <DataText title="Payouts in transit" value={stripeInTransitBalance} currency={'eur'} />
          </Panel>
        </div>
      </div>

      <>
        <ListCustom<UserTransactionsListQueryResponse['transactions'][0]>
          cellDefinition={CellDefinitionTransactionList}
          results={data ? data.transactions : []}
          enableSorting
          disableScrollToTop
          enableFilters
          enableRowSelection
          allowMRTExport
          onRefresh={handleRefresh}
          paginationAPI={{
            onPaginationChange: updateCurrentPage,
            defaultResultPerPage: currentPage.pageSize,
            rowCount: data?.paginationMetadata.totalCount || 0,
          }}
        >
          <div className="flex flex-row gap-2">
            {bulkUpdateTransactionStatusHook.hasPermissionsToBulkUpdateTransactionStatus && (
              <ButtonOutlined
                onClick={bulkUpdateTransactionStatusHook.toggleModalStatus}
                sx={{ mx: 1 }}
              >
                Bulk update transaction status
              </ButtonOutlined>
            )}
            {userCanInitiatePayout && (
              <ButtonOutlined onClick={toggleModal} sx={{ mx: 1 }}>
                Payout seller
              </ButtonOutlined>
            )}
          </div>
        </ListCustom>
      </>

      <PayoutModal
        onClose={toggleModal}
        open={payoutModalIsOpen}
        onConfirm={handleConfirmPayout}
        amount={(userDetail?.ledgerBalance?.availableBalance || 0) / 100 + '€'}
      />

      <Dialog
        open={bulkUpdateTransactionStatusHook.modalIsOpen}
        onClose={bulkUpdateTransactionStatusHook.toggleModalStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BulkUpdateTransactionStatusModal
          onClose={bulkUpdateTransactionStatusHook.toggleModalStatus}
          onConfirm={bulkUpdateTransactionStatusHook.submit}
        />
      </Dialog>
    </>
  );
};
