import { RefundReason } from '@bits-app/voggtpit-shared';

import { OrderItem } from '@/entities/order-item.entity';
import { AppThunkAction } from '@/redux/store';

import {
  selectIsOrderItemInTheRefund,
  selectOrderItemFromId,
  selectOrderItemIsCompletelyRefunded,
  selectRefundReason,
} from '../redux/refund.selectors';
import { refundActions } from '../redux/refund.slice';

import { getDefaultAmountForDeliveredByHandForOrderItem } from './on-refund-reason/get-default-refund-for-delivered-by-hand';
import { getDefaultAmountForFreeShippingForOrderItem } from './on-refund-reason/get-default-refund-for-free-shipping';
import { getDefaultAmountForLostParcelForOrderItem } from './on-refund-reason/get-default-refund-for-lost-parcel';
import { getDefaultRefundStateForReason } from './on-refund-reason/get-default-refund-state-for-reason';

export const addOrderItemInRefund =
  (orderItemId: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const isOrderItemInTheRefund = selectIsOrderItemInTheRefund(getState(), orderItemId);

    if (isOrderItemInTheRefund) {
      return;
    }

    const isOrderItemCompletelyRefund = selectOrderItemIsCompletelyRefunded(
      getState(),
      orderItemId,
    );

    if (isOrderItemCompletelyRefund) {
      return;
    }

    const orderItem = selectOrderItemFromId(getState(), orderItemId);

    if (!orderItem) {
      throw new Error(`Order item not found ${orderItemId}`);
    }

    dispatch(
      refundActions.setRefundCommissionToSeller({
        orderItemId: orderItem.id,
        refundCommissionToSeller: true,
      }),
    );
    dispatch(getDefaultRefundAmountForOrderItem(orderItem));
  };

const getDefaultRefundAmountForOrderItem =
  (orderItem: OrderItem): AppThunkAction<void> =>
  (dispatch, getState) => {
    const reason = selectRefundReason(getState());

    switch (reason) {
      case RefundReason.misclick:
      case RefundReason.changeOfMind:
      case RefundReason.counterfeit:
      case RefundReason.missingProduct:
      case RefundReason.productLostBeforeShipping:
      case RefundReason.sellerMistake:
      case RefundReason.others:
        dispatch(refundActions.setRefundAmountFor({ orderItemId: orderItem.id }));
        dispatch(getDefaultRefundStateForReason(reason));
        break;

      case RefundReason.lostParcel:
        dispatch(getDefaultAmountForLostParcelForOrderItem(orderItem));
        break;

      case RefundReason.deliveredByHand:
        dispatch(getDefaultAmountForDeliveredByHandForOrderItem(orderItem));
        break;

      case RefundReason.freeShipping:
        dispatch(getDefaultAmountForFreeShippingForOrderItem(orderItem));
        break;

      default:
        dispatch(refundActions.setRefundAmountFor({ orderItemId: orderItem.id }));
    }
  };
