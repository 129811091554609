import type { ValueTransformer } from 'typeorm';

import {
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
  ViewColumn,
  ViewEntity,
} from 'typeorm';

import { Currency } from '../models/currency';

import { OrderStatus, PaymentStatus, Order } from './Order';
import { ShippingAddress } from './ShippingAddress';
import { Show } from './Show';
import { User } from './User';

export enum ShowOrdersGroupPaymentStatusSummary {
  some_failed = 'some_failed',
  some_in_progress = 'some_in_progress',
  some_pending_sepa_debit = 'some_pending_sepa_debit',
  all_paid = 'all_paid',
}

export enum ShowOrdersGroupStatusSummary {
  some_canceled = 'some_canceled',
  some_refunded = 'some_refunded',
  some_pending = 'some_pending',
  all_shipped = 'all_shipped',
  all_delivered = 'all_delivered',
}

export enum ShowOrdersGroupShippingStatusSummary {
  some_pending = 'some_pending',
  all_shipped = 'all_shipped',
  all_delivered = 'all_delivered',
}

export enum ShowOrderGroupSourceType {
  shipment = 'shipment',
  orders = 'orders',
}

const integer: ValueTransformer = {
  to: (entityValue: number) => entityValue,
  from: (databaseValue: string) => parseInt(databaseValue, 10),
};

@ViewEntity({
  expression: (connection) =>
    connection
      .createQueryBuilder()
      .select(`concat("order"."customerId", '-',"order"."showId")`, 'id')
      .addSelect('order.customerId', 'customerId')
      .addSelect('order.showId', 'showId')
      .addSelect('max(order.currency)', 'currency')
      .addSelect('min(show.startAt)', 'showStartAt')
      .addSelect('array_agg(order.id order by order.id asc)', 'orderIds')
      .addSelect('array_agg(order.status)', 'statuses')
      .addSelect('array_agg(order.paymentStatus)', 'paymentStatuses')
      .addSelect('sum(order.amount + order.shippingAmount)', 'totalAmount')
      .addSelect('sum(order.shippingAmount)', 'totalShippingAmount')
      .addSelect(
        'sum(order.amount + order.shippingAmount)',
        'totalAmountAndShippingAmount',
      )
      .addSelect('sum(order.promotionAmount)', 'totalPromotionAmount')
      .addSelect(
        'min(order.externalShippingOrderId) FILTER (WHERE order.externalShippingOrderId IS NOT NULL)',
        'externalShippingOrderId',
      )
      .addSelect(
        `coalesce(sum(order.amount) FILTER (WHERE order.paymentStatus = '${PaymentStatus.success}'), 0)`,
        'totalPaidProductAmount',
      )
      .addSelect(
        `coalesce(sum(order.shippingAmount) FILTER (WHERE order.paymentStatus = '${PaymentStatus.success}'), 0)`,
        'totalPaidShippingAmount',
      )
      // we need this column to be in the view so that we can sort + filter + paginate on it
      .addSelect(
        `CASE
          WHEN (count(order.id) FILTER (WHERE order.paymentStatus = '${PaymentStatus.failed}')) > 0 THEN '${ShowOrdersGroupPaymentStatusSummary.some_failed}'
          WHEN (count(order.id) FILTER (WHERE order.paymentStatus = '${PaymentStatus.in_progress}' AND "order".status != '${OrderStatus.canceled}')) > 0 THEN '${ShowOrdersGroupPaymentStatusSummary.some_in_progress}'
          WHEN (count(order.id) FILTER (WHERE order.paymentStatus = '${PaymentStatus.pending_sepa_debit}')) > 0 THEN '${ShowOrdersGroupPaymentStatusSummary.some_pending_sepa_debit}'
          ELSE '${ShowOrdersGroupPaymentStatusSummary.all_paid}'
        END`,
        'paymentStatusSummary',
      )
      // We aggregate values for `shippingAddressId`, `trackingNumber` and `shippingProvider` into a single one,
      // because on these columns, in practice, we expect a single value (or null) for all orders of a ShowOrdersGroup.
      // Indeed, you can have 3 orders in one ShowOrdersGroup, 2 are shipped with a trackingNumber and
      // shippingProvider but one is cancel so he have not a shippingProvider and trackingNumber
      // This is used in replacement of a proper 'shipment' table that would properly modelize this practice.
      .addSelect(
        'min(order.shippingAddressId) FILTER (WHERE order.shippingAddressId IS NOT NULL)',
        'shippingAddressId',
      )
      .addSelect(
        'min(order.trackingNumber) FILTER (WHERE order.trackingNumber IS NOT NULL)',
        'trackingNumber',
      )
      .addSelect(
        'min(order.shippingProvider) FILTER (WHERE order.shippingProvider IS NOT NULL)',
        'shippingProvider',
      )
      .from(Order, 'order')
      .leftJoin(Show, 'show', 'order.showId = show.id')
      .where('order.showId IS NOT NULL')
      .groupBy('order.customerId')
      .addGroupBy('order.showId'),
})
export class ShowOrdersGroup {
  // A View Entity without @PrimaryColumn causes getCount to generate invalid SQL
  // https://github.com/typeorm/typeorm/issues/4479
  @PrimaryColumn()
  id!: string;

  @ManyToOne(() => User, { eager: true })
  @JoinColumn({ name: 'customerId' })
  customer!: User;

  @ViewColumn()
  customerId!: number;

  @ViewColumn()
  shippingAddressId!: number;

  @ManyToOne(() => ShippingAddress, { eager: true })
  @JoinColumn({ name: 'shippingAddressId' })
  shippingAddress!: ShippingAddress;

  @ViewColumn()
  trackingNumber!: string;

  @ViewColumn()
  shippingProvider!: string;

  @ManyToOne(() => Show, { eager: true })
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @ViewColumn()
  showId!: number;

  @ViewColumn()
  showStartAt!: Date;

  @ViewColumn()
  currency!: Currency;

  @ViewColumn()
  orderIds!: number[];

  @ViewColumn({
    // @ts-expect-error providing type is supported in opposite to what typing is saying
    type: 'enum',
    enum: OrderStatus,
    array: true,
  })
  statuses!: OrderStatus[];

  @ViewColumn({
    // @ts-expect-error providing type is supported in opposite to what typing is saying
    type: 'enum',
    enum: PaymentStatus,
    array: true,
  })
  paymentStatuses!: PaymentStatus[];

  // @todo remove this column from the view via a migration when PR#478 is totally released
  // totalAmount!: number

  @ViewColumn()
  totalShippingAmount!: number;

  @ViewColumn()
  totalAmountAndShippingAmount!: number;

  @ViewColumn()
  totalPromotionAmount!: number;

  @ViewColumn()
  externalShippingOrderId!: string;

  /**
   * The sum af all orders amounts of this group on paid orders.
   */
  @ViewColumn({
    // for view column, typeorm does not seem to hydrate values correctly: we need to do it manually
    // https://github.com/typeorm/typeorm/issues/4339
    transformer: integer,
  })
  totalPaidProductAmount!: number;

  /**
   * The sum of all orders' shipping amount of this group on paid orders.
   */
  @ViewColumn({
    // for view column, typeorm does not seem to hydrate values correctly: we need to do it manually
    // https://github.com/typeorm/typeorm/issues/4339
    transformer: integer,
  })
  totalPaidShippingAmount!: number;

  get statusSummary(): ShowOrdersGroupStatusSummary {
    if (this.statuses.some((status) => status === OrderStatus.canceled)) {
      return ShowOrdersGroupStatusSummary.some_canceled;
    }
    if (this.statuses.some((status) => status === OrderStatus.refunded)) {
      return ShowOrdersGroupStatusSummary.some_refunded;
    }
    if (this.statuses.some((status) => status === OrderStatus.pending)) {
      return ShowOrdersGroupStatusSummary.some_pending;
    }

    if (this.statuses.every((status) => status === OrderStatus.delivered)) {
      return ShowOrdersGroupStatusSummary.all_delivered;
    }
    if (
      this.statuses.every(
        (status) =>
          status === OrderStatus.shipped ||
          // delivered are also shipped
          // partial delivery will marked as `all_shipped`
          status === OrderStatus.delivered,
      )
    ) {
      return ShowOrdersGroupStatusSummary.all_shipped;
    }

    throw new Error(
      `Unknown summary status for ${this.statuses
        .map((status) => status)
        .join('+')}`,
    );
  }

  // The column is actually a string but the value are the ones of the enum `ShowOrdersGroupPaymentStatusSummary`
  // I was not able to properly create an enum for a view with typeorm migration
  @ViewColumn()
  paymentStatusSummary!: ShowOrdersGroupPaymentStatusSummary;

  get shippingStatusSummary(): ShowOrdersGroupShippingStatusSummary | null {
    const shippingStatuses = this.statuses.filter(
      (s) => s !== OrderStatus.canceled && s !== OrderStatus.refunded,
    );

    // if all orders are canceled/refunded, we can't have a summmary
    if (shippingStatuses.length === 0) {
      return null;
    }

    if (shippingStatuses.some((status) => status === OrderStatus.pending)) {
      return ShowOrdersGroupShippingStatusSummary.some_pending;
    }

    if (shippingStatuses.every((status) => status === OrderStatus.delivered)) {
      return ShowOrdersGroupShippingStatusSummary.all_delivered;
    }

    if (
      shippingStatuses.every(
        (status) =>
          status === OrderStatus.shipped ||
          // delivered are also shipped
          // partial delivery will marked as `all_shipped`
          status === OrderStatus.delivered,
      )
    ) {
      return ShowOrdersGroupShippingStatusSummary.all_shipped;
    }

    throw new Error(
      `Unknown summary status for ${shippingStatuses
        .map((status) => status)
        .join('+')}`,
    );
  }

  // this is just for shipments refacto
  sellerId!: number;

  shopId!: number;

  alternativeSource?: ShowOrderGroupSourceType;
}
