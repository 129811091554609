import { UserDataConsent } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../queries';

export const useDataConsentByUserId = (userId: number) => {
  return useQuery(QUERIES_KEYS.userDataConsent(userId), () =>
    authenticatedInstance.get<UserDataConsent>(
      `/database-explorer/customer/data-consent/${userId}`,
    ),
  );
};
