import type {
  CommercialEventApplication,
  User,
} from '@bits-app/bits-server-data';

export type CreateCommercialEventPayload = {
  name: string;
  startAt: Date;
  endAt: Date;
  countryId: string;
  bannerUrl: string;
  coverUrl: string;
  products: never[];
  shows: never[];
};

export type UpdateCommercialEventPayload = {
  name?: string;
  startAt?: Date;
  endAt?: Date;
  countryId?: string;
  bannerUrl?: string;
  coverUrl?: string;
  products?: number[];
  shows?: number[];
};

export type SellerInfo = {
  id: User['id'];
  username: User['username'];
  phoneNumber: User['phoneNumber'];
  email: User['email'];
  rating: number;
  gmvPerShowLast60Days: number;
  maxShowGmv: number;
  firstShowStartedAt: Date | null;
};

export type CommercialEventApplicationWithData = {
  id: CommercialEventApplication['id'];
  commercialEventApplication: CommercialEventApplication;
  sellerInfo: SellerInfo;
};

enum CommercialEventCountryList {
  FR = 'FR',
  BE = 'BE',
  DE = 'DE',
  AT = 'AT',
  NL = 'NL',
  LU = 'LU',
  ES = 'ES',
  RE = 'RE',
  PT = 'PT',
  GB = 'GB',
  IE = 'IE',
  NULL = 'NULL',
}

export const ORDERED_COUNTRY_IDS: CommercialEventCountryList[] = [
  CommercialEventCountryList.FR,
  CommercialEventCountryList.BE,
  CommercialEventCountryList.DE,
  CommercialEventCountryList.AT,
  CommercialEventCountryList.NL,
  CommercialEventCountryList.LU,
  CommercialEventCountryList.ES,
  CommercialEventCountryList.RE,
  CommercialEventCountryList.PT,
  CommercialEventCountryList.GB,
  CommercialEventCountryList.IE,
  CommercialEventCountryList.NULL,
];
