import { Order } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { ShoppingBasket } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@/components/elements/Loader';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { OrdersWithExport } from '@/voggt-database-explorer/components/OrdersWithExport';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';
import { ShowOrderRowActions } from '@/voggt-database-explorer/show/views/detail/ShowOrders/ShowOrderRowActions';

import { useShipmentDetailFromOrderId } from '../../../shipment/useShipmentDetailFromOrderId.hook';
import { useOrderDetail } from '../../hooks/useOrderDetail';
import { CellOrderDefinition, OrderWithProduct } from '../../order.const';

import { Details } from './Details';
import { OrderEventsList } from './OrderEventsList';

export const OrderDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_PRODUCT);

  const orderId = useIdParam();

  const location = useLocation();

  const { order, refreshData, loading } = useOrderDetail(orderId);

  const { data: shipment } = useShipmentDetailFromOrderId(Number(orderId));

  const handleOnTabChange = useNavigateInDetailTab(`/order/${orderId}`);

  const tabs: ItemTab[] = order
    ? [
        {
          path: '/',
          label: t('databaseExplorer.order.tabs.details'),
          Element: (
            <Details
              key="/"
              loading={loading}
              order={order}
              isReadOnly={isReadOnly}
              shipment={shipment}
            />
          ),
        },
        {
          path: 'other-item-in-order',
          label: t('databaseExplorer.order.tabs.buyerOrders'),
          Element: (
            <OrdersWithExport<OrderWithProduct>
              key="other-item-in-order"
              cellDefinition={CellOrderDefinition}
              filters={{ showId: order?.showId, customerId: order?.customerId }}
              renderRowActions={({ row }) => {
                if (row.original === null) {
                  return null;
                }
                return <ShowOrderRowActions order={row.original} />;
              }}
            />
          ),
        },
        {
          path: 'order-events',
          label: t('databaseExplorer.order.tabs.orderEvents'),
          Element: <OrderEventsList key="order-events" />,
        },
      ]
    : [];

  const currentTab =
    ['other-item-in-order', 'order-events'].find((path) => location.pathname.includes(path)) ?? '/';

  if (!order && !loading) {
    navigate('/order');
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ItemLayout<Order>
      placeholderIcon={<ShoppingBasket className="text-white" size={90} strokeWidth={2.5} />}
      id={orderId}
      title={`Order #${orderId}`}
      description={order?.product?.name ?? '-'}
      imageURL={order?.product?.images?.[0]?.url ?? ''}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        isReadOnly
          ? undefined
          : {
              goBackName: t('databaseExplorer.editionFormPath.orders', { orderId }),
              resourceId: Number(orderId),
              resourceType: 'Order',
              onSuccess: refreshData,
            }
      }
    />
  );
};
