import { PERMISSIONS, formatAmount, centsToUnit } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../../../../axios/axios.instance';
import { CellDefinition } from '../../../../../../components/elements/DataList';
import { useOwnUser } from '../../../../../../context/own-user.context';
import { QUERIES_KEYS } from '../../../../../../queries';
import { User } from '../../../../users';
import {
  UserDetailQueryResponse,
  UserTransactionsListQueryResponse,
} from '../misc/user-payout-list.type';

export const useUserTransactionsListQuery = (
  userId: User['id'],
  currentPage: number,
  pageSize: number,
) => {
  const { hasPermissions } = useOwnUser();
  return useQuery(
    QUERIES_KEYS.ledgerTransactionList(userId, currentPage, pageSize),
    async () => {
      const result = await authenticatedInstance.get<UserTransactionsListQueryResponse>(
        `/ledger/transactions/${userId}?page=${currentPage}&pageSize=${pageSize}`,
      );
      return result?.data;
    },
    {
      enabled: hasPermissions(PERMISSIONS.LEDGER.READ_LEDGER_VIEW),
    },
  );
};

export const useUserBalanceQuery = (userId: User['id'], userIsSeller: boolean) => {
  const { hasPermissions } = useOwnUser();
  const enabled = Boolean(userIsSeller && hasPermissions(PERMISSIONS.LEDGER.READ_LEDGER_VIEW));
  return useQuery(
    QUERIES_KEYS.ledgerBalance(userId),
    async () => {
      const result = await authenticatedInstance.get(`/ledger/balance/${userId}`);
      return result?.data;
    },
    {
      enabled,
    },
  );
};

export const useUserDetailQuery = (userId: User['id']) => {
  const { hasPermissions } = useOwnUser();
  return useQuery(
    QUERIES_KEYS.ledgerDetail(userId),
    async () => {
      const result = await authenticatedInstance.get<UserDetailQueryResponse>(
        `/ledger/details/${userId}`,
      );
      return result?.data;
    },
    {
      enabled: hasPermissions(PERMISSIONS.LEDGER.READ_LEDGER_VIEW),
    },
  );
};

export const CellDefinitionTransactionList: CellDefinition<
  UserTransactionsListQueryResponse['transactions'][0]
>[] = [
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },

  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'amount',
    label: 'Amount',
    type: 'money',
    getValue: (transaction) =>
      transaction?.amount ? `${formatAmount(centsToUnit(Number(transaction?.amount)))}€` : '',
  },
  {
    id: 'orderId',
    label: 'Order ID',
    type: 'internal-link',
    getLink: (row) => ({ link: `/order/${row.orderId}`, label: row.orderId || '' }),
  },
  {
    id: 'showId',
    label: 'Show ID',
    getLink: (row) => ({ link: `/show/${row.showId}`, label: row.showId || '' }),
    type: 'internal-link',
  },
  {
    id: 'shipmentId',
    label: 'Shipment ID',
    getLink: (row) => ({ link: `/shipment/${row.shipmentId}`, label: row.shipmentId || '' }),
    type: 'internal-link',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'stripePayoutId',
    label: 'Stripe payout ID',
  },
  {
    id: 'applicationName',
    label: 'Application Name',
  },
  {
    id: 'id',
    label: 'id',
  },
  {
    id: 'initiatorId',
    label: 'Initiator Id',
  },

  {
    id: 'version',
    label: 'Version',
  },
];
