import { useMemo } from 'react';

import { ShippingCategory, Show, User } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';
import { useGetCategories } from '@/queries';

const SHIPPING_CATEGORIES = Object.values(ShippingCategory) as ShippingCategory[];

export const useCellDefinition = () => {
  const params: useSearchVoggtPayloadType<User> = useMemo(
    () => ({
      typesenseIndex: 'user',
      typesenseParams: {
        per_page: 20,
        query_by: ['username'],
        include_fields: 'username',
      },
    }),
    [],
  );

  const {
    results: userOptions,
    setSearch: setUserSearch,
    loading: userLoading,
  } = useTypesenseSearch<User>(params);

  const { data: categories } = useGetCategories();

  const CellShowDefinition = useMemo(
    (): CellDefinition<Show & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (show) => ({ link: `/show/${show?.id}`, label: show?.id }),
        enableColumnFilter: false,
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (show) => ({ link: `/user/${show?.userId}`, label: show?.user }),
        columnFilter: {
          type: 'autocomplete',
          options: userOptions
            .map((user) => ({ value: user.username }))
            .filter((option) => Boolean(option?.value)),
          onAutocomplete: setUserSearch,
          loading: userLoading,
        },
      },
      {
        id: 'id',
        label: 'Status',
        type: 'external-link',
        getLink: (show) => ({
          link: `https://app.voggt.com/shows/${show.id}`,
          label: show.isBroadcasting ? '🔴 Live' : '',
        }),
      },
      {
        id: 'name',
        label: 'Name',
        enableColumnFilter: false,
      },
      {
        id: 'category',
        label: 'Category',
        columnFilter: {
          type: 'multi-select',
          options: (categories ?? [])
            .map((category) => ({ value: category.name }))
            .filter((option) => Boolean(option?.value))
            .sort((optionA, optionB) => optionA.value.localeCompare(optionB.value)),
        },
      },
      {
        id: 'shippingCategory',
        label: 'Shipping Category',
        columnFilter: {
          type: 'multi-select',
          options: SHIPPING_CATEGORIES.map((value) => ({ value })).sort((optionA, optionB) =>
            optionA.value.localeCompare(optionB.value),
          ),
        },
      },
      {
        id: 'percentageFee',
        label: 'Percentage Fee',
        columnFilter: { type: 'text' },
      },
      {
        id: 'fixedFee',
        label: 'Fixed Fee',
        columnFilter: { type: 'text' },
      },
      {
        id: 'description',
        label: 'Description',
        maxWidth: 700,
        enableColumnFilter: false,
      },
      {
        id: 'startAt',
        label: 'Start at',
        type: 'date-with-time',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: 'endedAt',
        label: 'Ended at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'terminatedAt',
        label: 'Terminated at (shops only)',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    [categories, setUserSearch, userLoading, userOptions],
  );

  return { CellShowDefinition };
};
