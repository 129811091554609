import { Shipment, User } from '@bits-app/bits-server-data';
import { formatAmountWithCurrency, centsToUnit } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

import { Information } from '../../components/layouts/PanelDetails';
import { getLinkShippingTracking } from '../../utils';

import { getExternalProviderShipmentLink, ShipmentsWithTotalAmounts } from './utils/utils';

export type ShipmentTypesense = Shipment & {
  customer: string;
  seller: string;
};

export const ShipmentTypesenseCellDefinition: CellDefinition<ShipmentTypesense>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'internal-link',
    getLink: (shipment: ShipmentTypesense) => ({
      link: `/shipment/${shipment.id}`,
      label: shipment?.id,
    }),
  },
  {
    id: 'seller',
    label: 'Seller',
    getLink: (shipment: ShipmentTypesense) => ({
      link: `/user/${shipment.sellerId}`,
      label: shipment.seller,
    }),
  },
  {
    id: 'customer',
    label: 'Customer',
    getLink: (shipment: ShipmentTypesense) => ({
      link: `/user/${shipment.customerId}`,
      label: shipment.customer,
    }),
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  { id: 'trackingNumber', label: 'Tracking Number' },
  {
    id: 'shippingAddressId',
    label: 'Shipping Address ID',
    type: 'internal-link',
    getLink: (shipment: ShipmentTypesense) => ({
      link: `/shipping-address/${shipment.shippingAddressId}`,
      label: shipment.shippingAddressId,
    }),
  },
  {
    id: 'externalShippingOrderId',
    label: 'External Shipping Order Id',
  },
  {
    id: 'labelPrintUrl',
    label: 'Label Print Url',
    type: 'external-link',
    getLink: (shipment: ShipmentTypesense) => ({
      link: shipment.labelPrintUrl,
      label: 'Open URL',
    }),
  },
  {
    id: 'easyShippingAmount',
    label: 'Easy Shipping Amount',
    type: 'money',
    getValue: (shipment: ShipmentTypesense) =>
      shipment?.easyShippingAmount
        ? formatAmountWithCurrency(
            centsToUnit(Number(shipment?.easyShippingAmount)),
            shipment.currency,
          )
        : '',
    enableSorting: true,
  },
  {
    id: 'currency',
    label: 'Currency',
    type: 'badge',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
    enableSorting: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
    enableSorting: true,
  },
];

export const ShipmentCellDefinition: CellDefinition<ShipmentsWithTotalAmounts>[] = [
  {
    id: 'id',
    label: 'Shipment ID',
    accessorFn: (shipment: Shipment) => shipment?.id,
    type: 'internal-link',
    getLink: (shipment: Shipment) => ({
      link: `/shipment/${shipment.id}`,
      label: shipment?.id,
    }),
  },
  {
    id: 'seller',
    label: 'Seller',
    accessorFn: (shipment: Shipment) => shipment?.seller?.username,
    type: 'internal-link',
    getLink: (shipment: Shipment) => ({
      link: `/user/${shipment.sellerId}`,
      label: shipment?.seller?.username,
    }),
  },
  {
    id: 'customer',
    label: 'Customer',
    accessorFn: (shipment: Shipment) => shipment?.customer?.username,
    type: 'internal-link',
    getLink: (shipment: Shipment) => ({
      link: `/user/${shipment.customerId}`,
      label: shipment?.customer?.username,
    }),
  },
  {
    id: 'status',
    label: 'status',
    type: 'badge',
  },
  {
    id: 'trackingNumber',
    label: 'Tracking Number',
    type: 'external-link',
    getLink: (shipment: ShipmentsWithTotalAmounts) => {
      const trackingLink = getLinkShippingTracking({
        shippingAddress: shipment.shippingAddress,
        shippingProvider: shipment.shippingProvider,
        trackingNumber: shipment.trackingNumber,
      });

      console.log(shipment);
      return {
        link: trackingLink ?? null,
        label: shipment.trackingNumber ?? '',
      };
    },
  },
  {
    id: 'shippingAddressId',
    label: 'Shipping Address ID',
    type: 'internal-link',
    getLink: (shipment: Shipment) => ({
      link: `/shipping-address/${shipment.shippingAddressId}`,
      label: shipment.shippingAddressId,
    }),
  },
  {
    id: 'externalShippingOrderId',
    label: 'External Shipping Order Id',
  },
  {
    id: 'labelPrintUrl',
    label: 'Label Print Url',
  },
  {
    id: 'totalProductsAmount',
    label: 'Total Products Amount',
    type: 'money',
    getValue: (shipment: Shipment & { totalProductsAmount: number }) =>
      formatAmountWithCurrency(
        centsToUnit(Number(shipment.totalProductsAmount)),
        shipment.currency,
      ),
    enableSorting: true,
  },
  {
    id: 'totalShippingFeesAmount',
    label: 'Total Shipping Fees Amount',
    type: 'money',
    getValue: (shipment: Shipment & { totalShippingFeesAmount: number }) =>
      shipment.totalShippingFeesAmount
        ? formatAmountWithCurrency(
            centsToUnit(Number(shipment.totalShippingFeesAmount)),
            shipment.currency,
          )
        : '',
    enableSorting: true,
  },
  {
    id: 'easyShippingAmount',
    label: 'Easy Shipping Amount',
    type: 'money',
    getValue: (shipment: Shipment) =>
      shipment?.easyShippingAmount
        ? formatAmountWithCurrency(
            centsToUnit(Number(shipment?.easyShippingAmount)),
            shipment.currency,
          )
        : '',
    enableSorting: true,
  },
  {
    id: 'currency',
    label: 'Currency',
    type: 'badge',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
    enableSorting: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
    enableSorting: true,
  },
  {
    id: 'labelGeneratedAt',
    label: 'Label generated at',
    type: 'date-with-time',
    enableSorting: true,
  },
];

export const panelDetailsShipments: Information<Shipment>[] = [
  { value: 'id', label: 'id' },
  { value: 'status', label: 'Status' },
  {
    label: 'Tracking number',
    value: 'trackingNumber',
    type: 'external-link',
    link: (shipment) => getLinkShippingTracking(shipment) ?? '',
  },
  {
    value: 'externalShippingOrderId',
    label: 'External shipping order id',
    type: 'external-link',
    link: (shipment) => getExternalProviderShipmentLink(shipment.externalShippingOrderId),
  },
  { value: 'shippingProvider', label: 'Shipping Provider' },
  { value: 'labelPrintUrl', label: 'Label print url' },
  { value: 'easyShippingAmount', label: 'Easy shipping amount' },
  { value: 'labelStripePaymentId', label: 'Stripe payment id' },
  { value: 'currency', label: 'Currency' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];

export const panelDetailsUser: Information<User>[] = [
  { value: 'id', label: 'id', type: 'internal-link', link: (user) => `/user/${user.id}` },
  { value: 'username', label: 'Username' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'lastName', label: 'Lastname' },
];
