import { AvailableEntityForComment, ActivityLog } from '@bits-app/voggtpit-shared';
import { formatDistance } from 'date-fns';

import { Panel } from '../layouts/Panel';

import { CommentsFromActivityLogs } from './Comments/CommentsFromActivityLogs';
import { TooltipWithTime } from './DataWrapper/date/TooltipWithTime';
import { Loader } from './Loader';

type Log = ActivityLog & { entity: AvailableEntityForComment };

type ActivityLogsProps = {
  logs?: Log[];
  isLoading: boolean;
  entityId: string;
  entityName: AvailableEntityForComment;
};

type ActivityProps = {
  log: Log;
  entityName: AvailableEntityForComment;
};

export const ActivityLogs = ({ logs, isLoading, entityId, entityName }: ActivityLogsProps) => {
  return (
    <Panel title="Activity logs" skipPadding>
      {isLoading ? <Loader /> : <ActivityList logs={logs} entityName={entityName} />}

      <CommentsFromActivityLogs entityId={entityId} entityName={entityName} />
    </Panel>
  );
};

const ActivityList = ({
  logs = [],
  entityName,
}: {
  logs?: Log[];
  entityName: AvailableEntityForComment;
}) => {
  return (
    <div className="px-4 max-h-[300px] overflow-y-auto">
      {logs.map((log) => (
        <div className="flex my-4 gap-4" key={log.id}>
          <DisplayActivity log={log} entityName={entityName} />
        </div>
      ))}
    </div>
  );
};

const DisplayActivity = (props: ActivityProps) => {
  switch (props.log.type) {
    case 'update':
      return <ActivityUpdate {...props} />;
    case 'comment':
      return <ActivityComment {...props} />;
    case 'feature':
      return <ActivityFeature {...props} />;
    default:
      return null;
  }
};

const ActivityUpdate = ({ log, entityName }: ActivityProps) => {
  const fields = log.content.split('\r');
  const entityOfLogIsDifferentThanCurrent = log.entity !== entityName;

  const title =
    ' updated value' +
    (entityOfLogIsDifferentThanCurrent ? ` of ${log.entity.replace('_', ' ')}` : '') +
    ' :';

  return (
    <>
      <AvatarWrapper username={log.createdBy} />
      <div className="text-sm flex flex-col flex-1">
        <div className="flex flex-1 justify-between">
          <p className="text-sm">
            <span className="font-bold">{log.createdBy}</span>
            {title}
          </p>

          <CreatedAt createdAt={log.createdAt} />
        </div>

        <div className="bg-slate-200 rounded-lg ">
          {fields.map((t, i) => (
            <p className="p-2" key={i}>
              {t}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

const ActivityComment = ({ log }: ActivityProps) => {
  return (
    <>
      <AvatarWrapper username={log.createdBy} />
      <div className="text-sm flex flex-col flex-1">
        <div className="flex flex-1 justify-between">
          <p className="text-sm">
            <span className="font-bold">{log.createdBy}</span>
          </p>

          <CreatedAt createdAt={log.createdAt} />
        </div>

        <div className="bg-slate-200 rounded-lg  p-2">
          <p>{log.content}</p>
        </div>
      </div>
    </>
  );
};

const ActivityFeature = ({ log }: ActivityProps) => {
  const featureTranslation = log.content.replace(
    /([A-Z])/g,
    (letter: string) => ` ${letter.toLowerCase()}`,
  );
  return (
    <>
      <AvatarWrapper username={log.createdBy} />
      <div className="text-sm flex flex-col flex-1">
        <div className="flex flex-1 justify-between">
          <p className="text-sm">
            <span className="font-bold">{log.createdBy}</span>
            used {featureTranslation}
          </p>
          <CreatedAt createdAt={log.createdAt} />
        </div>

        {log.payload ? (
          <div className="bg-slate-200 rounded-lg  p-2">
            <p>{log.payload}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};

const AvatarWrapper = ({ username }: { username: string }) => {
  const userInitials = username.split(' ').map((name) => name[0]);
  return (
    <div className="rounded-full bg-gray-300 w-6 h-6 flex items-center justify-center text-xs font-bold">
      {userInitials}
    </div>
  );
};

const CreatedAt = ({ createdAt }: { createdAt: Date }) => (
  <TooltipWithTime date={createdAt}>
    <p className="text-sm break-keep whitespace-nowrap text-slate-500">
      {formatDistance(createdAt, new Date(), { addSuffix: true })}
    </p>
  </TooltipWithTime>
);
