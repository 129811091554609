import { RefundDebitedAccount, RefundReason, add, minus } from '@bits-app/voggtpit-shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';

export type OrderItems = {
  amount: string | null;
  refundFullOrder: boolean;
  refundCommissionToSeller: boolean;
};

export type RefundState = {
  mainOrderItemId: number | null;
  orderItems: OrderItemRefund[];
  refundForm: {
    reason: RefundReason | null;
    debitedAccount: RefundDebitedAccount | null;
    orderItems: Record<string, OrderItems>;
    loading: boolean;
  };
  loading: boolean;
  error: string | null;
};

export const refundSlice = createSlice({
  name: 'refund',
  initialState: {
    mainOrderItemId: null,
    orderItems: [],
    refundForm: {
      reason: null,
      debitedAccount: null,
      orderItems: {},
      loading: false,
    },
    loading: false,
    error: null,
  } as RefundState,
  reducers: {
    setMainOrderItemId: (state, { payload }: PayloadAction<RefundState['mainOrderItemId']>) => {
      state.mainOrderItemId = payload;
    },
    setEntities: (state, { payload }: PayloadAction<RefundState['orderItems']>) => {
      state.orderItems = payload;
    },
    setRefundReason: (state, { payload }: PayloadAction<RefundState['refundForm']['reason']>) => {
      state.refundForm.reason = payload;
    },
    setDebitedAccount: (
      state,
      { payload }: PayloadAction<RefundState['refundForm']['debitedAccount']>,
    ) => {
      state.refundForm.debitedAccount = payload;

      const orderItems = Object.keys(state.refundForm.orderItems);
      orderItems.forEach((orderItemId) => {
        state.refundForm.orderItems[orderItemId] = {
          ...state.refundForm.orderItems[orderItemId],
          refundCommissionToSeller: payload === RefundDebitedAccount.seller,
        };
      });
    },
    setRefundFormLoading: (
      state,
      { payload }: PayloadAction<RefundState['refundForm']['loading']>,
    ) => {
      state.refundForm.loading = payload;
    },
    setRefundAmountFor: (
      state,
      { payload }: PayloadAction<{ orderItemId: number; amount?: string }>,
    ) => {
      const targetedAmount = state.orderItems.find(
        (orderItem) => orderItem.id === payload.orderItemId,
      );
      const totalAmount = add(targetedAmount?.amount || 0, targetedAmount?.shippingAmount || 0);
      const amountEnterIsFullOrder =
        payload.amount === minus(totalAmount, targetedAmount?.refundedAmount ?? 0).toFixed(2);
      state.refundForm.orderItems[payload.orderItemId] = {
        ...state.refundForm.orderItems[payload.orderItemId],
        refundFullOrder: amountEnterIsFullOrder,
        amount: payload.amount || null,
      };
    },
    setRefundFullOrder: (
      state,
      {
        payload,
      }: PayloadAction<{ orderItemId: number; refundFullOrder: boolean; amount?: string }>,
    ) => {
      state.refundForm.orderItems[payload.orderItemId] = {
        ...state.refundForm.orderItems[payload.orderItemId],
        amount: payload.amount || null,
        refundFullOrder: payload.refundFullOrder,
      };
    },
    setRefundCommissionToSeller: (
      state,
      { payload }: PayloadAction<{ orderItemId: number; refundCommissionToSeller: boolean }>,
    ) => {
      state.refundForm.orderItems[payload.orderItemId] = {
        ...state.refundForm.orderItems[payload.orderItemId],
        refundCommissionToSeller: payload.refundCommissionToSeller,
      };
    },
    removeFromRefund: (state, { payload }: PayloadAction<number>) => {
      delete state.refundForm.orderItems[payload];
    },
    removeAllFromRefund: (state) => {
      state.refundForm.orderItems = {};
    },
    resetRefundForm: (state) => {
      state.refundForm = {
        reason: null,
        debitedAccount: null,
        orderItems: {},
        loading: false,
      };
    },
    setRefundLoading: (state, { payload }: PayloadAction<RefundState['loading']>) => {
      state.loading = payload;
    },
    setRefundError: (state, { payload }: PayloadAction<RefundState['error']>) => {
      state.error = payload;
    },
  },
});

export const refundActions = refundSlice.actions;

export default refundSlice.reducer;
