import type { OwnUser } from '../../own-user';
import type { EntityName, DatabaseSource } from '../common';

export type Feature =
  | 'activeIsSelectedFlag'
  | 'addRecordToCommercialEvent'
  | 'addUserToSentinelFraudCase'
  | 'attachByPartnerProgramNameAndThreshold'
  | 'attachUserToPromotionByPartnerProgramName'
  | 'attachUserToPromotionByReferralCode'
  | 'cancelOrder'
  | 'createGoldenVoucherInShow'
  | 'createPartnerProgram'
  | 'deletePartnerProgram'
  | 'deleteUser'
  | 'duplicateCommercialEvent'
  | 'expirePromotion'
  | 'hardBlockUser'
  | 'impersonateUser'
  | 'ledgerBulkUpdateTransactionStatus'
  | 'ledgerPayout'
  | 'refundAShow'
  | 'refundOrderItems'
  | 'refundSellerCancelRequests'
  | 'refundShipment'
  | 'removeBadge'
  | 'removeRecordFromCommercialEvent'
  | 'restoreSso'
  | 'transferFund'
  | 'unlinkSso'
  | 'unlockShowAuctionAndGiveaway'
  | 'updateBadge'
  | 'updateCategory'
  | 'updateDraftBadge'
  | 'updateEmote'
  | 'updateFee'
  | 'updateImageOfCommercialEvent'
  | 'updateLedgerFromCsv'
  | 'updateOrderPayoutStatusFromShop'
  | 'updateOrderShippingStatus'
  | 'updateOrderTrackingNumber'
  | 'updateOwnUserPermissions'
  | 'updateSellerTier'
  | 'updateCommercialEventApplication'
  | 'updateFeaturedShowApplication'
  | 'updateTeamsForMember'
  | 'uploadUsersToPromotion'
  | 'withdrawalFromStripeConnectedAccount';

export type ExternalInitiator = 'zoho' | 'bits-server' | 'google-app-script';

export type FeatureInitiator = OwnUser | ExternalInitiator;

type FeatureTrackingAttributes = {
  id: string;
  feature: Feature;
  initiator: FeatureInitiator;
  entityName: EntityName;
  entityId: string;
  databaseSource: DatabaseSource;
  createdAt: Date;
  payload: string;
};

export class FeatureTracking {
  readonly id: string;

  readonly initiator: FeatureInitiator;

  readonly feature: Feature;

  readonly entityName: EntityName;

  readonly entityId: string;

  readonly databaseSource: DatabaseSource;

  readonly createdAt: Date;

  readonly payload: string;

  constructor(attributes: FeatureTrackingAttributes) {
    this.id = attributes.id;
    this.initiator = attributes.initiator;
    this.feature = attributes.feature;
    this.entityName = attributes.entityName;
    this.entityId = attributes.entityId;
    this.databaseSource = attributes.databaseSource;
    this.createdAt = attributes.createdAt;
    this.payload = attributes.payload;
  }
}
