import { useReducer, useRef, useState } from 'react';

import { NotificationTriggerAudienceEnum } from '@bits-app/voggtpit-shared';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { IconButton, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Button, Checkbox, ListItemText, SelectChangeEvent, OutlinedInput } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';


import { authenticatedInstance } from '@/axios/axios.instance';
import { formatText } from '@/utils';
import { ShowNotification } from '@/voggt-database-explorer/show/misc/show.const';

import { useSnackbar } from '../../../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../../../queries';

type NotificationForm = {
  audiences: NotificationTriggerAudienceEnum[];
};

const audienceValues = [
  NotificationTriggerAudienceEnum.show_reminders, 
  NotificationTriggerAudienceEnum.seller_followers, 
  NotificationTriggerAudienceEnum.category_buyers, 
  NotificationTriggerAudienceEnum.category_followers,
  NotificationTriggerAudienceEnum.related_categories_followers,
  NotificationTriggerAudienceEnum.related_categories_followers_extended
];

export const ShowNotificationRowAction = ({ row }: { row: ShowNotification }) => {
  const notificationAudiences = row.audiences || [];
  const snackbar = useSnackbar();
  const { handleSubmit, control, watch } = useForm<NotificationForm>();
  const [menuIsOpen, toggleMenu] = useReducer((p) => !p, false);
  const ref = useRef(null);
  const [selectedAudiences, setSelectedAudiences] = useState<NotificationTriggerAudienceEnum[]>(notificationAudiences);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: SelectChangeEvent<typeof selectedAudiences>, field: any) => {
    const {
      target: { value },
    } = event;
    const updatedAudiences = 
      typeof value === 'string' ? value.split(',') as NotificationTriggerAudienceEnum[] : value;
    
    setSelectedAudiences(updatedAudiences);
    field.onChange(updatedAudiences); 
  };

  const { mutate } = useMutation({
    mutationFn: (input: NotificationForm) => {
      return authenticatedInstance.post(`notifications-center/update-audiences`, {
        ...input,
        notificationId: row.id,
      });
    },
    onSuccess: () => {
      snackbar.success("Notification's audiences updated");
      queryClient.invalidateQueries(QUERIES_KEYS.notificationList());
      toggleMenu();
    },
  });

  return (
    <>
      <IconButton onClick={toggleMenu} ref={ref}>
        <TrackChangesIcon />
      </IconButton>

      <Dialog open={menuIsOpen} onClose={toggleMenu}>
        <DialogTitle>Update notification's audiences</DialogTitle>
        <DialogContent className="flex flex-col gap-4">
          <Controller
            name="audiences"
            control={control}
            defaultValue={[]} 
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="audiences-label">Audiences</InputLabel>
                <Select 
                  labelId="audiences-label"
                  {...field} 
                  label="Audiences"           
                  id="audiences-id"
                  multiple
                  value={selectedAudiences}
                  onChange={(event) => handleChange(event, field)}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}>
                    {audienceValues.map((audience) => (
                      <MenuItem key={audience} value={audience}>
                        <Checkbox checked={selectedAudiences.indexOf(audience) > -1} />
                        <ListItemText primary={formatText(audience)} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            onClick={handleSubmit((d) => mutate(d))}
            disabled={watch('audiences', []).length === 0}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};