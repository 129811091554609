import type { OrderItem, RefundReason } from '../order-item';
import type { Cent } from '../types';

export enum RefundOrderItemState {
  'not_started' = 'not_started',
  'paying' = 'paying',
  'paid' = 'paid',
  'payment_failed' = 'payment_failed',
  'completed' = 'completed',
}

type RefundOrderItemAttributes = {
  id: string;
  refundId: string;
  orderItemId: OrderItem['id'];
  amount: Cent;
  reason: RefundReason;
  state?: RefundOrderItemState;
  refundFullOrder?: boolean;
  refundCommissionToSeller?: boolean;
  excludeShippingAmountFromRefund?: boolean;
};

export class RefundOrderItem {
  readonly id: string;

  readonly refundId: string;

  readonly orderItemId: OrderItem['id'];

  readonly reason: RefundReason;

  readonly amount: Cent;

  readonly refundFullOrder?: boolean;

  readonly refundCommissionToSeller?: boolean;

  readonly excludeShippingAmountFromRefund?: boolean;

  state: RefundOrderItemState;

  constructor(attributes: RefundOrderItemAttributes) {
    this.id = attributes.id;
    this.refundId = attributes.refundId;
    this.orderItemId = attributes.orderItemId;
    this.reason = attributes.reason;
    this.amount = attributes.amount;
    this.state = attributes.state ?? RefundOrderItemState.not_started;
    this.refundFullOrder = attributes.refundFullOrder;
    this.refundCommissionToSeller = attributes.refundCommissionToSeller;
    this.excludeShippingAmountFromRefund =
      attributes.excludeShippingAmountFromRefund;
  }
}
