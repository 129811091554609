import { MarketPageType } from '@bits-app/voggtpit-shared';
import { Grid, Stack } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useIdParam } from '@/hooks/use-id-params';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';

import { PanelDetailImage } from '../../components/elements/PanelDetailImage';
import { PanelDetails, Information } from '../../components/layouts/PanelDetails';
import { useEditMarketPageImage } from '../hooks/use-edit-market-page-cover.hook';
import { useMarketPage } from '../hooks/use-market-page.hook';

export const MarketPageDetail = () => {
  const marketPageId = useIdParam();
  const { t } = useTranslation();

  const { data: marketPage, isLoading, refetch } = useMarketPage(marketPageId);

  const previousPagePath = location.pathname.replace(/\/market-pages\/.*/, '/market-pages/');

  const tabs: ItemTab[] = [
    {
      Element: <MarketPageComponent key="/" marketPage={marketPage} loading={isLoading} />,
      label: t('marketPage.detail.title'),
      path: '/',
    },
  ];

  if (!marketPageId) {
    return null;
  }

  return (
    <ItemLayout<MarketPageType>
      id={marketPageId}
      tabs={tabs}
      title={marketPage?.title}
      previousPagePath={previousPagePath}
      editionDescriptor={{
        goBackName: t('marketPage.update.editionFormPath', {
          marketPageTitle: marketPage?.title,
        }),
        resourceId: marketPageId,
        resourceType: 'MarketPage',
        onSuccess: refetch,
      }}
      description={marketPage?.slug}
      currentTab={'/'}
      onTabChange={() => undefined}
      imageURL={marketPage?.coverUrl}
    />
  );
};

const MarketPageComponent = ({
  marketPage,
  loading,
}: {
  marketPage?: MarketPageType;
  loading: boolean;
}) => {
  const { editImage, editorRef } = useEditMarketPageImage(marketPage ? marketPage.id : '');

  const handleEditImage = (file: File) => {
    editImage(file);
  };

  if (!marketPage) return null;

  return (
    <DetailsLayout>
      <Grid xs={12} justifyContent="flex-end" display={'flex'} rowGap={2}>
        <Stack spacing={2} direction="row" ml="auto" pr={1} pl={1} alignItems="center">
          <EditButton to={`/market-pages/${marketPage.id}/edit`} />
        </Stack>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PanelDetails<MarketPageType>
            title={t('databaseExplorer.panel.language')}
            information={panelDetailsMarketPage}
            data={marketPage}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PanelDetailImage
            title={t('databaseExplorer.commercialEvent.coverUrl')}
            thumbnailUrl={marketPage.coverUrl || ''}
            alt={marketPage.title}
            maxSize={150000}
            onEdit={handleEditImage}
            imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
            ref={editorRef}
          />
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};

const panelDetailsMarketPage: Information<MarketPageType>[] = [
  { value: 'id', label: 'Id' },
  { value: 'categoryId', label: 'Category Id' },
  { value: 'coverUrl', label: 'Cover Url' },
  { value: 'slug', label: 'Slug' },
  { value: 'description', label: 'Description' },
  { value: 'title', label: 'Title' },
  { value: 'countryIds', label: 'Country Ids' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];
