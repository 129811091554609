import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';

import { Country } from './Country';
import { Order } from './Order';
import { UserTargetPromotion } from './UserTargetPromotion';

export enum PromotionType {
  campaign = 'campaign',
  reattribution = 'reattribution',
  referral = 'referral',
  referrer = 'referrer',
  welcome = 'welcome',
}

@Entity()
export class Promotion {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  name!: string;

  @Column({ nullable: true, type: 'int4' })
  promotionAmount!: number | null;

  @Column({ nullable: true, type: 'int4' })
  promotionPercentage!: number | null;

  @Column({ nullable: true, type: 'int4' })
  promotionMaxAmount!: number | null;

  @Column()
  totalBudget!: number;

  @Column({ default: 0 })
  spentBudget!: number;

  @OneToMany(() => UserTargetPromotion, (target) => target.promotion)
  userTargetPromotions!: UserTargetPromotion[];

  @OneToMany(() => Order, (order) => order.promotion)
  orders!: Order[];

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  @Column({ default: 'NULL' })
  countryId!: Country['id'];

  @Column({
    type: 'enum',
    enum: PromotionType,
    default: PromotionType.campaign,
  })
  type!: PromotionType;

  @Column({ type: 'timestamp' })
  expireAt!: Date;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
