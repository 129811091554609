import { useRef, useState } from 'react';

import type { Show, User } from '@bits-app/bits-server-data';
import {
  ShowOrderDeliveryData,
  PERMISSIONS,
  AvailableEntityForComment,
} from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ActivityLogs } from '@/components/elements/ActivityLogs';
import { Dropdown } from '@/components/elements/Dropdown';
import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { ExternalLink } from '@/components/elements/Link';
import { EditorRef, PanelDetailImage } from '@/components/elements/PanelDetailImage';
import { SoftDeletedBanner } from '@/components/elements/banner/SoftDeletedBanner';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useOwnUser } from '@/context/own-user.context';
import { useActivityLogs } from '@/hooks/use-activity-logs';
import { useIdParam } from '@/hooks/use-id-params';
import { QUERIES_KEYS } from '@/queries';
import { getStreamInspectorLink } from '@/utils';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ImpersonateAccessModal } from '@/voggt-database-explorer/users/views/profile/modal/ImpersonateAccess.modal';

import { AddToCommercialEventModal } from '../../../commercial-event/components/AddToCommercialEvent.modal';
import { RemoveFromCommercialEventModal } from '../../../commercial-event/components/RemoveFromCommercialEvent.modal';
import { useAddToCommercialEvent } from '../../../commercial-event/hooks/use-add-to-commercial-event';
import { useRemoveFromCommercialEvent } from '../../../commercial-event/hooks/use-remove-from-commercial-event';
import { useStressTestOnShow } from '../../../stress-tests/hooks/use-stress-test-on-show';
import { StressTestShowModal } from '../../../stress-tests/views/StressTestShow.modal';
import { useImpersonateFeature } from '../../../users/hooks/use-impersonate-feature';
import { useDeleteShow } from '../../hook/useDeleteShow';
import { useEditShowThumbnail } from '../../hook/useEditShowThumbnail';
import { useRefundShow } from '../../hook/useRefundShow';
import { useUnlockShow } from '../../hook/useUnlockShow';
import { panelDetailsPayoutData, PayoutData } from '../../misc/payoutdata.type';
import {
  panelDetailsOrderDelivery,
  panelDetailsShow,
  panelDetailsUser,
} from '../../misc/show.const';

import { DeleteAShowModal } from './DeleteAShow.modal';
import { RefundAshowModal } from './RefundAShow.modal';

const MAX_FILE_SIZE = 10000000;

type DetailsProps = {
  show: Show;
  loading: boolean;
  payoutData?: PayoutData;
  orderDeliveryData?: ShowOrderDeliveryData;
  isReadOnly: boolean;
  refreshAfterEdit: () => void;
};

export const Details = ({
  show,
  loading,
  payoutData,
  orderDeliveryData,
  isReadOnly,
  refreshAfterEdit,
}: DetailsProps) => {
  const showId = useIdParam();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermissions } = useOwnUser();

  const activityLogs = useActivityLogs({
    entity: AvailableEntityForComment.show,
    entityId: showId,
  });

  const editorRef = useRef<EditorRef>(null);

  const { mutate: unlockShow } = useUnlockShow(+showId);
  const refundShowHook = useRefundShow(+showId);

  const addShowToCommercialEventHook = useAddToCommercialEvent(+showId, 'shows');
  const removeShowFromCommercialEventHook = useRemoveFromCommercialEvent();
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const deleteShowHook = useDeleteShow(+showId, refreshAfterEdit);
  const useStressTestOnShowHook = useStressTestOnShow();

  const {
    ownUserIsAllowedToImpersonate,
    toggleImpersonateModalFeature,
    requestImpersonate,
    impersonateModalFeatureIsOpen,
  } = useImpersonateFeature(Number(show.userId));

  const { data: zohoUrl } = useQuery<string>(
    QUERIES_KEYS.getZohoUserLink(Number(showId)),
    async () => {
      const response = await authenticatedInstance.get(`/zoho/${showId}/zoho-show-link`);

      return response.data;
    },
  );

  const { mutate: onEditThumbnail } = useEditShowThumbnail({
    showId: +showId,
    onSuccess: () => {
      editorRef.current?.clearImage();
      editorRef.current?.close();
    },
    onError: (errors) => editorRef.current?.setImageErrors(errors),
  });

  if (!showId) {
    return null;
  }

  const itemsMenuList = [
    {
      key: 'databaseExplorer.commercialEvent.add.title',
      onClick: addShowToCommercialEventHook.toggleModalStatus,
    },
    {
      key: 'databaseExplorer.commercialEvent.remove.title',
      onClick: () => setIsRemoveModalOpen(true),
    },
    {
      key: 'databaseExplorer.show.clearCache.action',
      onClick: unlockShow,
      forbidden: !show.isBroadcasting,
    },
    {
      key: 'databaseExplorer.show.refund.action',
      onClick: refundShowHook.toggleModalIsOpen,
      forbidden: !refundShowHook.isAuthorized,
    },
    {
      key: 'databaseExplorer.show.delete.action',
      onClick: deleteShowHook.toggleModalStatus,
      forbidden: Boolean(
        !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHOW) ||
          show.deletedAt !== null,
      ),
    },
    {
      key: 'databaseExplorer.stressTests.title',
      onClick: useStressTestOnShowHook.toggleModalStatus,
      forbidden: !hasPermissions(PERMISSIONS.SHOW_STRESS_TEST.WRITE_SHOW_STRESS_TEST),
    },
    {
      forbidden: !ownUserIsAllowedToImpersonate,
      onClick: toggleImpersonateModalFeature,
      key: 'databaseExplorer.users.impersonate.action',
    },
  ];

  const healthStreamLink = getStreamInspectorLink(show);

  return (
    <DetailsLayout>
      {show?.deletedAt && <SoftDeletedBanner />}
      <div>
        <div className="flex justify-end items-center gap-2 mb-2">
          {!isReadOnly && <EditButton to={`/show/${showId}/edit`} />}

          <ForestAdminLink entity="show" entityId={showId} />

          <ExternalLink
            href={`https://app.voggt.com/shows/${showId}`}
            label={t('databaseExplorer.show.view-on-voggt')}
          />

          {hasPermissions(PERMISSIONS.USER.READ_USER_ZOHO_LINK) && (
            <ExternalLink href={zohoUrl} label={t('databaseExplorer.show.zoho')} />
          )}

          {healthStreamLink && (
            <ExternalLink
              href={healthStreamLink}
              label={t('link.show-inspector-health')}
              sx={{ textTransform: 'none' }}
            />
          )}

          <Dropdown
            title="Actions"
            options={itemsMenuList.map((item) => ({
              onClick: () => item.onClick && item.onClick(),
              forbidden: item.forbidden,
              label: t(item.key),
            }))}
          />
        </div>

        <div className="flex flex-col gap-4 lg:flex-row mb-2">
          <div className="flex flex-1 flex-col gap-4">
            <PanelDetails<Show>
              title={t('databaseExplorer.panel.main')}
              information={panelDetailsShow}
              data={show}
              loading={loading}
            />
          </div>

          <div className="flex flex-1 flex-col gap-4">
            <PanelDetailImage
              thumbnailUrl={show.thumbnailUrl}
              alt={show.name}
              onEdit={isReadOnly ? undefined : onEditThumbnail}
              maxSize={MAX_FILE_SIZE}
              imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              ref={editorRef}
            />

            <PanelDetails<User>
              title={t('databaseExplorer.panel.seller')}
              information={panelDetailsUser}
              data={show?.user}
              labelRow
              seeDetails={(seller) => navigate(`/user/${seller.id}`)}
              loading={loading}
            />

            {payoutData && (
              <div>
                <PanelDetails<PayoutData>
                  title={t('databaseExplorer.panel.payout')}
                  information={panelDetailsPayoutData}
                  data={payoutData}
                  labelRow
                  loading={loading}
                />
              </div>
            )}

            {orderDeliveryData && (
              <div>
                <PanelDetails<ShowOrderDeliveryData>
                  title={t('databaseExplorer.panel.delivery')}
                  information={panelDetailsOrderDelivery}
                  data={orderDeliveryData}
                  labelRow
                  loading={loading}
                />
              </div>
            )}

            <ActivityLogs
              logs={activityLogs.data}
              isLoading={activityLogs.isLoading}
              entityId={showId}
              entityName={AvailableEntityForComment.show}
            />
          </div>
        </div>
      </div>

      {addShowToCommercialEventHook.modalIsOpen && (
        <AddToCommercialEventModal
          onCancel={addShowToCommercialEventHook.toggleModalStatus}
          onConfirm={addShowToCommercialEventHook.submitUpdate}
        />
      )}
      {isRemoveModalOpen && (
        <RemoveFromCommercialEventModal
          showId={+showId}
          onCancel={() => setIsRemoveModalOpen(false)}
          onConfirm={(commercialEventId) => {
            removeShowFromCommercialEventHook.onConfirm(+showId, 'shows', commercialEventId);
            setIsRemoveModalOpen(false);
          }}
        />
      )}
      {useStressTestOnShowHook.modalIsOpen && (
        <StressTestShowModal
          onCancel={useStressTestOnShowHook.toggleModalStatus}
          onConfirm={useStressTestOnShowHook.stressTestShow}
          isLoading={useStressTestOnShowHook.isLoading}
          showId={+showId}
        />
      )}
      {deleteShowHook.modalIsOpen && <DeleteAShowModal {...deleteShowHook} showId={showId} />}

      {refundShowHook.modalIsOpen && (
        <RefundAshowModal
          showId={showId}
          isLoading={refundShowHook.isLoading}
          onCancel={refundShowHook.toggleModalIsOpen}
          onConfirm={refundShowHook.process}
        />
      )}

      <ImpersonateAccessModal
        requestImpersonate={requestImpersonate}
        impersonateModalFeatureIsOpen={impersonateModalFeatureIsOpen}
        toggleModal={toggleImpersonateModalFeature}
      />
    </DetailsLayout>
  );
};
