import { useCallback } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';

export const CreateShowNotificationButton = (props: { className: string; showId: string }) => {
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHOW_NOTIFICATION,
  );
  const navigate = useNavigate();

  const redirectToCreateForm = useCallback(
    () => navigate(`/show/${props.showId}/notifications-center/create`),
    [navigate, props.showId],
  );

  if (isReadOnly) {
    return null;
  }

  return (
    <ButtonOutlined className={props.className} onClick={redirectToCreateForm}>
      Create show notification
    </ButtonOutlined>
  );
};
