import type { RefundOrderItem } from './refund-order-item.entity';
import type { RefundDebitedAccount } from '../order-item';

export enum RefundState {
  'not_started' = 'not_started',
  'paying' = 'paying',
  'paid' = 'paid',
  'payment_failed' = 'payment_failed',
  'sending_seller_email' = 'sending_seller_email',
  'email_seller_sent' = 'email_seller_sent',
  'emailing_seller_failed' = 'emailing_seller_failed',
  'sending_buyer_email' = 'sending_buyer_email',
  'email_buyer_sent' = 'email_buyer_sent',
  'emailing_buyer_failed' = 'emailing_buyer_failed',
  'completed' = 'completed',
}

type RefundAttributes = {
  id: string;
  agent: string;
  debitedAccount: RefundDebitedAccount;
  refundOrderItems: RefundOrderItem[];
  sendEmailToSeller: boolean;
  sendEmailToBuyer: boolean;
  state: RefundState;
};

export class Refund {
  readonly id: string;

  readonly agent: string;

  readonly debitedAccount: RefundDebitedAccount;

  readonly sendEmailToBuyer: boolean;

  readonly sendEmailToSeller: boolean;

  refundOrderItems: RefundOrderItem[];

  state: RefundState;

  constructor(attributes: RefundAttributes) {
    this.id = attributes.id;
    this.agent = attributes.agent;
    this.debitedAccount = attributes.debitedAccount;
    this.sendEmailToBuyer = attributes.sendEmailToBuyer;
    this.sendEmailToSeller = attributes.sendEmailToSeller;
    this.refundOrderItems = attributes.refundOrderItems;
    this.state = attributes.state ?? RefundState.not_started;
  }
}
