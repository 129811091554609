import {
  isMatchingAmountFormat,
  RefundDebitedAccount,
  RefundReason,
  formatAmount,
  add,
} from '@bits-app/voggtpit-shared';
import { SelectChangeEvent } from '@mui/material';

import { OrderItem } from '@/entities/order-item.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { getDefaultRefundStateForReason } from '../../../../refund/use-cases/on-refund-reason/get-default-refund-state-for-reason';
import { selectMaxRefundableAmountFor } from '../../../redux/refund.selectors';
import { refundActions } from '../../../redux/refund.slice';

export const useOrderItemRefundForm = (orderItem: OrderItem) => {
  const maxAmount = useAppSelector((state) => selectMaxRefundableAmountFor(state, orderItem.id));

  const dispatch = useAppDispatch();

  const handleOnReasonSelected = (event: SelectChangeEvent<RefundReason>) => {
    const value = event.target.value as RefundReason;

    dispatch(getDefaultRefundStateForReason(value));
  };

  const handleOnDebitedAccountSelected = (event: SelectChangeEvent<RefundDebitedAccount>) => {
    const value = event.target.value as RefundDebitedAccount;

    dispatch(refundActions.setDebitedAccount(value));
  };

  const handleOnAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!isMatchingAmountFormat(value)) {
      return;
    }

    if (Number(value) > maxAmount) {
      return;
    }

    dispatch(
      refundActions.setRefundAmountFor({
        orderItemId: orderItem.id,
        amount: value === '' ? undefined : value,
      }),
    );
  };

  const handleRefundFullOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    const amount = value
      ? formatAmount(add(orderItem.amount, orderItem.shippingAmount))
      : undefined;

    dispatch(
      refundActions.setRefundFullOrder({
        orderItemId: orderItem.id,
        refundFullOrder: value,
        amount,
      }),
    );
  };

  const handleToggleRefundCommissionToSeller = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    dispatch(
      refundActions.setRefundCommissionToSeller({
        orderItemId: orderItem.id,
        refundCommissionToSeller: value,
      }),
    );
  };

  return {
    handleOnReasonSelected,
    handleOnDebitedAccountSelected,
    handleOnAmountChange,
    handleRefundFullOrder,
    handleToggleRefundCommissionToSeller,
  };
};
