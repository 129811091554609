import { Notification } from '@bits-app/voggtpit-shared';
import { CircularProgress } from '@mui/material';

import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';

import { useCreateShowNotification } from './use-create-show-notification';

export const ShowNotificationCreationView = () => {
  const { header, schemas, onCreate, isLoading } = useCreateShowNotification();
  const validationSchemaBuilder = validationSchemasBuilder['Show-Notification'];

  const goBackName = 'Show notifications list';

  if (!schemas.length || !header) {
    return null;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <UpsertForm<Notification>
      schemas={schemas}
      header={header}
      onUpsert={onCreate}
      goBackName={goBackName}
      validationSchemaBuilder={validationSchemaBuilder}
    />
  );
};
