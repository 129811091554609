import { Shipment, Order, Product, ShippingAddress, Show, User } from '@bits-app/bits-server-data';
import { PERMISSIONS, AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@/components/elements/Dropdown';
import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

import { ActivityLogs } from '../../../../components/elements/ActivityLogs';
import { useActivityLogs } from '../../../../hooks/use-activity-logs';
import { CancelOrder } from '../../../components/CancelOrder.modal';
import { EditButton } from '../../../components/EditButton';
import { useCancelOrder } from '../../hooks/useCancelOrder';
import { useCancelOrderShippingFee } from '../../hooks/useCancelOrderShippingFee';
import {
  panelDetailsOrder,
  PanelDetailsOrderWithPromotionsType,
  panelDetailsPromotions,
  panelDetailRefund,
  panelDetailDelivery,
  panelDetailsCancellation,
  panelDetailsUser,
  panelDetailsProduct,
  panelDetailsShippingAddress,
  panelDetailsShow,
  panelDetailsShipment,
  panelDetailsServiceFee,
  PanelDetailsOrderWithCollectedFeeAndFeeAmount,
} from '../../order.const';

import { CancelOrderShippingFee } from './CancelOrderShippingFee.modal';

type DetailsType = {
  loading: boolean;
  order: Order;
  isReadOnly: boolean;
  shipment?: Shipment;
};

export const Details = ({ loading, order, isReadOnly, shipment }: DetailsType) => {
  const orderId = useIdParam();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activityLogs = useActivityLogs({
    entity: AvailableEntityForComment.order,
    entityId: orderId,
  });

  const cancelOrderShippingFeeHook = useCancelOrderShippingFee(order);

  const cancelOrderHook = useCancelOrder(order.id);
  const { hasPermissions } = useOwnUser();

  if (!orderId) return null;

  const itemsMenuList = [
    {
      key: 'databaseExplorer.order.cancel.cancelOrder',
      onClick: cancelOrderHook.onOpenCancelOrder,
      forbidden: !cancelOrderHook.isAllowedToCancelOrder,
    },
    {
      key: 'Refund shipping fee',
      onClick: cancelOrderShippingFeeHook.toggleIsOpen,
      forbidden: !cancelOrderShippingFeeHook.isAllowedToRefundShippingFee,
    },
  ];

  if (!order.shopId) {
    const refundAction = {
      key: 'databaseExplorer.order.refund',
      onClick: () => navigate(`/refund/${orderId}`),
      forbidden: !hasPermissions(PERMISSIONS.REFUND.WRITE_REFUND),
    };

    itemsMenuList.push(refundAction);
  }

  return (
    <>
      <DetailsLayout>
        <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1} alignItems="center">
          {!isReadOnly && <EditButton to={`/order/${orderId}/edit`} />}
          <ForestAdminLink entity="order" entityId={orderId} />
          <Dropdown
            title="Actions"
            options={itemsMenuList.map((item) => ({
              onClick: () => item.onClick && item.onClick(),
              forbidden: item.forbidden,
              label: t(item.key),
            }))}
          />{' '}
        </Stack>

        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="flex flex-1 flex-col gap-4 overflow-hidden">
            <PanelDetails<Order>
              title={t('databaseExplorer.panel.main')}
              information={panelDetailsOrder}
              data={order}
              loading={loading}
            />

            {shipment && (
              <PanelDetails<Shipment>
                title={t('databaseExplorer.panel.shipment')}
                information={panelDetailsShipment}
                data={shipment}
                loading={loading}
                labelRow
                seeDetails={(shipment) => navigate(`/shipment/${shipment.id}`)}
              />
            )}

            <ActivityLogs
              logs={activityLogs.data}
              isLoading={activityLogs.isLoading}
              entityId={orderId}
              entityName={AvailableEntityForComment.order}
            />

            <PanelDetails<PanelDetailsOrderWithPromotionsType>
              title={t('databaseExplorer.panel.promotion')}
              information={panelDetailsPromotions}
              data={order as PanelDetailsOrderWithPromotionsType}
              loading={loading}
              labelRow
            />

            <PanelDetails<ShippingAddress>
              title={t('databaseExplorer.panel.shippingAddresses')}
              information={panelDetailsShippingAddress}
              seeDetails={(shippingAddress) => navigate(`/shipping-address/${shippingAddress.id}`)}
              data={order?.shippingAddress}
              labelRow
              loading={loading}
            />

            <PanelDetails<PanelDetailsOrderWithCollectedFeeAndFeeAmount>
              title={t('databaseExplorer.panel.serviceFee')}
              information={panelDetailsServiceFee}
              data={order as PanelDetailsOrderWithCollectedFeeAndFeeAmount}
              labelRow
              loading={loading}
            />
          </div>

          <div className="flex flex-1 flex-col gap-4 overflow-hidden">
            <PanelDetails<Order>
              title={t('databaseExplorer.panel.delivery')}
              information={panelDetailDelivery}
              data={order}
              loading={loading}
              labelRow
            />

            <PanelDetails<User>
              title={t('databaseExplorer.panel.seller')}
              information={panelDetailsUser('seller')}
              data={order?.seller}
              labelRow
              seeDetails={(seller) => navigate(`/user/${seller.id}`)}
              loading={loading}
            />

            <PanelDetails<User>
              title={t('databaseExplorer.panel.buyer')}
              information={panelDetailsUser('buyer')}
              data={order?.customer}
              labelRow
              seeDetails={(buyer) => navigate(`/user/${buyer.id}`)}
              loading={loading}
            />

            <PanelDetails<Product>
              title={t('databaseExplorer.panel.product')}
              information={panelDetailsProduct}
              data={order?.product}
              labelRow
              seeDetails={(product) => navigate(`/product/${product.id}`)}
              loading={loading}
            />

            <PanelDetails<Order>
              title={t('databaseExplorer.panel.refund')}
              information={panelDetailRefund}
              data={order}
              loading={loading}
              labelRow
            />

            <PanelDetails<Order>
              title={t('databaseExplorer.panel.cancellation')}
              information={panelDetailsCancellation}
              data={order}
              loading={loading}
              labelRow
            />

            <PanelDetails<Show>
              title={t('databaseExplorer.panel.show')}
              information={panelDetailsShow}
              data={order?.show}
              seeDetails={(show) => navigate(`/show/${show.id}`)}
              labelRow
              loading={loading}
            />
          </div>
        </div>
      </DetailsLayout>

      {cancelOrderHook.openCancelOrder && (
        <CancelOrder
          onClose={cancelOrderHook.closeCancelOrder}
          onConfirm={cancelOrderHook.onCancelOrder}
          isLoading={cancelOrderHook.isLoading}
        />
      )}

      {cancelOrderShippingFeeHook.isOpen && (
        <CancelOrderShippingFee {...cancelOrderShippingFeeHook} />
      )}
    </>
  );
};
