import { useEffect, useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { OrderItem } from '@/entities/order-item.entity';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { TableWithStickyColumns } from '@Elements/TableWithStickyColumns';

import {
  selectIsRefundFormCompleted,
  selectMainOrderItem,
  selectOrderItemsExcludingMain,
  selectRefundError,
  selectIsRefundLoading,
  selectAreAmountsInconsistent,
} from '../../redux/refund.selectors';
import { useStartRefund } from '../hooks/use-start-refund';

import { ConfirmRefundDialog } from './ConfirmRefundDialog';
import { ScrollableRefundTable } from './ScrollableRefundTable/ScrollableRefundTable';
import { StickyBody, StickyHeader } from './StickyTable/StickyRefundTable';
import { useRefundOrderItem } from './use-refund-order-item';

export type HeadCell = {
  id: keyof OrderItem;
  label: string;
  maxWidth?: React.CSSProperties['maxWidth'];
  minWidth?: React.CSSProperties['minWidth'];
};

export const RefundOrderItem = () => {
  const mainOrderItem = useAppSelector(selectMainOrderItem);
  const orderItems = useAppSelector(selectOrderItemsExcludingMain);
  const isRefundFormCompleted = useAppSelector(selectIsRefundFormCompleted);
  const areAmountsInconsistent = useAppSelector(selectAreAmountsInconsistent);
  const loading = useAppSelector(selectIsRefundLoading);
  const error = useAppSelector(selectRefundError);
  const { hasPermissions } = useOwnUser();

  const { confirmRefund, handleRefundCancelation, handleRefundConfirmation } = useRefundOrderItem();

  const [scrollableTableRef, setScrollableTableRef] = useState<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const startRefund = useStartRefund();

  const isReadOnly = !hasPermissions(PERMISSIONS.REFUND.WRITE_REFUND);

  const orderItemStickyHeadCells: HeadCell[] = [
    { id: 'id', label: t('orderItemLabels.orderItemId'), maxWidth: 70 },
    { id: 'product', label: t('orderItemLabels.product'), maxWidth: 140 },
    { id: 'showId', label: t('orderItemLabels.showId'), maxWidth: 70 },
    { id: 'buyerId', label: t('orderItemLabels.buyerId'), maxWidth: 70 },
    { id: 'buyer', label: t('orderItemLabels.buyer') },
    { id: 'seller', label: t('orderItemLabels.seller') },
  ];

  const orderItemHeadCells: HeadCell[] = [
    { id: 'shippingStatus', label: t('orderItemLabels.shippingStatus'), minWidth: 120 },
    { id: 'paymentStatus', label: t('orderItemLabels.paymentStatus'), minWidth: 130 },
    { id: 'amount', label: t('orderItemLabels.amount'), minWidth: 100 },
    { id: 'shippingAmount', label: t('orderItemLabels.shippingAmount'), minWidth: 135 },
    { id: 'commission', label: t('orderItemLabels.commission'), minWidth: 100 },
    { id: 'purchaseDate', label: t('orderItemLabels.purchaseDate'), minWidth: 180 },
    { id: 'refundedAmount', label: t('orderItemLabels.refundedAmount'), minWidth: 155 },
    { id: 'refundReason', label: t('orderItemLabels.refundReason'), minWidth: 160 },
    { id: 'refundDebitedAccount', label: t('orderItemLabels.debitedAccount'), minWidth: 150 },
  ];

  const refundFormHeadCells: (Omit<HeadCell, 'id'> & { id: string })[] = [
    { id: 'refundFullOrder', label: t('orderItemLabels.refundFullOrder') },
    { id: 'refundFullOrder', label: t('orderItemLabels.refundCommissionToSeller') },
    { id: 'refundReason', label: t('orderItemLabels.refundReason') },
    { id: 'amountToRefund', label: t('orderItemLabels.amountToRefund') },
    { id: 'debitedAccount', label: t('orderItemLabels.debitedAccount') },
  ];

  useEffect(() => {
    if (params.id && !error) {
      startRefund(params.id);
      return;
    }

    navigate('/refund');
  }, [params, navigate, error, startRefund]);

  if (!mainOrderItem) {
    return null;
  }

  return (
    <>
      <Dialog
        open
        fullWidth
        fullScreen
        sx={{ margin: ({ spacing }) => spacing(2) }}
        PaperProps={{ sx: { borderRadius: '8px' } }}
      >
        <DialogTitle color="common.black">{t('refund.process.title')}</DialogTitle>

        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              maxHeight: `calc(100% - 50px)`,
              maxWidth: ({ spacing }) => `calc(100vw - ${spacing(4)})`,
            }}
          >
            <TableWithStickyColumns
              tableHeader={
                <StickyHeader headCell={orderItemStickyHeadCells} isReadOnly={isReadOnly} />
              }
              tableBody={
                <StickyBody
                  mainOrderItem={mainOrderItem}
                  orderItems={orderItems}
                  borderRowColSpan={orderItemStickyHeadCells.length + 1}
                  loading={loading}
                  isReadOnly={isReadOnly}
                />
              }
              mainTableRef={scrollableTableRef}
              sxTableContainer={{
                minWidth: 750,
                maxWidth: 850,
              }}
            >
              <ScrollableRefundTable
                descriptionHeadCells={orderItemHeadCells}
                refundFormHeadCells={refundFormHeadCells}
                mainOrderItem={mainOrderItem}
                orderItems={orderItems}
                borderRowColSpan={orderItemHeadCells.length + refundFormHeadCells.length + 1}
                tableContainerRef={setScrollableTableRef}
                loading={loading}
                isReadOnly={isReadOnly}
              />
            </TableWithStickyColumns>
          </Box>
        </DialogContent>

        <Grid>
          {areAmountsInconsistent && (
            <Alert severity="warning">
              Some amounts are negative. Please, update them manually to be able to process the
              refund.
            </Alert>
          )}
          <DialogActions
            sx={{
              padding: ({ spacing }) => spacing(3),
            }}
          >
            <Button color="error" onClick={handleRefundCancelation}>
              {t('cancel')}
            </Button>

            {!isReadOnly && (
              <Button
                variant="contained"
                onClick={handleRefundConfirmation}
                disabled={!isRefundFormCompleted || areAmountsInconsistent}
              >
                {t('refund.refund')}
              </Button>
            )}
          </DialogActions>
        </Grid>
      </Dialog>

      {confirmRefund.open && <ConfirmRefundDialog {...confirmRefund} />}
    </>
  );
};
