import { useMemo } from 'react';

import { MRT_ColumnDef, MRT_Header } from 'material-react-table';

import { SwitchFilterMRT } from '@/components/elements/DataList/filters/SwitchFilterMRT';
import { isSetFilter, isStringFilter } from '@Elements/filters/types';

import { CustomCell } from '../Cell/CustomCell';
import { LinkRedirection } from '../Cell/LinkRedirection';
import type { CellDefinition, ColumnFilter } from '../datalist-type';
import { AutocompleteFilterMRT, EnumFilterMRT, TextFilterMRT } from '../filters';

export const useMaterialReactTableBridge = <T extends Record<string, unknown>>(
  cellDefinition: CellDefinition<T>[],
) => {
  return useMemo(
    () =>
      cellDefinition.map((row) => {
        const {
          id,
          label,
          type,
          getValue,
          getLink,
          minWidth,
          maxWidth,
          width,
          accessorFn,
          enableColumnFilter,
          columnFilter,
        } = row;
        const _width = width || forcedWidthForField(id);
        const base = Object.assign(
          {
            header: label,
            minSize: _width || minWidth,
            maxSize: _width || maxWidth,
            size: _width,
            Filter: columnFilter
              ? getCustomFilter({
                  ...columnFilter,
                  fieldName: columnFilter.fieldName ?? String(id),
                })
              : undefined,
            filterVariant: columnFilter?.type === 'multi-select' ? 'multi-select' : undefined,
          },
          accessorFn ? { id, accessorFn } : { accessorKey: id },
          columnFilter ? { enableColumnFilter: true } : {},
          enableColumnFilter !== undefined ? { enableColumnFilter } : {},
        );
        const customRender = type && type !== 'string';
        const isLinkCell = type === 'internal-link' || type === 'external-link';

        if (isLinkCell && type && getLink) {
          return {
            ...base,
            Cell: ({ cell }) => <LinkRedirection {...getLink(cell.row.original)} type={type} />,
          };
        }
        if (customRender) {
          return {
            ...base,
            Cell: ({ cell }) => {
              return (
                <CustomCell
                  value={getValue ? getValue(cell.row.original) : cell.getValue()}
                  type={type}
                />
              );
            },
          };
        }
        return {
          ...base,
          Cell: ({ cell }) => <>{getValue ? getValue(cell.row.original) : cell.getValue()}</>,
        };
      }) as MRT_ColumnDef<T>[],
    [cellDefinition],
  );
};

const forcedWidthForField = (fieldId: string | number | symbol) => {
  switch (fieldId) {
    case 'currency':
      return 110;
    default:
      return undefined;
  }
};

const getCustomFilter = (filter: ColumnFilter & { fieldName: string }) => {
  switch (filter.type) {
    case 'multi-select':
      return ({ header }: { header: MRT_Header }) => {
        const filters = header.column.getFilterValue() as string[];

        return (
          <EnumFilterMRT
            header={header}
            activeFilters={filters ? (isSetFilter(filters) ? filters : undefined) : undefined}
            options={filter.options ?? []}
          />
        );
      };

    case 'autocomplete':
      return ({ header }: { header: MRT_Header }) => {
        if (!filter.onAutocomplete) {
          return null;
        }

        const filters = header.column.getFilterValue() as string;

        return (
          <AutocompleteFilterMRT
            header={header}
            activeFilters={filters ? (isStringFilter(filters) ? filters : undefined) : undefined}
            options={filter.options ?? []}
            onAutocomplete={filter.onAutocomplete}
            loading={filter.loading}
          />
        );
      };

    case 'text':
      return ({ header }: { header: MRT_Header }) => {
        const filters = header.column.getFilterValue() as string;

        return (
          <TextFilterMRT
            header={header}
            activeFilters={filters ? (isStringFilter(filters) ? filters : undefined) : undefined}
          />
        );
      };

    case 'switch':
      return ({ header }: { header: MRT_Header }) => {
        const filters = header.column.getFilterValue() as string;

        return (
          <SwitchFilterMRT
            header={header}
            activeFilters={
              filters
                ? isSetFilter(filters)
                  ? (filters.map(String) as ('true' | 'false')[])
                  : undefined
                : undefined
            }
          />
        );
      };

    default:
      return () => null;
  }
};
