import type { Permission } from './permissions';

import { isAtLeastContaining } from '../utils';

import {
  ADMIN,
  CARE_INTERN,
  CARE_INTERN_LEAD,
  FINANCE,
  GROWTH,
  OPS,
  REGIONAL_MANAGER,
} from './permissions';

export const isCareInternLeadPermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, CARE_INTERN_LEAD);

export const isCareInternPermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, CARE_INTERN);

export const isRegionalManagerPermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, REGIONAL_MANAGER);

export const isFinancePermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, FINANCE);

export const isGrowthPermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, GROWTH);

export const isOpsPermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, OPS);

export const isAdminPermissions = (permissions: Permission[]) =>
  isAtLeastContaining(permissions, ADMIN);
