import * as yup from 'yup';

import { NotificationTriggerAudienceEnum } from '../notifications-center';

import { SchemaValidation } from './validation-schema';

export class ShowNotificationValidationSchema extends SchemaValidation {
  availableResolver = {
    title: yup.string().required(),
    body: yup.string().required(),
    audiences: yup
      .array()
      .of(
        yup
          .mixed<NotificationTriggerAudienceEnum>()
          .oneOf(Object.values(NotificationTriggerAudienceEnum))
          .required(),
      ),
  };
}
