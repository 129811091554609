import { Order } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useOrderDetail = (orderId: string) => {
  const navigate = useNavigate();
  const {
    data: axiosResponse,
    refetch,
    isLoading,
  } = useQuery<AxiosResponse<Order>>({
    queryKey: QUERIES_KEYS.orderDetail(Number(orderId)),
    queryFn: () => authenticatedInstance.get(`/database-explorer/order/${orderId}`),
    onError: () => {
      navigate('/orders');
    },
  });

  return {
    order: axiosResponse?.data,
    loading: isLoading,
    refreshData: refetch,
  };
};
