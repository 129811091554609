import { User } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';

export const useSearchUserByDeviceId = () => {
  return useMutation({
    mutationFn: async (deviceId: string) => {
      const { data } = await authenticatedInstance.get<User[]>(
        `/bits-data/user-used-device/search?deviceId=${deviceId}`,
      );
      return data;
    },
  });
};
