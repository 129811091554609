import { Product } from '@bits-app/bits-server-data';

export const ProductFeedRecommendationPreview = ({
  product,
  index,
}: {
  product: Product;
  index: number;
}) => {
  return (
    <a href={'/product/' + product.id} target="_blank" className="bg-white p-4 rounded-lg relative">
      <div className="absolute top-0 right-0 left-0 flex">
        <div className="h-10 w-10 bg-green-500 rounded-full flex items-center justify-center">
          <span className="text-white text-base font-semibold">{index}</span>
        </div>
      </div>
      <div className="rounded-lg flex flex-col gap-1" key={product.id}>
        <img src={product.images?.[0].url} alt={product.name} className={'h-60 aspect-[2/3]'} />
        <p className="text-base">
          {product.id} - {product.name}
        </p>
        <p className="font-medium text-xs">@{product.seller.username}</p>
      </div>
    </a>
  );
};
