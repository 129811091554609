import type { OrderCancellationReason } from './order-cancellation-reason.enum';
import type { PaymentStatus } from './payment-status.enum';
import type { RefundDebitedAccount } from './refund-debited-account.enum';
import type { RefundReason } from './refund-reason.enum';
import type { Customer } from '../customer/customer.entity';
import type { Product } from '../product/product.entity';
import type { Show } from '../show/show.entity';

import { Currency } from '../currency.enum';

import { OrderStatus } from './order-status.enum';

export type OrderItemAttributes = {
  id: number;
  paymentStatus: PaymentStatus;
  amount: number;
  commission?: number;
  buyerServiceFeeAmount: number;
  buyerServiceFeeAmountRefundedAt?: Date;
  shippingAmount?: number;
  currency?: Currency;
  seller: Customer;
  buyer: Customer;
  show: Show;
  purchaseDate: Date;
  updateDate: Date;
  shippingStatus?: OrderStatus;
  shippingProvider?: string;
  trackingNumber?: string;
  refundedAmount?: number;
  refundDebitedAccount?: RefundDebitedAccount;
  refundReason?: RefundReason;
  refundAgent?: string;
  refundedAt?: Date;
  product: Product;
  paymentIntentId: string;
  cancellationReason?: OrderCancellationReason;
  cancellationRequestedAt?: Date;
  promotionAmount?: number;
  promotionTopUpStripeTransferId?: string;
  percentageFee?: number;
  fixedFee?: number;
  feeAmount?: number;
  feeDeductibleFromBuyerPayment?: number;
  feeDeductibleFromTopUpToSeller?: number;
  includeShippingFeesForCommissionByDefault?: boolean;
  showId?: number;
  shopId?: number;
  shippingFeesRefundedAt?: Date;
};

export class OrderItem {
  readonly id: number;

  readonly paymentStatus: PaymentStatus;

  readonly amount: number;

  readonly commission: number;

  readonly shippingAmount: number;

  readonly shippingStatus: OrderStatus;

  readonly seller: Customer;

  readonly buyer: Customer;

  readonly show: Show;

  readonly purchaseDate: Date;

  readonly refundedAmount?: number;

  readonly refundDebitedAccount?: RefundDebitedAccount;

  readonly refundReason?: RefundReason;

  readonly refundAgent?: string;

  readonly refundedAt?: Date;

  readonly product: Product;

  readonly currency: Currency;

  readonly paymentIntentId: string;

  readonly cancellationReason?: OrderCancellationReason;

  readonly cancellationRequestedAt?: Date;

  readonly promotionAmount?: number;

  readonly updateDate: Date;

  readonly shippingProvider?: string;

  readonly trackingNumber?: string;

  readonly promotionTopUpStripeTransferId?: string;

  readonly percentageFee?: number;

  readonly fixedFee?: number;

  readonly feeAmount?: number;

  readonly feeDeductibleFromBuyerPayment?: number;

  readonly feeDeductibleFromTopUpToSeller?: number;

  readonly includeShippingFeesForCommissionByDefault?: boolean;

  readonly showId?: number;

  readonly shopId?: number;

  readonly buyerServiceFeeAmount: number;

  readonly buyerServiceFeeAmountRefundedAt?: Date;

  readonly shippingFeesRefundedAt?: Date;

  constructor(attributes: OrderItemAttributes) {
    this.id = attributes.id;
    this.paymentStatus = attributes.paymentStatus;
    this.amount = attributes.amount;
    this.commission = attributes.commission ?? 0;
    this.shippingAmount = attributes.shippingAmount ?? 0;
    this.shippingStatus = attributes.shippingStatus ?? OrderStatus.pending;
    this.seller = attributes.seller;
    this.buyer = attributes.buyer;
    this.show = attributes.show;
    this.purchaseDate = attributes.purchaseDate;
    this.refundedAmount = attributes.refundedAmount;
    this.refundDebitedAccount = attributes.refundDebitedAccount;
    this.refundReason = attributes.refundReason;
    this.refundAgent = attributes.refundAgent;
    this.refundedAt = attributes.refundedAt;
    this.product = attributes.product;
    this.currency = attributes.currency ?? Currency.eur;
    this.paymentIntentId = attributes.paymentIntentId;
    this.cancellationReason = attributes.cancellationReason;
    this.cancellationRequestedAt = attributes.cancellationRequestedAt;
    this.promotionAmount = attributes.promotionAmount;
    this.promotionTopUpStripeTransferId =
      attributes.promotionTopUpStripeTransferId;
    this.updateDate = attributes.updateDate;
    this.shippingProvider = attributes.shippingProvider;
    this.trackingNumber = attributes.trackingNumber;
    this.percentageFee = attributes.percentageFee;
    this.fixedFee = attributes.fixedFee;
    this.feeAmount = attributes.feeAmount;
    this.feeDeductibleFromBuyerPayment =
      attributes.feeDeductibleFromBuyerPayment;
    this.feeDeductibleFromTopUpToSeller =
      attributes.feeDeductibleFromTopUpToSeller;
    this.includeShippingFeesForCommissionByDefault =
      attributes.includeShippingFeesForCommissionByDefault ?? true;
    this.showId = attributes.showId;
    this.shopId = attributes.shopId;
    this.buyerServiceFeeAmount = attributes.buyerServiceFeeAmount;
    this.buyerServiceFeeAmountRefundedAt =
      attributes.buyerServiceFeeAmountRefundedAt;
    this.shippingFeesRefundedAt = attributes.shippingFeesRefundedAt;
  }

  isGiveaway() {
    return this.amount === 0;
  }

  get orderId() {
    return [this.show.id, this.buyer.username].join('_');
  }
}
