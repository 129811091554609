import { useState } from 'react';

import { Order } from '@bits-app/bits-server-data';
import { OrderStatus, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';
import { queryClient, QUERIES_KEYS } from '@/queries';

import { authenticatedInstance } from '../../../../../axios/axios.instance';
import { useSnackbar } from '../../../../../components/elements/snackbar/use-snackbar';

export const useSetOrderShippedOrDelivered = (orderId: Order['id']) => {
  const [statusToSet, setStatusToSet] = useState<
    OrderStatus.shipped | OrderStatus.delivered | null
  >(null);

  const { hasPermissions } = useOwnUser();
  const snackbar = useSnackbar();

  const isAllowedToSetAsShippedOrDelivered = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_ORDER,
  );

  const { mutate: onSetAsShippedOrDelivered } = useMutation({
    mutationFn: () => {
      if (!statusToSet) {
        return Promise.reject();
      }

      return authenticatedInstance.patch(`/database-explorer/order/${orderId}`, {
        status: statusToSet,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.showOrders());
      snackbar.success('databaseExplorer.successfulUpdate', {
        interpolationMap: { resourceId: orderId, resourceType: 'Order' },
      });
      setStatusToSet(null);
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });

  return {
    isAllowedToSetAsShippedOrDelivered,
    onOpenSetAsShippedOrDelivered: (status: OrderStatus.shipped | OrderStatus.delivered) =>
      setStatusToSet(status),
    onSetAsShippedOrDelivered,
    closeSetAsShippedOrDelivered: () => setStatusToSet(null),
    statusToSet,
  };
};
