import { useState } from 'react';

import { Order } from '@bits-app/bits-server-data';
import { OrderCancellationReason, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../queries';

export const useCancelOrder = (orderId: Order['id']) => {
  const [open, setOpen] = useState(false);
  const onOpenCancelOrder = () => setOpen(true);
  const closeCancelOrder = () => setOpen;

  const { hasPermissions } = useOwnUser();
  const isAllowedToCancelOrder = hasPermissions(PERMISSIONS.ORDER.WRITE_ORDER_CANCEL);

  const snackbar = useSnackbar();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (cancellationReason: OrderCancellationReason | null) => {
      if (!cancellationReason) {
        return;
      }

      try {
        await authenticatedInstance.patch(`/database-explorer/order/${orderId}/cancel-order`, {
          cancellationReason,
        });

        snackbar.success('databaseExplorer.order.cancel.success', {
          interpolationMap: { orderId, cancellationReason },
        });
      } catch (error) {
        snackbar.error('somethingWrongHappened');
      } finally {
        queryClient.invalidateQueries(QUERIES_KEYS.orderDetail(Number(orderId)));
      }
    },
    onSuccess: () => {
      closeCancelOrder();
    },
  });

  return {
    isAllowedToCancelOrder,
    openCancelOrder: open,
    onOpenCancelOrder,
    onCancelOrder: mutate,
    closeCancelOrder,
    isLoading,
  };
};
