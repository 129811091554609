import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

@Entity()
export class UserDataConsent {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column()
  userId!: number;

  @OneToOne(() => User, (user) => user.dataConsent)
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForAppPersonalizationAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForEmailPromotionsAndVouchersAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForEmailShowsAndEventsAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForEmailNewsAndNewFeaturesAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForEmailPartnerNewsAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForSMSAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  hasGivenConsentForDataSharingWithThirdPartiesAt!: Date | null;

  @Column({ type: 'boolean', default: false })
  userHasReceivedDataConsentRequest!: boolean;

  @Column({ nullable: true, type: 'timestamp' })
  sellerHasGivenConsentForEmailsAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  sellerHasGivenConsentForSMSAt!: Date | null;

  @Column({ type: 'boolean', default: false })
  sellerHasReceivedDataConsentRequest!: boolean;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn({ name: 'deletedAt', type: 'timestamp' })
  deletedAt!: Date;
}
