import type { Country } from '@bits-app/bits-server-data';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { Information, PanelDetails } from '@/components/layouts/PanelDetails';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

type DetailProps = {
  country: Country;
  panelDetailsCountry: Information<Country>[];
  loading: boolean;
};

export const Detail = ({ country, panelDetailsCountry, loading }: DetailProps) => {
  const { id: countryId = '' } = useParams();
  const { t } = useTranslation();

  if (!countryId) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <ForestAdminLink entity="country" entityId={countryId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<Country>
              title={t('databaseExplorer.panel.country')}
              information={panelDetailsCountry}
              data={country}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
