import {
  formatAmountWithCurrency,
  Currency,
  CommercialEventApplicationWithData,
} from '@bits-app/voggtpit-shared';

import { formatText } from '@/utils';

import { CellDefinition } from '../../components/elements/DataList';

export const CommercialEventApplicationWithDataCellDefinition: CellDefinition<CommercialEventApplicationWithData>[] =
  [
    {
      id: 'sellerInfo.username' as keyof CommercialEventApplicationWithData,
      label: 'Seller username',
      accessorFn: (row) => row.sellerInfo.username,
      getLink: (row) => ({ link: `/user/${row.sellerInfo.id}`, label: row.sellerInfo.username }),
      type: 'internal-link',
    },
    {
      id: 'commercialEventApplication.show.startAt' as keyof CommercialEventApplicationWithData,
      label: 'Show start at',
      accessorFn: (data) => data.commercialEventApplication.show.startAt.toLocaleString(),
      type: 'date-with-time',
    },
    {
      id: 'commercialEventApplication.commercialEvent.name' as keyof CommercialEventApplicationWithData,
      label: 'Commercial event',
      accessorFn: (data) => formatText(data.commercialEventApplication.commercialEvent.name),
      getLink: (data) => ({
        link: `/commercial-event/${data.commercialEventApplication.commercialEventId}`,
        label: data.commercialEventApplication.commercialEvent.name,
      }),
      type: 'internal-link',
    },
    {
      id: 'sellerInfo.gmvPerShowLast60Days' as keyof CommercialEventApplicationWithData,
      label: 'GMV per show last 60 days',
      getValue: (data) =>
        formatAmountWithCurrency(data.sellerInfo.gmvPerShowLast60Days, Currency.eur),
    },
    {
      id: 'commercialEventApplication.show.category.name' as keyof CommercialEventApplicationWithData,
      label: 'Category',
      accessorFn: (data) => formatText(data.commercialEventApplication.show.category.name),
    },
    {
      id: 'commercialEventApplication.showId' as keyof CommercialEventApplicationWithData,
      label: 'Show id',
      getLink: (data) => ({
        label: data.commercialEventApplication.showId,
        link: `/show/${data.commercialEventApplication.showId}`,
      }),
      type: 'internal-link',
    },
    {
      id: 'commercialEventApplication.createdAt' as keyof CommercialEventApplicationWithData,
      label: 'Applied at',
      getValue: (data) => data.commercialEventApplication.createdAt.toLocaleString(),
      type: 'date-with-time',
    },
    {
      id: 'commercialEventApplication.show.countryId' as keyof CommercialEventApplicationWithData,
      label: 'Show country',
      accessorFn: (data) => data.commercialEventApplication.show.countryId,
      enableColumnFilter: true,
      columnFilter: {
        fieldName: 'featuredShowApplication.show.countryId',
        type: 'multi-select',
        options: [
          { label: 'France', value: 'FR' },
          { label: 'Germany', value: 'DE' },
          { label: 'Autriche', value: 'AT' },
          { label: 'Belgium', value: 'BE' },
          { label: 'Netherlands', value: 'NL' },
        ],
      },
    },
    {
      id: 'commercialEventApplication.show.gmvTargetAmount' as keyof CommercialEventApplicationWithData,
      label: 'GMV Target Amount',
      getValue: (data) =>
        formatAmountWithCurrency(
          data.commercialEventApplication.show.gmvTargetAmount / 100,
          Currency.eur,
        ),
    },
    {
      id: 'featuredShowApplication.status' as keyof CommercialEventApplicationWithData,
      label: 'Status',
      accessorFn: (data) => data.commercialEventApplication.status,
      type: 'badge',
    },
    {
      id: 'sellerInfo.firstShowStartedAt' as keyof CommercialEventApplicationWithData,
      label: 'First show started at',
      getValue: (data) =>
        data.sellerInfo.firstShowStartedAt
          ? data.sellerInfo.firstShowStartedAt.toLocaleString()
          : null,
      type: 'date-with-time',
    },
    {
      id: 'sellerInfo.maxShowGmv' as keyof CommercialEventApplicationWithData,
      label: 'Max show GMV',
      getValue: (data) => formatAmountWithCurrency(data.sellerInfo.maxShowGmv, Currency.eur),
      type: 'money',
    },
    {
      id: 'sellerInfo.phoneNumber' as keyof CommercialEventApplicationWithData,
      label: 'Phone number',
      accessorFn: (data) => String(data.sellerInfo.phoneNumber),
    },
    {
      id: 'sellerInfo.email' as keyof CommercialEventApplicationWithData,
      label: 'Email',
      accessorFn: (data) => data.sellerInfo.email,
    },
  ];
