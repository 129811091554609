import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';

export const AuthenticatedRoute = () => {
  const { ownUser } = useOwnUser();

  const location = useLocation();

  if (!ownUser) {
    return <Navigate to="/login" state={{ from: location.pathname + location.search }} />;
  }

  return <Outlet />;
};
