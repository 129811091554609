import type { CountryCodeIso2 } from '../country-code-iso2.enum';

type ShowAttributes = {
  id: number;
  name: string;
  startAt: Date;
  country: CountryCodeIso2;
};

export class Show {
  readonly id: number;

  readonly name: string;

  readonly startAt: Date;

  readonly country: CountryCodeIso2;

  constructor(attributes: ShowAttributes) {
    this.id = attributes.id;
    this.name = attributes.name;
    this.startAt = attributes.startAt;
    this.country = attributes.country;
  }
}
