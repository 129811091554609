import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { CommercialEvent } from './CommercialEvent';
import { Show } from './Show';

export enum CommercialEventApplicationStatus {
  pending = 'pending',
  approved = 'approved',
  refused = 'refused',
}

export enum CommercialEventApplicationDecisionReason {
  great_items = 'great_items',
  great_track_record = 'great_track_record',
  loyal_seller = 'loyal_seller',
  not_enough_items = 'not_enough_items',
  not_qualitative_enough = 'not_qualitative_enough',
  not_experienced_enough = 'not_experienced_enough',
  full_calendar = 'full_calendar',
}

@Entity()
export class CommercialEventApplication {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @OneToOne(() => Show)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Column()
  showId!: Show['id'];

  @OneToOne(() => CommercialEvent)
  @JoinColumn({ name: 'commercialEventId' })
  commercialEvent!: CommercialEvent;

  @Column()
  commercialEventId!: CommercialEvent['id'];

  @Column({
    type: 'enum',
    enum: CommercialEventApplicationStatus,
    default: CommercialEventApplicationStatus.pending,
  })
  status!: CommercialEventApplicationStatus;

  @Column({
    nullable: true,
    type: 'enum',
    enum: CommercialEventApplicationDecisionReason,
  })
  decisionReason!: CommercialEventApplicationDecisionReason | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn({ nullable: true, name: 'deletedAt', type: 'timestamp' })
  deletedAt!: Date | null;
}
