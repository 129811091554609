import { useMemo } from 'react';

import { Channel } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const useCellDefinition = () => {
  const CellChannelDefinition = useMemo(
    (): CellDefinition<Channel>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (channel) => ({ link: `/channel/${channel.id}`, label: channel?.id }),
      },
      {
        id: 'user',
        label: 'User',
        getLink: (channel) => ({
          link: `/user/${channel.userId}`,
          label: channel.user as unknown as string,
        }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },

      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return { CellChannelDefinition };
};
