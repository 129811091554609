import { FeaturedShowApplicationType } from '@bits-app/bits-server-data';
import { SSOProvider } from '@bits-app/react-sso';
import { CountryCodeIso2, Currency, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { Home } from '@/Home';
import { PageNotFound } from '@/_errors/views/404';
import { OwnUsersList } from '@/administration/ui/OwnUsersList';
import { Login } from '@/auth/ui';
import { ZendeskAuth } from '@/auth/ui/ZendeskAuth';
import { AuthenticatedRoute } from '@/components/elements/AuthenticatedRoute';
import { AuthorizedRoute } from '@/components/elements/AuthorizedRoute';
import { AuthenticatedLayout } from '@/components/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { OwnUserProvider } from '@/context/own-user.context';
import { useStore } from '@/hooks/use-store';
import { OrderItemsMassUpdate } from '@/order-items-mass-update/ui';
import { ListPartnerProgram } from '@/partner-program';
import { Promotions } from '@/promotions/ui/views';
import { queryClient } from '@/queries';
import { Refund, RefundOrderItem } from '@/refund/ui';
import { SellerCancelRequests } from '@/seller-cancel-requests/ui';
import { getEnv } from '@/utils';
import { ShippingFeeConfigCreationView } from '@/voggt-database-explorer/shipping-fee-config/views/create/ShippingFeeConfigCreationView';

import { AutoFollowUserCampaignTabs } from './auto-follow-user-campaign';
import { AutoFollowUserCampaignCreationView } from './auto-follow-user-campaign/create';
import { TypesenseContextProvider } from './context/typesense.context';
import { useRedirection } from './hooks/use-redirection';
import { MarketPageCreationView } from './market-pages/views/MarketPageCreationView';
import { MarketPageDetail } from './market-pages/views/detail';
import { ListMarketPages } from './market-pages/views/list/list';
import { MuxModal } from './mux/MuxModal';
import { MuxContextProvider } from './mux/mux.context';
import { CommercialEventApplicationList } from './pages/commercial-event-application';
import { FeaturedShowApplicationList } from './pages/featured-show-application';
import { FeedRecommendationView } from './pages/feed-recommendation';
import { LedgerPayoutView } from './pages/ledger';
import { MassImport } from './pages/mass-import';
import { SentinelDetail, SentinelList } from './pages/sentinel';
import {
  AVAILABLE_DATABASE_EXPLORER_MODULE_PATH,
  BadgeDetail,
  BlockedShippingAddressDetail,
  CategoryDetail,
  ChannelDetail,
  CommercialEventCreationView,
  CommercialEventDetail,
  CountryDetail,
  EmoteDetail,
  HardBlockUser,
  LanguageDetail,
  ListBadge,
  ListBlockedShippingAddress,
  ListCategory,
  ListChannel,
  ListCommercialEvent,
  ListCountry,
  ListEmote,
  ListLanguage,
  ListOrder,
  ListPaymentMethod,
  ListProduct,
  ListSellerNews,
  ListShipment,
  ListShippingAddress,
  ListShippingFeeConfig,
  ListShow,
  ListUsers,
  OrderDetail,
  PaymentMethodDetail,
  ProductDetail,
  SellerNewsCreationView,
  SellerNewsDetail,
  ShipmentDetail,
  ShippingAddressCreationView,
  ShippingAddressDetail,
  ShippingFeeConfigDetail,
  ShowDetail,
  ShowNotificationCreationView,
  UserProfile,
} from './voggt-database-explorer';
import { UserByDevice } from './voggt-database-explorer/user-by-device-id/ui/userByDevice';

const App = () => {
  const store = useStore();

  useRedirection();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MuxContextProvider>
          <OwnUserProvider>
            <TypesenseContextProvider>
              <Routes>
                <Route path="/" element={<AuthenticatedRouteLayout />}>
                  <Route index element={<Home />} />

                  <Route
                    path="/auth/zendesk"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.LINK_ZENDESK_ACCOUNT.WRITE_LINK_ZENDESK_ACCOUNT
                        }
                      />
                    }
                  >
                    <Route index element={<ZendeskAuth />} />
                  </Route>

                  <Route
                    path="refund"
                    element={<AuthorizedRoute authorizedModule={PERMISSIONS.REFUND.READ_REFUND} />}
                  >
                    <Route index element={<Refund />} />
                    <Route path=":id" element={<RefundOrderItem />} />
                  </Route>

                  <Route
                    path="/user-by-device-id"
                    element={
                      <AuthorizedRoute
                        authorizedModule={PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_USER}
                      />
                    }
                  >
                    <Route index element={<UserByDevice />} />
                  </Route>

                  <Route
                    path="/admin/members"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.ADMINISTRATION.READ_ADMINISTRATION_PERMISSIONS
                        }
                      />
                    }
                  >
                    <Route index element={<OwnUsersList />} />
                  </Route>

                  <Route
                    path="/promotions"
                    element={
                      <AuthorizedRoute authorizedModule={PERMISSIONS.PROMOTION.READ_PROMOTION} />
                    }
                  >
                    <Route path="eur" element={<Promotions currency={Currency.eur} />} />
                  </Route>

                  <Route
                    path="/partner-program"
                    element={
                      <AuthorizedRoute
                        authorizedModule={PERMISSIONS.PARTNER_PROGRAM.READ_PARTNER_PROGRAM}
                      />
                    }
                  >
                    <Route path="" element={<ListPartnerProgram />} />
                  </Route>

                  <Route>
                    <Route path="/market-pages" element={<ListMarketPages />} />
                    <Route path="/market-pages/:id/*" element={<MarketPageDetail />} />
                    <Route path="/market-pages/create" element={<MarketPageCreationView />} />
                  </Route>

                  <Route
                    path="/seller-cancel-requests"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.SELLER_CANCEL_REQUEST.READ_SELLER_CANCEL_REQUEST
                        }
                      />
                    }
                  >
                    <Route
                      path="eur/:country"
                      element={
                        <SellerCancelRequests
                          currency={Currency.eur}
                          countries={Object.values(CountryCodeIso2).filter(
                            (country) => country !== CountryCodeIso2.GB,
                          )}
                          defaultCountry={CountryCodeIso2.AT}
                        />
                      }
                    />
                    <Route
                      path="gbp/:country"
                      element={
                        <SellerCancelRequests
                          currency={Currency.gbp}
                          countries={Object.values(CountryCodeIso2).filter(
                            (country) => country === CountryCodeIso2.GB,
                          )}
                          defaultCountry={CountryCodeIso2.GB}
                        />
                      }
                    />
                  </Route>

                  <Route
                    path="/order-items-mass-update"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.ORDER_ITEM_MASS_UPDATE.READ_ORDER_ITEM_MASS_UPDATE
                        }
                      />
                    }
                  >
                    <Route path="default" element={<OrderItemsMassUpdate tab="default" />} />
                    <Route
                      path="payment-failed-above-1day"
                      element={<OrderItemsMassUpdate tab="payment-failed" />}
                    />
                    <Route
                      path="shipped-above-30days"
                      element={<OrderItemsMassUpdate tab="shipped" />}
                    />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.user}
                    element={
                      <AuthorizedRoute
                        authorizedModule={PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_USER}
                      />
                    }
                  >
                    <Route index element={<ListUsers />} />
                    <Route path=":id/hard-block-user" element={<HardBlockUser />} />
                    <Route path=":id/*" element={<UserProfile />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.show}
                    element={
                      <AuthorizedRoute
                        authorizedModule={PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHOW}
                      />
                    }
                  >
                    <Route index element={<ListShow />} />
                    <Route
                      path=":id/notifications-center/create"
                      element={<ShowNotificationCreationView />}
                    />
                    <Route path=":id/*" element={<ShowDetail />} />
                  </Route>
                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.product}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_PRODUCT
                        }
                      />
                    }
                  >
                    <Route index element={<ListProduct />} />
                    <Route path=":id/*" element={<ProductDetail />} />
                  </Route>
                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.shippingAddress}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPPING_ADDRESS
                        }
                      />
                    }
                  >
                    <Route index element={<ListShippingAddress />} />
                    <Route path=":id/create" element={<ShippingAddressCreationView />} />
                    <Route path=":id/*" element={<ShippingAddressDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.category}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_CATEGORY
                        }
                      />
                    }
                  >
                    <Route path="child/:id/*" element={<CategoryDetail type="child" />} />
                    <Route path="parent/:id/*" element={<CategoryDetail type="parent" />} />
                    <Route path="*" element={<ListCategory />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.channel}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_CHANNEL
                        }
                      />
                    }
                  >
                    <Route index element={<ListChannel />} />
                    <Route path=":id/*" element={<ChannelDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.country}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_COUNTRY
                        }
                      />
                    }
                  >
                    <Route index element={<ListCountry />} />
                    <Route path=":id/*" element={<CountryDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.order}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_ORDER
                        }
                      />
                    }
                  >
                    <Route index element={<ListOrder />} />
                    <Route path=":id/*" element={<OrderDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.badge}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_BADGE
                        }
                      />
                    }
                  >
                    <Route index element={<ListBadge />} />
                    <Route path="draft/:id/*" element={<BadgeDetail isDraft />} />
                    <Route path=":id/*" element={<BadgeDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.emote}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_EMOTE
                        }
                      />
                    }
                  >
                    <Route index element={<ListEmote />} />
                    <Route path=":id/*" element={<EmoteDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.language}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_LANGUAGE
                        }
                      />
                    }
                  >
                    <Route index element={<ListLanguage />} />
                    <Route path=":id/*" element={<LanguageDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.payment_method}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_PAYMENT_METHOD
                        }
                      />
                    }
                  >
                    <Route
                      path="card/:id/*"
                      element={<PaymentMethodDetail paymentMethodName="paymentMethodCard" />}
                    />
                    <Route
                      path="bancontact/:id/*"
                      element={<PaymentMethodDetail paymentMethodName="paymentMethodBancontact" />}
                    />
                    <Route
                      path="paypal/:id/*"
                      element={<PaymentMethodDetail paymentMethodName="paymentMethodPaypal" />}
                    />
                    <Route
                      path="giropay/:id/*"
                      element={<PaymentMethodDetail paymentMethodName="paymentMethodGiropay" />}
                    />
                    <Route
                      path="ideal/:id/*"
                      element={<PaymentMethodDetail paymentMethodName="paymentMethodIdeal" />}
                    />
                    <Route path="*" element={<ListPaymentMethod />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.blocked_shipping_address}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER
                            .READ_DATABASE_EXPLORER_BLOCKED_SHIPPING_ADDRESS
                        }
                      />
                    }
                  >
                    <Route index element={<ListBlockedShippingAddress />} />
                    <Route path=":id/*" element={<BlockedShippingAddressDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.shipment}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPMENT
                        }
                      />
                    }
                  >
                    <Route index element={<ListShipment />} />
                    <Route path=":id/*" element={<ShipmentDetail />} />
                  </Route>

                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.shipping_fee_config}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPPING_FEE_CONFIG
                        }
                      />
                    }
                  >
                    <Route index element={<ListShippingFeeConfig />} />
                    <Route path="create" element={<ShippingFeeConfigCreationView />} />
                    <Route path=":id/*" element={<ShippingFeeConfigDetail />} />
                  </Route>
                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.seller_news}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SELLER_NEWS
                        }
                      />
                    }
                  >
                    <Route index element={<ListSellerNews />} />
                    <Route path="create" element={<SellerNewsCreationView />} />
                    <Route path=":id/*" element={<SellerNewsDetail />} />
                  </Route>
                  <Route
                    path={AVAILABLE_DATABASE_EXPLORER_MODULE_PATH.commercial_event}
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_COMMERCIAL_EVENT
                        }
                      />
                    }
                  >
                    <Route index element={<ListCommercialEvent />} />
                    <Route path="create" element={<CommercialEventCreationView />} />
                    <Route path=":id/*" element={<CommercialEventDetail />} />
                  </Route>

                  <Route
                    path="auto-follow-user-campaign"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.AUTO_FOLLOW_USER_CAMPAIGN.READ_AUTO_FOLLOW_USER_CAMPAIGN
                        }
                      />
                    }
                  >
                    <Route path="create" element={<AutoFollowUserCampaignCreationView />} />
                    <Route path="*" element={<AutoFollowUserCampaignTabs />} />
                  </Route>

                  <Route
                    path="featured-show-application"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER
                            .READ_DATABASE_EXPLORER_FEATURED_SHOW_APPLICATION
                        }
                      />
                    }
                  >
                    <Route
                      path="*"
                      element={
                        <FeaturedShowApplicationList
                          applicationType={FeaturedShowApplicationType.featured}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path="premier-show-application"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER
                            .READ_DATABASE_EXPLORER_FEATURED_SHOW_APPLICATION
                        }
                      />
                    }
                  >
                    <Route
                      path="*"
                      element={
                        <FeaturedShowApplicationList
                          applicationType={FeaturedShowApplicationType.premier}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path="commercial-event-application"
                    element={
                      <AuthorizedRoute
                        authorizedModule={
                          PERMISSIONS.DATABASE_EXPLORER
                            .READ_DATABASE_EXPLORER_COMMERCIAL_EVENT_APPLICATION
                        }
                      />
                    }
                  >
                    <Route path="*" element={<CommercialEventApplicationList />} />
                  </Route>

                  <Route
                    path="feed-recommendation"
                    element={
                      <AuthorizedRoute
                        authorizedModule={PERMISSIONS.FEED_RECOMMANDATION.READ_FEED_RECOMMANDATION}
                      />
                    }
                  >
                    <Route index element={<FeedRecommendationView />} />
                  </Route>

                  <Route path="/play-mux/:assetId" element={<MuxModal />} />

                  <Route path="/sentinel" element={<SentinelList />} />
                  <Route path="/sentinel/:id" element={<SentinelDetail />} />

                  <Route path="/ledger-list-payout" element={<LedgerPayoutView />} />

                  <Route
                    path="/mass-import"
                    element={
                      <AuthorizedRoute
                        authorizedModule={PERMISSIONS.MASS_IMPORT.WRITE_MASS_IMPORT}
                      />
                    }
                  >
                    <Route index element={<MassImport />} />
                  </Route>
                  <Route path="*" element={<PageNotFound />} />
                </Route>

                <Route path="/login" element={<WithSSO />} />
              </Routes>
            </TypesenseContextProvider>
          </OwnUserProvider>
        </MuxContextProvider>
      </Provider>
    </QueryClientProvider>
  );
};

const AuthenticatedRouteLayout = () => (
  <AuthenticatedLayout>
    <AuthenticatedRoute />
  </AuthenticatedLayout>
);

const WithSSO = () => (
  <SSOProvider config={{ google: { clientId: getEnv('REACT_APP_GOOGLE_AUTH_CLIENT_ID') } }}>
    <Login />
  </SSOProvider>
);

export default App;
