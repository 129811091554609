import { useMemo } from 'react';

import type { Badge } from '@bits-app/bits-server-data';
import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { AlertBar } from '@/components/elements/AlertBar';
import { Dropdown } from '@/components/elements/Dropdown';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';
import { BadgeTranslationsPanel } from '@/voggt-database-explorer/badge/views/detail/BadgeTranslations.panel';
import { EditBadgeButton } from '@/voggt-database-explorer/badge/views/detail/edit-badge/EditBadgeButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ForestAdminLink } from '@Elements/ForestAdminLink';

import { DeleteBadgeModal } from './delete-badge/DeleteBadge.modal';
import { useDeleteBadge } from './delete-badge/use-delete-badge.hook';
import { PersistDraftBadgeModal } from './persist-badge/PersistDraftBadge.modal';
import { usePersistDraftBadge } from './persist-badge/use-persist-draft-badge.hook';
import { panelDetailsBadge } from './useBadgeDetailsView';

type DetailProps = {
  badge: Badge | DraftBadge;
  loading: boolean;
  badgeRelatedUsersCount?: number;
  isDraft?: boolean;
};

export const Detail = ({ badge, loading, badgeRelatedUsersCount, isDraft }: DetailProps) => {
  const badgeId = useIdParam();
  const { t } = useTranslation();

  const deleteBadge = useDeleteBadge(isDraft);
  const persistDraftBadge = usePersistDraftBadge();

  const itemsMenuList = useMemo(
    () => [
      {
        forbidden: !deleteBadge.ownUserIsAllowedToDeleteBadge || Boolean(badgeRelatedUsersCount),
        onClick: deleteBadge.onOpen,
        key: 'databaseExplorer.badge.delete.action',
      },
      {
        forbidden: !persistDraftBadge.ownUserIsAllowedToPersistDraftBadge || !isDraft,
        onClick: persistDraftBadge.onOpen,
        key: 'databaseExplorer.badge.persist.action',
      },
    ],
    [
      badgeRelatedUsersCount,
      deleteBadge.onOpen,
      deleteBadge.ownUserIsAllowedToDeleteBadge,
      isDraft,
      persistDraftBadge.onOpen,
      persistDraftBadge.ownUserIsAllowedToPersistDraftBadge,
    ],
  );

  const badgeRelatedUsersCountIsLoading = !isDraft && badgeRelatedUsersCount === undefined;

  if (!badgeId || badgeRelatedUsersCountIsLoading) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          {isDraft && (
            <Box pr={1} pl={1} flex={1} justifyContent={'center'}>
              <AlertBar title={t('databaseExplorer.badge.isDraft')} />
            </Box>
          )}

          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <EditBadgeButton badge={badge} isDraft={isDraft} />

            <ForestAdminLink entity="badge" entityId={badgeId} />

            <Dropdown
              title="Actions"
              options={itemsMenuList.map((item) => ({
                onClick: () => item.onClick && item.onClick(),
                forbidden: item.forbidden,
                label: t(item.key),
              }))}
            />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<Badge | DraftBadge>
              title={t('databaseExplorer.panel.badge')}
              information={panelDetailsBadge}
              data={badge}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <BadgeTranslationsPanel badge={badge} />
          </Grid>
        </Grid>
      </Grid>

      {deleteBadge.isOpen && (
        <DeleteBadgeModal
          badge={badge}
          onClose={deleteBadge.onClose}
          onDelete={deleteBadge.onDeleteBadge}
          isLoading={deleteBadge.isLoading}
        />
      )}

      {persistDraftBadge.isOpen && isDraft && (
        <PersistDraftBadgeModal
          onClose={persistDraftBadge.onClose}
          draftBadge={badge as DraftBadge}
          isLoading={persistDraftBadge.isLoading}
          onPersist={persistDraftBadge.onPersist}
        />
      )}
    </DetailsLayout>
  );
};
