import * as yup from 'yup';

import { SchemaValidation } from './validation-schema';

export class CommercialEventValidationSchema extends SchemaValidation {
  availableResolver = {
    description: this.resolveDescription(),
    name: this.resolveName(),
    weight: yup.number().required(),
    startAt: this.resolveStartAt(),
    endAt: this.resolveEndAt(),
  };

  resolveDescription(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveName(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveStartAt(): yup.ISchema<any> {
    return yup.date().required();
  }

  resolveEndAt(): yup.ISchema<any> {
    return yup.date().required();
  }
}
