import type { Currency } from '../currency.enum';

export type UserBalance = {
  availableBalance: number;
  pendingBalance: number;
};

export type TransferFundType = 'bank-account' | 'stripe-account';

export type TransferFundPayload = {
  amount: number;
  currency: Currency;
  description: string;
  userId: number;
  transferType: TransferFundType;
  transferReason: TransferReason;
  shipmentId?: string;
  orderId?: number;
  showId?: number;
  relatedMonth?: string;
};

export enum TransferReason {
  secondShipping = 'secondShipping',
  extraShipping = 'extraShipping',
  refundMistake = 'refundMistake',
  careCommercialGesture = 'careCommercialGesture',
  careInsuranceReimbursement = 'careInsuranceReimbursement',
  commercialCampaigns = 'commercialCampaigns',
  variableBonus = 'variableBonus',
  marketingBuyersActivation = 'marketingBuyersActivation',
  marketingCampaigns = 'marketingCampaigns',
  fixedBonus = 'fixedBonus',
  shippingLabelsOverweightCharges = 'shippingLabelsOverweightCharges',
  commissionCorrection = 'commissionCorrection',
  financialAdjustement = 'financialAdjustement',
}

export const TransferReasonTranslation: Record<TransferReason, string> = {
  secondShipping: 'Second Shipping',
  extraShipping: 'Extra Shipping',
  refundMistake: 'Refund Mistake',
  shippingLabelsOverweightCharges: 'Shipping labels: overweight charges',
  marketingCampaigns: 'Marketing - marketing campaigns',
  commercialCampaigns: 'Commercial - commercial campaigns',
  marketingBuyersActivation: 'Marketing - buyers activation (HH)',
  careCommercialGesture: 'Care - commercial gesture',
  careInsuranceReimbursement: 'Care - insurance reimbursement',
  variableBonus: 'Variable Bonus',
  fixedBonus: 'Fixed Bonus',
  commissionCorrection: 'Commission Correction',
  financialAdjustement: 'Financial Adjustement',
} as const;

const TransferReasonExtraMetadata = {
  [TransferReason.secondShipping]: {
    financeCategory: 'User experience',
    invoicingCategory: 'Adjustments',
  },
  [TransferReason.extraShipping]: {
    financeCategory: 'User experience',
    invoicingCategory: 'Adjustments',
  },
  [TransferReason.refundMistake]: {
    financeCategory: 'User experience',
    invoicingCategory: 'Adjustments',
  },
  [TransferReason.careCommercialGesture]: {
    financeCategory: 'User experience',
    invoicingCategory: 'Adjustments',
  },
  [TransferReason.marketingBuyersActivation]: {
    financeCategory: 'Marketing costs',
    invoicingCategory: 'Marketing',
  },
  [TransferReason.marketingCampaigns]: {
    financeCategory: 'Marketing costs',
    invoicingCategory: 'Marketing',
  },
  [TransferReason.commercialCampaigns]: {
    financeCategory: 'Commercial costs',
    invoicingCategory: 'Commercial',
  },
  [TransferReason.variableBonus]: {
    financeCategory: 'Commercial costs',
    invoicingCategory: 'Variable bonus',
  },
  [TransferReason.fixedBonus]: {
    financeCategory: 'Commercial costs',
    invoicingCategory: 'Fixed bonus',
  },
  [TransferReason.commissionCorrection]: {
    financeCategory: 'Commission refund',
    invoicingCategory: 'Commission correction',
  },
  [TransferReason.financialAdjustement]: {
    financeCategory: 'Financial adjustment',
    invoicingCategory: 'Adjustments',
  },
  [TransferReason.shippingLabelsOverweightCharges]: {
    financeCategory: 'Financial adjusments',
    invoicingCategory: 'Adjusments',
  },
  [TransferReason.careInsuranceReimbursement]: {
    financeCategory: 'Care',
    invoicingCategory: 'Insurance reimbursement',
  },
};

export function getMetadataRelatedToTransfer(transferReason: TransferReason) {
  return TransferReasonExtraMetadata[transferReason] || {};
}
