import { useCallback, useMemo, useRef } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Stack, Typography, Button, IconButton } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import MaterialReactTable, {
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFilters } from '@/components/elements/DataList/hooks/use-filters.hook';
import { useSearch } from '@/components/elements/DataList/hooks/use-search.hook';
import { useTableOptions } from '@/hooks/use-table-options';

import { BasicRowData, rowIsDeleted } from '../Table';

import type { DataListType } from './datalist-type';
import { useMaterialReactTableBridge } from './hooks/use-material-react-table-bridge.hook';
import { usePagination } from './hooks/use-pagination.hook';

export const MAX_HEIGHT_ROW = 67;

const DEFAULT_RESULT_PER_PAGE = [50, 100, 200];

export function DataList<T extends { id: string | number }>({
  cellDefinition,
  results,
  searchAPI,
  paginationAPI,
  filtersAPI,
  children,
  getPathDetail,
  onClickOnRow,
  enableFilters = false,
  enableGlobalFilter = true,
  title,
  defaultSortBy,
  renderRowActions,
  defaultHiddenColumns,
  enableRowSelection,
  enableHiding = true,
  customSelectedRowsAction,
  allowMRTExport = false,
  enableSorting = false,
  onRefresh,
  showGlobalFilter = true,
  disableScrollToTop = false,
}: DataListType<T>) {
  const { t } = useTranslation();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    if (disableScrollToTop) return;

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({ top: 0 });
    }
  }, [disableScrollToTop]);

  const { options, onChangeLimit, onChangePage, onChangeSearch, setFilters } = useTableOptions(
    paginationAPI?.defaultResultPerPage ?? 50,
  );

  const [pagination, onPaginationChange] = usePagination({
    options,
    resultsCount: results.length,
    onChangeLimit,
    onChangePage,
    paginationAPI,
    scrollToTop,
  });
  const [search, onSearchChange] = useSearch({ options, onChangeSearch, scrollToTop, searchAPI });
  const [filters, onColumnFiltersChange] = useFilters({
    options,
    setFilters,
    filtersAPI,
    scrollToTop,
  });

  const navigate = useNavigate();

  const handleOnClickOnRow = (data: T) => {
    if (onClickOnRow) {
      onClickOnRow?.(data);
    } else {
      const path = getPathDetail?.(data);
      path && navigate(path);
    }
  };

  const columns = useMaterialReactTableBridge(cellDefinition);

  const rowsPerPageOptions = useMemo(() => {
    if (!paginationAPI?.defaultResultPerPage || paginationAPI.defaultResultPerPage === -1) {
      return DEFAULT_RESULT_PER_PAGE;
    }

    return [...new Set([...DEFAULT_RESULT_PER_PAGE, paginationAPI.defaultResultPerPage])].sort();
  }, [paginationAPI]);

  const onExportToCSV = (results: T[]) => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(results);
    download(csvConfig)(csv);
  };

  return (
    <>
      {title && (
        <Box pl={2} pt={2} pb={2} sx={{ backgroundColor: '#FCFCFC' }}>
          <Typography variant={'h5'}>{title}</Typography>
        </Box>
      )}
      <MaterialReactTable
        manualFiltering={searchAPI?.manualFiltering}
        onGlobalFilterChange={onSearchChange}
        columns={columns}
        data={results}
        renderRowActions={renderRowActions}
        enableRowActions={Boolean(renderRowActions)}
        enableFilterMatchHighlighting={false}
        muiSearchTextFieldProps={{
          variant: 'outlined',
        }}
        positionGlobalFilter="left"
        enableGlobalFilterModes
        enableGlobalFilter={enableGlobalFilter}
        enableFullScreenToggle={false}
        enableSorting={enableSorting}
        enableFilters={enableFilters}
        enableDensityToggle={false}
        enableColumnResizing
        enableStickyHeader
        enableRowSelection={(row) => {
          if (typeof enableRowSelection === 'boolean') {
            return enableRowSelection;
          }
          if (typeof enableRowSelection === 'function') {
            return enableRowSelection({ original: row.original });
          }
          return false;
        }}
        rowCount={paginationAPI?.rowCount}
        manualPagination={Boolean(paginationAPI)}
        onPaginationChange={onPaginationChange}
        onColumnFiltersChange={onColumnFiltersChange}
        positionToolbarAlertBanner="none"
        state={{
          pagination,
          globalFilter: search,
          ...(filters.length > 0 ? { columnFilters: filters } : undefined),
        }}
        initialState={{
          globalFilter: search,
          pagination,
          showGlobalFilter,
          sorting: defaultSortBy ? [defaultSortBy] : [],
          density: 'compact',
          columnVisibility: defaultHiddenColumns ?? {},
          ...(filters.length > 0 ? { columnFilters: filters } : undefined),
        }}
        muiTableContainerProps={{
          sx: { flex: 1 },
          ref: tableContainerRef,
        }}
        muiTablePaperProps={{
          sx: {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: ({ palette }) => palette.background.paper,
          },
          elevation: 0,
        }}
        muiTableHeadCellProps={{
          sx: {
            '& .Mui-TableHeadCell-Content': {
              justifyContent: 'space-between',
            },
          },
        }}
        muiTopToolbarProps={{
          sx: {
            '& .MuiBox-root': {
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 2,
            },
          },
        }}
        renderTopToolbarCustomActions={({ table }) => {
          const { rows } = table.getSelectedRowModel();
          const _rows = rows.map((r) => ({ original: r.original }));
          return (
            <Box flex={1} flexDirection={'row'}>
              {_rows.length ? (
                <>
                  <Typography variant="caption" fontStyle="italic">
                    {t('selectedRowsCount', { count: _rows.length })}
                  </Typography>
                  {customSelectedRowsAction?.(_rows)}
                </>
              ) : null}
            </Box>
          );
        }}
        renderToolbarInternalActions={({ table }) => (
          <Stack direction={'row'}>
            {children}
            {allowMRTExport ? (
              <Button
                sx={{ marginLeft: 'auto' }}
                variant="outlined"
                onClick={() => onExportToCSV(results)}
              >
                {t('action.exportAsCsv')}
              </Button>
            ) : null}
            {onRefresh && (
              <IconButton title={t('action.refresh')} onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            )}
            {enableHiding && <MRT_ShowHideColumnsButton table={table} />}
            {enableFilters && <MRT_ToggleFiltersButton table={table} />}
          </Stack>
        )}
        muiTablePaginationProps={{
          rowsPerPageOptions,
          showFirstButton: false,
          showLastButton: false,
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleOnClickOnRow(row.original),
          sx: {
            cursor: 'pointer',
            backgroundColor: rowIsDeleted(row as unknown as Row<BasicRowData>)
              ? '#d1d5db'
              : 'inherit',
            opacity: rowIsDeleted(row as unknown as Row<BasicRowData>) ? 0.6 : 1,
          },
        })}
      />
    </>
  );
}
