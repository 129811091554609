import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { AuthenticationLogs } from './AuthenticationLogs';
import { AuthenticationToken } from './AuthenticationToken';
import { Badge } from './Badge';
import { Channel } from './Channel';
import { Country } from './Country';
import { Language } from './Language';
import { Order } from './Order';
import { PaymentMethodCard } from './PaymentMethodCard';
import { SellerCompany } from './SellerCompany';
import { SellerConfig } from './SellerConfig';
import { ShippingAddress } from './ShippingAddress';
import { Show } from './Show';
import { TermsAndConditionsAcceptance } from './TermsAndConditionsAcceptance';
import { UserBannedFromSeller } from './UserBannedFromSeller';
import { UserBannedFromShow } from './UserBannedFromShow';
import { UserDataConsent } from './UserDataConsent';
import { UserFollowCategory } from './UserFollowCategory';
import { UserFollowProduct } from './UserFollowProduct';
import { UserFollowShow } from './UserFollowShow';
import { UserFollowUser } from './UserFollowUser';
import { UserPromoteModerator } from './UserModerateForUser';
import { UserSso } from './UserSso';
import { UserTargetPromotion } from './UserTargetPromotion';

export const DEFAULT_FIXED_FEES = 0;
export const DEFAULT_PERCENTAGE_FEES = 8.9;

@Entity()
export class User {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ nullable: true })
  firstName!: string;

  @Column({ nullable: true })
  lastName!: string;

  @Column({ type: 'timestamp', nullable: true })
  birthdate!: Date;

  @Column({ nullable: true, type: 'character varying', select: false })
  @Index('email_lower_idx', { synchronize: false })
  @Index('email_gin_idx', { synchronize: false })
  email!: string;

  @Column({ nullable: true, type: 'character varying' })
  @Index('email_alias_lower_idx', { synchronize: false })
  emailAlias!: string | null;

  @Column({ nullable: true })
  biography!: string;

  @Column({ nullable: true })
  @Index('username_upper_idx', { synchronize: false })
  @Index('username_gin_idx', { synchronize: false })
  username!: string;

  @Column({ nullable: true })
  language!: string;

  @Column({ nullable: true })
  coverUrl!: string;

  @Column({ nullable: true, type: 'character varying' })
  avatarUrl!: string | null;

  @Column({ type: 'boolean', default: false, select: false })
  isEmailVerified!: boolean;

  @Column({ type: 'boolean', default: false })
  isSeller!: boolean;

  @Column({ type: 'boolean', default: true })
  isSelected!: boolean;

  @Column({ select: false, type: 'boolean', default: false })
  isInfluencer!: boolean;

  @Column({ select: false, type: 'boolean', default: false })
  isStaff!: boolean;

  // TODO: migrate to feature flags
  @Column({ select: false, type: 'boolean', default: true })
  isBancontactEligible!: boolean;

  // TODO: migrate to feature flags
  @Column({ select: false, type: 'boolean', default: false })
  isGiropayEligible!: boolean;

  // TODO: migrate to feature flags
  @Column({ select: false, type: 'boolean', default: false })
  isPaypalEligible!: boolean;

  // TODO: migrate to feature flags
  @Column({ select: false, type: 'boolean', default: true })
  isPaypalRecurringPaymentEligible!: boolean;

  // TODO: migrate to feature flags
  @Column({ select: false, type: 'boolean', default: true })
  isApplePayEligible!: boolean;

  // TODO: migrate to feature flags
  @Column({ select: false, type: 'boolean', default: true })
  isGooglePayEligible!: boolean;

  @Column({ select: false, type: 'boolean', default: true })
  isPersonalizedProductFeedEligible!: boolean;

  @Column({ select: false, type: 'boolean', default: false })
  isSharedPaymentMethodEligible!: boolean;

  @Column({ select: false, type: 'boolean', default: true })
  isPreAuthorizationRequiredForHighProfileShows!: boolean;

  /**
   * Allows care team to let user recreate their account and bypassing the days limitation after account deletion
   */
  @Column({ select: false, type: 'boolean', default: false })
  canRecreateAccount!: boolean;

  /**
   * @deprecated in favor of UserClientSessionService.getUserPlatformAndVersion(user)
   */
  @Column({ select: false, nullable: true, type: 'character varying' })
  platform!: string | null;

  /**
   * @deprecated in favor of UserClientSessionService.getUserPlatformAndVersion(user)
   */
  @Column({ select: false, nullable: true, type: 'character varying' })
  version!: string | null;

  @Column({ nullable: true, select: false })
  password!: string;

  @Column({ nullable: true })
  @Index('referralCode_upper_idx', { synchronize: false })
  @Index('referralCode_gin_idx', { synchronize: false })
  referralCode!: string;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country | null;

  @Column({ nullable: true })
  countryId!: Country['id'] | null;

  @ManyToOne(() => User, {})
  @JoinColumn({ name: 'referrerId' })
  referrer!: User;

  @Index()
  @Column({ nullable: true })
  referrerId!: number;

  @Index('phone_number_gin_idx', { synchronize: false })
  @Column({ unique: true, nullable: true, select: false })
  phoneNumber!: string;

  @Index()
  @Column({ nullable: true, select: false })
  resetPasswordToken!: string;

  @Column({ nullable: true, select: false })
  notificationToken!: string;

  @Column({ type: 'boolean', default: false, select: false })
  isPhoneNumberVerified!: boolean;

  @Index()
  @Column({ nullable: true, select: false })
  stripeCustomerId!: string;

  @Index()
  @Column({ nullable: true, select: false })
  stripeAccountId!: string;

  /**
   * Seller's fixed fee to be applied to orders from their customers
   */
  @Column({ select: false, default: 0 })
  fixedFee!: number;

  /**
   * Seller's percentage fee to be applied to orders from their customers
   */
  @Column({ select: false, default: DEFAULT_PERCENTAGE_FEES, type: 'float' })
  percentageFee!: number;

  @Column({ nullable: true, select: false, default: null })
  maxAmountFee!: number;

  @Column({ nullable: true, select: false, default: null })
  minAmountFee!: number;

  @Column({ nullable: true, type: 'int4' })
  overrideBuyerServiceFeeAmount!: number | null;

  @Column({ select: false, type: 'timestamp', nullable: true })
  blockedCommentUntil!: Date;

  static isBlockedFromCommenting(
    user: Pick<User, 'isHardBlocked' | 'blockedCommentUntil'>,
  ): boolean {
    return (
      user.isHardBlocked ||
      (!!user.blockedCommentUntil &&
        user.blockedCommentUntil.getTime() > Date.now())
    );
  }

  @Column({ select: false, type: 'timestamp', nullable: true })
  blockedAuctionUntil!: Date;

  static isBlockedFromBidding(
    user: Pick<User, 'isHardBlocked' | 'blockedAuctionUntil'>,
  ): boolean {
    return (
      user.isHardBlocked ||
      (!!user.blockedAuctionUntil &&
        user.blockedAuctionUntil.getTime() > Date.now())
    );
  }

  @Column({ default: false })
  isHardBlocked!: boolean;

  @Column({ default: false })
  isBanned!: boolean;

  @Column({ default: false })
  isNewBanFeatureEligible!: boolean;

  // TODO: remove during next release
  @Column({ select: false, default: false })
  isAutoplayFirstShowEligible!: boolean;

  @Column({ select: false, default: false })
  isSupplierEligible!: boolean;

  @Column({ select: false, type: 'timestamp', nullable: true })
  lastOpenAppAt!: Date;

  @Column({ name: 'sellerCreatedAt', type: 'timestamp', nullable: true })
  sellerCreatedAt!: Date;

  @Column({ type: 'smallint', default: 5 })
  sellerScore!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp', select: false })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;

  /**
   * Mark that the user deleted its account.
   */
  @Column({ type: 'timestamp', nullable: true })
  accountDeletedAt!: Date | null;

  @OneToMany(() => AuthenticationLogs, (authLog) => authLog.user)
  authenticationLogs!: AuthenticationLogs[];

  @OneToMany(() => AuthenticationToken, (authToken) => authToken.user)
  authenticationTokens!: AuthenticationToken[];

  @OneToOne(() => Channel, (channel) => channel.user)
  channel!: Channel;

  @OneToOne(() => SellerConfig, (sellerConfig) => sellerConfig.user)
  sellerConfig?: SellerConfig;

  @OneToOne(() => SellerCompany, (sellerCompany) => sellerCompany.seller)
  sellerCompany?: SellerCompany;

  @OneToMany(() => ShippingAddress, (shippingAddress) => shippingAddress.user)
  shippingAddresses!: ShippingAddress[];

  // TODO: remove along with /me routes
  @OneToMany(
    () => PaymentMethodCard,
    (paymentMethodCard) => paymentMethodCard.user,
  )
  paymentMethodCards!: PaymentMethodCard[];

  @OneToMany(() => User, (user) => user.referrer)
  referrals!: User[];

  @OneToMany(() => UserPromoteModerator, (upm) => upm.user)
  userPromoteModerators!: UserPromoteModerator[];

  @OneToMany(() => Show, (show) => show.user)
  shows!: Show[];

  @ManyToMany(() => Badge)
  @JoinTable()
  badges!: Badge[];

  @OneToMany(() => UserFollowCategory, (ufc) => ufc.user)
  userFollowCategory!: UserFollowCategory[];

  @OneToMany(() => Order, (order) => order.customer)
  orders!: Order[];

  @ManyToMany(() => Language, (language) => language.followedBy)
  @JoinTable({
    name: 'user_follow_language',
    joinColumn: { name: 'userId' },
    inverseJoinColumn: { name: 'languageId' },
  })
  followedLanguages!: Language[];

  @Column({ nullable: true, type: 'character varying' })
  favouritePickupPoint!: string | null;

  @OneToMany(() => UserFollowUser, (ufu) => ufu.user)
  followedUsersRelations!: UserFollowUser[];

  @OneToMany(() => UserFollowUser, (ufu) => ufu.seller)
  followedByUsersRelations!: UserFollowUser[];

  @OneToMany(
    () => UserTargetPromotion,
    (userTargetPromotion) => userTargetPromotion.user,
  )
  userTargetPromotions!: UserTargetPromotion[];

  @OneToOne(() => UserSso, (userSso) => userSso.user)
  userSso!: UserSso | null;

  @OneToMany(
    () => TermsAndConditionsAcceptance,
    (acceptance) => acceptance.user,
  )
  termsAndConditionsAcceptances!: TermsAndConditionsAcceptance[];

  @OneToMany(() => UserFollowProduct, (ufp) => ufp.user)
  userFollowProduct!: UserFollowProduct[];

  @OneToMany(() => UserFollowShow, (ufp) => ufp.user)
  followedShowsRelations!: UserFollowShow[];

  @OneToMany(() => UserBannedFromShow, (ubfs) => ubfs.user)
  bannedFromShowsRelations!: UserBannedFromShow[];

  @OneToMany(() => UserBannedFromSeller, (ubfs) => ubfs.user)
  bannedFromSellersRelations!: UserBannedFromSeller[];

  @OneToMany(() => UserBannedFromSeller, (ubfs) => ubfs.seller)
  bannedUsersRelations!: UserBannedFromSeller[];

  @OneToOne(() => UserDataConsent, (udc) => udc.user)
  dataConsent!: UserDataConsent | null;

  static isAuthenticatedThroughSso(user: Pick<User, 'userSso'>): boolean {
    return !!user.userSso;
  }

  static isAccountFinalized(user: Pick<User, 'stripeCustomerId'>): boolean {
    return !!user.stripeCustomerId;
  }

  static isAccountDeleted(user: Pick<User, 'accountDeletedAt'>): boolean {
    return !!user.accountDeletedAt;
  }
}
