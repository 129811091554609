import { UserDataConsent } from '@bits-app/bits-server-data';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParamAsNumber } from '@/hooks/use-id-params';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

import { useDataConsentByUserId } from '../../hooks/useDataConsentByUserId';

import { sellerDataConsentDetail, userDataConsentDetail } from './const/data-consent-panel';

type DataConsentPanelProps = {
  isUserSeller: boolean;
};

export const DataConsentPanel = ({ isUserSeller }: DataConsentPanelProps) => {
  const userId = useIdParamAsNumber();
  const userDataConsentByUserIdHook = useDataConsentByUserId(userId);

  const userDataConsent = userDataConsentByUserIdHook.data?.data;

  if (!userDataConsent) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid spacing={2} container>
        <Grid
          xs={12}
          display="flex"
          rowGap={2}
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Grid md={12} xs={12}>
            <Grid sx={{ mb: 2, p: { xs: 0 } }}>
              <PanelDetails<UserDataConsent>
                loading={userDataConsentByUserIdHook.isLoading}
                title={'User data consent'}
                information={userDataConsentDetail}
                data={userDataConsent}
                labelRow
              />
            </Grid>
          </Grid>
          {isUserSeller && (
            <Grid md={12} xs={12}>
              <Grid sx={{ mb: 2, p: { xs: 0 } }}>
                <PanelDetails<UserDataConsent>
                  loading={userDataConsentByUserIdHook.isLoading}
                  title={'Seller data consent'}
                  information={sellerDataConsentDetail}
                  data={userDataConsent}
                  labelRow
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
