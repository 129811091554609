import type { ShippingFeeConfig } from '@bits-app/bits-server-data';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ForestAdminLink } from '@Elements/ForestAdminLink';

import { panelDetailsShippingFeeConfig } from './useShippingFeeConfigDetailsView';

type DetailProps = {
  shippingFeeConfig: ShippingFeeConfig;
  loading: boolean;
  isReadOnly: boolean;
};

export const Detail = ({ shippingFeeConfig, loading, isReadOnly }: DetailProps) => {
  const shippingFeeConfigId = useIdParam();
  const { t } = useTranslation();

  if (!shippingFeeConfigId) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={2} direction="row" ml={'auto'} pr={1} pl={1} alignItems="center">
            {!isReadOnly && <EditButton to={`/shipping-fee-config/${shippingFeeConfigId}/edit`} />}
            <ForestAdminLink entity="shippingFeeConfig" entityId={shippingFeeConfigId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<ShippingFeeConfig>
              title={t('databaseExplorer.panel.shippingFeeConfig')}
              information={panelDetailsShippingFeeConfig}
              data={shippingFeeConfig}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
