import { useReducer, useRef } from 'react';

import { CommercialEventApplicationDecisionReason } from '@bits-app/bits-server-data';
import { CommercialEventApplicationWithData } from '@bits-app/voggtpit-shared';
import { MoreVert } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  DialogContentText,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { authenticatedInstance } from '@/axios/axios.instance';
import { keepOnlyValidReasonBasedOnDecision } from '@/pages/show-event-application-utils/helper';

import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../queries';

import { ApplicationForm } from './CommercialEventApplicationRowAction';

export const BulkUpdateCommercialEventApplication = ({
  rows,
}: {
  rows: CommercialEventApplicationWithData[];
}) => {
  const snackbar = useSnackbar();
  const { handleSubmit, control, watch } = useForm<ApplicationForm>();
  const [menuIsOpen, toggleMenu] = useReducer((p) => !p, false);
  const ref = useRef(null);

  const { mutate } = useMutation({
    mutationFn: (input: ApplicationForm) => {
      return authenticatedInstance.post(
        `database-explorer/commercial-event/applications/decision`,
        {
          ...input,
          commercialEventApplicationIds: rows.map((r) => r.id),
        },
      );
    },
    onSuccess: () => {
      snackbar.success('Application updated');
      toggleMenu();
      queryClient.invalidateQueries(QUERIES_KEYS.commercialEventApplicationList());
      queryClient.invalidateQueries(QUERIES_KEYS.commercialEventApplicationList('pending'));
    },
  });

  return (
    <>
      <IconButton onClick={toggleMenu} ref={ref}>
        <MoreVert />
      </IconButton>

      <Dialog open={menuIsOpen}>
        <DialogTitle>Answer to {rows.length} applications. </DialogTitle>
        <DialogContent className="flex flex-col gap-4">
          <DialogContentText>
            All the applications will get the same answer, be careful
          </DialogContentText>
          <Controller
            name="decision"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="bulk-update-commercial-event-application-answer-label">
                  Answer
                </InputLabel>
                <Select
                  labelId="bulk-update-commercial-event-application-answer-label"
                  {...field}
                  label="Answer"
                >
                  <MenuItem key={'approved'} value={'approved'}>
                    Accept
                  </MenuItem>
                  <MenuItem key={'refused'} value={'refused'}>
                    Reject
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="bulk-update-commercial-event-application-reason-label">
                  Reason
                </InputLabel>
                <Select
                  labelId="bulk-update-commercial-event-application-reason-label"
                  {...field}
                  label="Reason"
                >
                  {Object.entries(CommercialEventApplicationDecisionReason)
                    .filter(keepOnlyValidReasonBasedOnDecision(watch('decision')))
                    .map(([key, label]) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            onClick={handleSubmit((d) => mutate(d))}
            disabled={!watch('decision') || !watch('reason')}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
