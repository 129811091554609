import { Show, Product } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';

import { authenticatedInstance } from '../../axios/axios.instance';

export type FeedRecommendationForm = {
  recommendationType: 'show-user' | 'show-device' | 'product-user' | 'product-device';
  identifier?: string;
  eventId?: string;
  categoryIds?: string[];
  isOnlyFromLiveShows?: boolean;
  sortByPrice?: 'ASC' | 'DESC';
  refresh: boolean;
};

export type RecommendationResponse = {
  shows?: Show[];
  products?: Product[];
  paginationMetadata: { totalCount: number; hasNextPage: boolean };
};

type QueryKey = [
  string,
  FeedRecommendationForm['recommendationType'],
  FeedRecommendationForm['identifier'],
  FeedRecommendationForm['eventId'],
  FeedRecommendationForm['categoryIds'],
  FeedRecommendationForm['refresh'],
];

export const useFeedRecommendation = () => {
  const { register, watch, control } = useForm<FeedRecommendationForm>({
    defaultValues: {
      identifier: undefined,
      recommendationType: 'show-user',
      eventId: undefined,
      isOnlyFromLiveShows: undefined,
      sortByPrice: undefined,
      categoryIds: [],
      refresh: false,
    },
  });

  const { refetch, data } = useQuery<
    unknown,
    unknown,
    AxiosResponse<{
      ids: number[];
      paginationMetadata: { totalCount: number; hasNextPage: boolean };
    }>,
    QueryKey
  >(
    [
      'recommendation',
      watch('recommendationType'),
      watch('identifier'),
      watch('eventId'),
      watch('categoryIds'),
      watch('refresh'),
    ],
    {
      queryFn: ({ queryKey }) => {
        const [, recommendationType, identifier, eventId, categoryIds, refresh] = queryKey;
        return getRecommendation({
          recommendationType: recommendationType,
          identifier,
          eventId,
          categoryIds,
          refresh,
        });
      },
      enabled: false,
    },
  );

  return {
    register,
    refetch,
    control,
    watch,
    data,
    disableSubmit: !watch('identifier'),
  };
};

function resolveUrl(recommendationType: FeedRecommendationForm['recommendationType']) {
  switch (recommendationType) {
    case 'show-user':
      return '/data-recommendation/show/user';
    case 'show-device':
      return '/data-recommendation/show/device';
    case 'product-user':
      return '/data-recommendation/product/user';
    case 'product-device':
      return '/data-recommendation/product/device';
  }
}

function getRecommendation(params: FeedRecommendationForm) {
  const url = resolveUrl(params.recommendationType);
  const uri = authenticatedInstance.getUri({ url, params });
  return authenticatedInstance.get<unknown, RecommendationResponse>(
    `${url}/${params.identifier}?${uri}`,
  );
}
