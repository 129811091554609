import {
  RefundReason,
  RefundDebitedAccount,
  Currency,
  PaymentStatus,
  add,
  minus,
} from '@bits-app/voggtpit-shared';
import { createSelector } from '@reduxjs/toolkit';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { RootState } from '@/redux/store';

import { RefundState } from './refund.slice';

const refundSelector = (state: RootState) => state.refund;

export const selectMainOrderItemId = createSelector(
  refundSelector,
  (refund: RefundState) => refund.mainOrderItemId,
);

export const selectMainOrderItem = createSelector(refundSelector, (refund: RefundState) => {
  const orderItems = refund.orderItems;

  return orderItems.find(({ id }) => id === refund.mainOrderItemId);
});

export const selectIsProcessingRefund = createSelector(
  selectMainOrderItemId,
  (mainOrderItemId: number | null) => mainOrderItemId !== null,
);

export const selectOrderItems = createSelector(
  refundSelector,
  (refund: RefundState) => refund.orderItems,
);

export const selectOrderItemFromId = createSelector(
  [selectOrderItems, (_, orderItemId) => orderItemId],
  (orderItems, orderItemId) => {
    if (!orderItemId) {
      throw new Error('Missing order item id');
    }

    return orderItems.find(({ id }) => orderItemId === id);
  },
);

export const selectIsRefundLoading = createSelector(
  refundSelector,
  (refund: RefundState) => refund.loading,
);

export const selectRefundError = createSelector(
  refundSelector,
  (refund: RefundState) => refund.error,
);

export const selectIsOrderItemRefundable = (state: RootState, orderItem: OrderItemRefund) => {
  return (
    !selectOrderItemIsCompletelyRefunded(state, orderItem.id) &&
    orderItem.paymentStatus === PaymentStatus.success
  );
};

export const selectAreAllRefundableOrderItemsInTheRefund = (state: RootState) => {
  const refund = state.refund;

  if (refund.orderItems.length === 0) {
    return false;
  }

  return (
    refund.orderItems.filter((orderItem) => selectIsOrderItemRefundable(state, orderItem))
      .length === Object.keys(refund.refundForm.orderItems).length
  );
};

export const selectAllRefundableOrderItems = (state: RootState) => {
  const refund = state.refund;

  return refund.orderItems.filter((orderItem) => selectIsOrderItemRefundable(state, orderItem));
};

export const selectRefundableOrderItems = (state: RootState) => {
  const refund = state.refund;

  return refund.orderItems.filter((orderItem) => selectIsOrderItemRefundable(state, orderItem));
};

export const selectRefundForm = createSelector(
  refundSelector,
  (refund: RefundState) => refund.refundForm,
);

export const selectIsOrderItemInTheRefund = createSelector(
  [selectRefundForm, (_, orderItemId) => orderItemId],
  (refundForm, orderItemId) => {
    return Object.keys(refundForm.orderItems).includes(String(orderItemId));
  },
);

export const selectOrderItemsExcludingMain = createSelector(refundSelector, (refund: RefundState) =>
  refund.orderItems.filter(({ id }) => id !== refund.mainOrderItemId).sort((a, b) => a.id - b.id),
);

export const selectRefundSummary = createSelector(
  selectMainOrderItem,
  selectRefundForm,
  (
    mainOrderItem,
    refundForm,
  ):
    | {
        buyer: string;
        seller: string;
        debitedAccount: RefundDebitedAccount;
        refundReason: RefundReason;
        amount: number;
        currency: Currency;
      }
    | undefined => {
    if (!mainOrderItem) {
      throw new Error('No refund in process.');
    }

    if (!refundForm.debitedAccount || !refundForm.reason) {
      return;
    }

    const refundAmount = Object.values(refundForm.orderItems).filter(
      (orderItem) => orderItem.amount !== null,
    );

    const sum = refundAmount.reduce((total, orderItem) => add(total, Number(orderItem.amount)), 0);

    return {
      buyer: mainOrderItem.buyer,
      seller: mainOrderItem.seller,
      debitedAccount: refundForm.debitedAccount,
      refundReason: refundForm.reason,
      amount: sum,
      currency: mainOrderItem.currency,
    };
  },
);

export const selectIsRefundFormCompleted = createSelector(
  refundSelector,
  ({ refundForm, orderItems }) => {
    if (!refundForm.debitedAccount) {
      return false;
    }

    if (!refundForm.reason) {
      return false;
    }

    if (isRefundFormEmpty(refundForm) || !areAllZeroAmountGiveaways(refundForm, orderItems)) {
      return false;
    }

    return true;
  },
);

export const selectAreAmountsInconsistent = createSelector(selectRefundForm, (refundForm) => {
  return Object.values(refundForm.orderItems).some(
    (orderItem) => Number(orderItem.amount) !== Math.abs(Number(orderItem.amount)),
  );
});

export const selectRefundDetails = createSelector(refundSelector, ({ refundForm, orderItems }) => {
  if (!refundForm.debitedAccount) {
    throw new Error('Uncompleted refund: missing debited account');
  }

  if (!refundForm.reason) {
    throw new Error('Uncompleted refund: missing refund reason');
  }

  if (
    isRefundFormEmpty(refundForm) ||
    !areAllZeroAmountGiveaways(refundForm, orderItems) ||
    areAmountNotSet(refundForm)
  ) {
    throw new Error('Uncompleted refund: missing refund amount');
  }

  return {
    debitedAccount: refundForm.debitedAccount,
    refundReason: refundForm.reason,
    orderItems: Object.entries(refundForm.orderItems).reduce(
      (items, [id, { amount, refundFullOrder, refundCommissionToSeller }]) => ({
        ...items,
        [id]: {
          refundCommissionToSeller,
          amount: Number(amount),
          refundFullOrder,
        },
      }),
      {},
    ),
  };
});

export const selectRefundReason = createSelector(
  refundSelector,
  (refund) => refund.refundForm.reason,
);

export const selectDebitedAccount = createSelector(
  refundSelector,
  (refund) => refund.refundForm.debitedAccount,
);

export const selectRefundFormLoading = createSelector(
  refundSelector,
  (refund) => refund.refundForm.loading,
);

export const selectRefundAmountFor = createSelector(
  [refundSelector, (_, orderItemId) => orderItemId],
  (refund, orderItemId) => refund.refundForm.orderItems[orderItemId]?.amount,
);

export const selectRefundFullOrder = createSelector(
  [refundSelector, (_, orderItemId) => orderItemId],
  (refund, orderItemId) => refund.refundForm.orderItems[orderItemId]?.refundFullOrder,
);

export const selectRefundCommissionToSeller = createSelector(
  [refundSelector, (_, orderItemId) => orderItemId],
  (refund, orderItemId) => refund.refundForm.orderItems[orderItemId]?.refundCommissionToSeller,
);

export const selectOrderItemIsCompletelyRefunded = createSelector(
  [selectOrderItems, (_, orderItemId) => orderItemId],
  (orderItems, orderItemId) => {
    if (!orderItemId) {
      throw new Error('Missing order item id');
    }

    const orderItem: OrderItemRefund | undefined = orderItems.find(({ id }) => orderItemId === id);

    if (!orderItem) {
      throw new Error(`Order item not found ${orderItemId}`);
    }

    const refundedAmountDiff = minus(
      add(orderItem.amount, orderItem.shippingAmount),
      orderItem.refundedAmount ?? 0,
    );

    return Boolean(
      orderItem.refundReason && refundedAmountDiff <= 0 && orderItem.refundDebitedAccount,
    );
  },
);

export const selectOrderItemIsPurchasedWithPromotion = createSelector(
  [selectOrderItems, (_, orderItemId) => orderItemId],
  (orderItems, orderItemId) => {
    if (!orderItemId) {
      throw new Error('Missing order item id');
    }

    const orderItem: OrderItemRefund | undefined = orderItems.find(({ id }) => orderItemId === id);

    if (!orderItem) {
      throw new Error(`Order item not found ${orderItemId}`);
    }

    return Boolean(orderItem.promotionAmount);
  },
);

export const selectOrderItemsFromRefund = createSelector(
  selectOrderItems,
  selectRefundForm,
  (orderItems, refundForm) => {
    const orderItemIds = Object.keys(refundForm.orderItems);

    return orderItemIds
      .map((orderItemId) => orderItems.find(({ id }) => id === Number(orderItemId)))
      .filter(Boolean) as OrderItemRefund[];
  },
);

export const selectMaxRefundableAmountFor = createSelector(
  [selectOrderItems, selectRefundReason, (_, orderItemId) => orderItemId],
  (orderItems, refundReason, orderItemId) => {
    if (!orderItemId) {
      throw new Error('Missing order item id');
    }

    const orderItem: OrderItemRefund | undefined = orderItems.find(({ id }) => orderItemId === id);

    if (!orderItem) {
      throw new Error(`Order item not found ${orderItemId}`);
    }

    if (
      refundReason === RefundReason.deliveredByHand ||
      refundReason === RefundReason.freeShipping
    ) {
      return orderItem.shippingAmount;
    }

    return minus(
      add(orderItem.amount, orderItem.shippingAmount),
      orderItem.refundedAmount ?? 0,
      orderItem.promotionAmount ?? 0,
    );
  },
);

export const selectIsOrderRefundForFreeShipping = createSelector(selectOrderItems, (orderItems) =>
  orderItems.some(({ refundReason }) => refundReason === RefundReason.freeShipping),
);

export const selectIsOrderRefundForDeliveredByHand = createSelector(
  selectOrderItems,
  (orderItems) =>
    orderItems.some(({ refundReason }) => refundReason === RefundReason.deliveredByHand),
);

const isRefundFormEmpty = (refundForm: RefundState['refundForm']) =>
  Object.keys(refundForm.orderItems).length === 0;

const areAllZeroAmountGiveaways = (
  refundForm: RefundState['refundForm'],
  orderItems: RefundState['orderItems'],
) =>
  Object.entries(refundForm.orderItems)
    .filter(([_, { amount }]) => Number(amount) === 0)
    .every(([id]) => {
      const orderItem = orderItems.find((orderItem) => String(orderItem.id) === id);

      return orderItem?.amount === 0;
    });

const areAmountNotSet = (refundForm: RefundState['refundForm']) =>
  Object.values(refundForm.orderItems).some((amount) => amount === null);
