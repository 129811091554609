import type { Channel, User } from '@bits-app/bits-server-data';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { MUX_BASE_URL } from '@/utils';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

import { SoftDeletedBanner } from '../../../../components/elements/banner/SoftDeletedBanner';

import { panelDetailsChannel, panelDetailsUser } from './useChannelDetailsView';

type DetailProps = {
  channel: Channel;
  loading: boolean;
};
export const Detail = ({ channel, loading }: DetailProps) => {
  const channelId = useIdParam();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!channelId) return null;

  return (
    <DetailsLayout>
      {channel?.deletedAt && <SoftDeletedBanner />}
      <div>
        <div>
          <div className="flex justify-end items-center gap-2 mb-2">
            <EditButton to={`/channel/${channelId}/edit`} />
            <ForestAdminLink entity="channel" entityId={channelId} />
            <Button
              component={'a'}
              variant="text"
              target={'_blank'}
              href={`${MUX_BASE_URL}/video/live-streams/${channel.providerId}/health`}
            >
              {t('link.mux-inspector')}
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-4 lg:flex-row mb-2">
          <div className="flex flex-1 flex-col gap-4">
            <PanelDetails<Channel>
              title={t('databaseExplorer.panel.main')}
              information={panelDetailsChannel}
              data={channel}
              loading={loading}
            />
          </div>

          <div className="flex flex-1 flex-col gap-4">
            <PanelDetails<User>
              title={t('databaseExplorer.panel.user')}
              information={panelDetailsUser}
              data={channel?.user}
              loading={loading}
              seeDetails={(data) => navigate(`/user/${data.id}`)}
            />
          </div>
        </div>
      </div>
    </DetailsLayout>
  );
};
