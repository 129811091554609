import { useState } from 'react';

import { User } from '@bits-app/bits-server-data';
import { Box, Paper, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Label } from '@/components/elements/Label';
import { LoadingButton } from '@/components/elements/buttons/LoadingButton';

import { ListWithExport } from '../../components/ListWithExport';
import { CellUserDefinition } from '../../users/hooks/use-cell-definition';
import { useSearchUserByDeviceId } from '../hooks/useSearchUserByDeviceId';

export const UserByDevice = () => {
  const [deviceId, setDeviceId] = useState('');
  const { t } = useTranslation();
  const { mutate, isLoading, data } = useSearchUserByDeviceId();

  const users = data || [];

  const handleOnChangeDeviceId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceId(event.target.value);
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate(deviceId);
  };

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <Box pt={3}>
        <Paper
          sx={{
            marginBottom: ({ spacing }) => spacing(4),
            padding: ({ spacing }) => spacing(1),
            backgroundColor: ({ palette }) => palette.background.paper,
            fontStyle: 'italic',
          }}
        >
          <Trans i18nKey="userByDevice.featureDescription">
            <p>You can use any device identifier to find a user:</p>
            <ul>
              <li>Device ID</li>
              <li>GAID (Google Advertising ID)</li>
              <li>IDFV (Identifier For Vendor)</li>
              <li>IDFA (Identifier For Advertising)</li>
            </ul>
          </Trans>
        </Paper>

        <Box component="form" onSubmit={handleSearch}>
          <Label htmlFor="deviceId">{t('userByDevice.deviceIdLabel', 'Device ID')}</Label>

          <Box display="flex" alignItems="center" gap={3}>
            <TextField
              required
              name="deviceId"
              type="text"
              value={deviceId}
              onChange={handleOnChangeDeviceId}
              placeholder="Enter device identifier"
              size="small"
            />

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={!deviceId || isLoading}
            >
              {t('userByDevice.search', 'Search')}
            </LoadingButton>
          </Box>
        </Box>
        {users.length > 0 && (
          <ListWithExport<User & { id: string }>
            dataName="search-by-device-ids"
            filters={{ deviceId }}
            cellDefinition={CellUserDefinition}
            enableRowSelection={false}
          />
        )}
      </Box>
    </div>
  );
};
