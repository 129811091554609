import type { Category, ParentCategory } from '@bits-app/bits-server-data';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { EditButton } from '@/components/elements/buttons/EditButtonWithChildren';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

import { CategoryType } from '../../category.type';
import { UpsertCategoryModal } from '../../components/upsert-category/UpsertCategory.modal';
import { useUpsertCategory } from '../list/hooks/use-upsert-category.hook';

import { panelDetailsCategory, panelDetailsParentCategory } from './useCategoryDetailsView';

type DetailProps = {
  category: Category | ParentCategory;
  loading: boolean;
  type: CategoryType;
};

export const Detail = ({ category, loading, type }: DetailProps) => {
  const categoryId = useIdParam();
  const { t } = useTranslation();

  const { ownUserIsAllowedToUpsertCategory, onCloseForm, onOpenForm, openForm } =
    useUpsertCategory();

  if (!categoryId) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <EditButton
              onClick={onOpenForm}
              hide={!ownUserIsAllowedToUpsertCategory || type === 'parent'}
            />
            <ForestAdminLink entity="category" entityId={categoryId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            {type === 'child' ? (
              <PanelDetails<Category>
                title={t('databaseExplorer.panel.category')}
                information={panelDetailsCategory}
                data={category as Category}
                loading={loading}
              />
            ) : (
              <PanelDetails<ParentCategory>
                title={t('databaseExplorer.panel.parentCategory')}
                information={panelDetailsParentCategory}
                data={category as ParentCategory}
                loading={loading}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {type === 'child' && (
        <UpsertCategoryModal
          categories={[]}
          onClose={onCloseForm}
          category={category as Category}
          isOpen={openForm}
        />
      )}
    </DetailsLayout>
  );
};
