import { useState } from 'react';

import type { ShippingAddress, User } from '@bits-app/bits-server-data';
import { AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { Loader } from '@/components/elements/Loader';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';

import { ActivityLogs } from '../../../../components/elements/ActivityLogs';
import { WarningTooltip } from '../../../../components/elements/WarningTooltip';
import { useActivityLogs } from '../../../../hooks/use-activity-logs';
import { CreateButtonRequest } from '../create/CreationButtonRequest';
import { ShippingAddressCreationModal } from '../create/ShippingAddressCreationModal';

import { panelDetailsShippingAddress, panelDetailsUser } from './useDetailsView';

type ShippingDetailsProps = {
  loading: boolean;
  shippingAddress: ShippingAddress | undefined;
  userCanEditShippingAddress: boolean;
  shippingAddressIsOnlyEditable: boolean;
};

export const ShippingDetails = ({
  loading,
  shippingAddress,
  userCanEditShippingAddress,
  shippingAddressIsOnlyEditable,
}: ShippingDetailsProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const shippingAddressId = useIdParam();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activityLogs = useActivityLogs({
    entity: AvailableEntityForComment.shipping_address,
    entityId: shippingAddressId,
  });

  if (loading) {
    return <Loader />;
  }

  if (!shippingAddressId || !shippingAddress) {
    return null;
  }

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const navigateToCreation = () => {
    toggleModal();
    navigate(`/shipping-address/${shippingAddressId}/create`);
  };

  return (
    <>
      <div className="flex justify-end items-center gap-2 mb-2">
        {userCanEditShippingAddress ? (
          shippingAddressIsOnlyEditable ? (
            <EditButton to={`/shipping-address/${shippingAddressId}/edit`} />
          ) : (
            <>
              <CreateButtonRequest onClick={toggleModal} />
              <EditButton to={`/shipping-address/${shippingAddressId}/edit`} />
            </>
          )
        ) : null}
        <ForestAdminLink entity="shippingAddress" entityId={shippingAddressId} />
      </div>
      {userCanEditShippingAddress && shippingAddressIsOnlyEditable ? (
        <WarningTooltip warningText="databaseExplorer.shippingAddress.panel.editActionTooltip" />
      ) : (
        <WarningTooltip warningText="databaseExplorer.shippingAddress.panel.editAndCreateActionsTooltip" />
      )}

      <div className="flex flex-col gap-2 lg:flex-row">
        <div className="flex flex-1 flex-col gap-4">
          <PanelDetails<ShippingAddress>
            title={t('databaseExplorer.panel.main')}
            information={panelDetailsShippingAddress}
            data={shippingAddress}
            loading={loading}
          />
        </div>

        <div className="flex flex-1 flex-col gap-4">
          <PanelDetails<User>
            title={t('databaseExplorer.panel.user')}
            information={panelDetailsUser}
            data={shippingAddress?.user}
            labelRow
            seeDetails={(user) => navigate(`/user/${user.id}`)}
            loading={loading}
          />

          <ActivityLogs
            entityId={shippingAddressId}
            isLoading={activityLogs.isLoading}
            logs={activityLogs.data}
            entityName={AvailableEntityForComment.shipping_address}
          />
        </div>
      </div>

      <ShippingAddressCreationModal
        toggleModal={toggleModal}
        modalIsOpen={modalIsOpen}
        validate={navigateToCreation}
        username={shippingAddress?.user?.username ?? ''}
      />
    </>
  );
};
