import { DataDate } from '../elements/DataWrapper/DataDate';
import { DataLink } from '../elements/DataWrapper/DataLink';
import { DataStatus } from '../elements/DataWrapper/DataStatus';
import { DataText } from '../elements/DataWrapper/DataText';

import { Panel } from './Panel';

type PanelDetailsProps<T> = {
  title: string;
  information: Information<T>[];
  data: T & object;
  labelRow?: boolean;
  loading?: boolean;
  seeDetails?: (data: T) => void;
  actionName?: string;
};

type AvailableType =
  | 'boolean'
  | 'external-link'
  | 'internal-link'
  | 'date'
  | 'date-with-time'
  | 'image'
  | 'badge';

export type Information<T> = {
  value: keyof T;
  label: string;
  format?: (data: T | null) => unknown;
  type?: AvailableType;
  link?: (data: T) => string;
  subDescription?: string;
};

type RenderValueType<T> = {
  type?: AvailableType;
  data: T & object;
  value: keyof T;
  link?: () => string;
  format?: Information<T>['format'];
  label: string;
  subDescription?: string;
};

export const PanelDetails = <T,>({
  information,
  title,
  data,
  seeDetails,
  actionName = 'Open',
}: PanelDetailsProps<T>) => {
  const hasData = Object.keys(data || {}).length;

  return (
    <Panel
      title={title}
      actionName={actionName}
      onClickOnAction={seeDetails ? () => seeDetails(data) : undefined}
    >
      <div className="flex flex-col gap-3">
        {information.map(({ label, value, link, ...rest }) => {
          const somethingToDisplay = data || hasData;

          if (!somethingToDisplay) return null;

          return (
            <div className="flex flex-col" key={`${title}-${label}`}>
              <RenderValue<T>
                data={data}
                value={value}
                label={label}
                link={link ? () => link(data) : undefined}
                {...rest}
              />
            </div>
          );
        })}
      </div>
    </Panel>
  );
};

const RenderValue = <T,>({
  label,
  type,
  data,
  value,
  link = () => '',
  format,
  subDescription,
}: RenderValueType<T>) => {
  const formattedValue = format ? format(data) : data[value];

  try {
    if (!formattedValue) return <DataText title={label} value="-" />;
  } catch (error) {
    console.log({ error });
  }

  switch (type) {
    case 'external-link':
      const href = link();
      return (
        <DataLink
          type="external"
          title={label}
          displayText={formattedValue as string}
          link={href}
        />
      );

    case 'internal-link':
      const to = link();
      return (
        <DataLink type="internal" title={label} displayText={formattedValue as string} link={to} />
      );

    case 'boolean':
      return (
        <DataStatus
          title={label}
          value={formattedValue as string}
          status={formattedValue as boolean}
        />
      );
    case 'date-with-time':
      return <DataDate title={label} date={formattedValue as Date} />;
    case 'date':
      return <DataDate title={label} date={formattedValue as Date} />;
    case 'image':
      return <img src={formattedValue as string} style={{ width: 30 }} />;
    case 'badge':
      return <DataText title={label} value={formattedValue as string} disableCopy />;
    default:
      return (
        <DataText title={label} value={formattedValue as string} subDescription={subDescription} />
      );
  }
};
